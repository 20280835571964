<template>
  <div class="date-time-wrapper" :style="{ maxWidth: maxWidth + 'px' }">
    <v-menu
      ref="dateMenu"
      v-model="dateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
      @update:model-value="$emit('focus')"
    >
      <template v-slot:activator="{ props }">
        <v-text-field
          :model-value="displayDate"
          prepend-inner-icon="mdi-calendar-outline"
          color="green"
          variant="outlined"
          :clearable="clearable"
          hide-details
          :error="error"
          :rules="datePickerRules"
          readonly
          v-bind="props"
          @click:clear="clearDate()"
        />
      </template>
      <v-date-picker
        v-model="date"
        no-title
        scrollable
        color="green"
        elevation="12"
        :min="minValueExcluded"
      />
    </v-menu>
    <v-menu
      ref="timeMenu"
      v-model="timeMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
      @update:model-value="$emit('focus')"
    >
      <template v-slot:activator="{ props }">
        <v-text-field
          v-model="displayTime"
          prepend-inner-icon="mdi-clock-time-four-outline"
          color="green"
          variant="outlined"
          readonly
          hide-details
          :rules="timePickerRules"
          clearable
          class="date-picker"
          v-bind="props"
          @click:clear="clearTime()"
        />
      </template>
      <v-time-picker v-model="time" format="24hr" scrollable color="green" elevation="12" />
    </v-menu>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DateTimePicker',
  emits: ['update:model-value', 'focus'],
  data() {
    return {
      dateMenu: false,
      timeMenu: false,
    } as {
      dateMenu: boolean
      timeMenu: boolean
    }
  },
  computed: {
    defaultDateTime(): Date {
      return new Date(this.minValue ?? Date.now())
    },
    date: {
      get(): Date {
        return this.modelValue ? new Date(this.modelValue) : this.defaultDateTime
      },
      set(value: Date) {
        const dateTimeString = this.buildDateTime({ date: this.getValidDateInValidInterval(value) })
        this.$emit('update:model-value', dateTimeString)
      },
    },
    time: {
      get(): string {
        return this.date.toISOString().split('T')[1].slice(0, 5)
      },
      set(value: string) {
        const dateTimeString = this.buildDateTime({ time: value })
        if (!dateTimeString) return this.$emit('update:model-value', null)

        const validDate = this.getValidDateInValidInterval(new Date(dateTimeString))
        this.$emit('update:model-value', validDate.toISOString())
      },
    },
    minValueExcluded(): string {
      const startDate: Date = new Date(this.minValue ?? 0)
      const timeStamp: number = startDate.setDate(startDate.getDate() - 1)
      return new Date(timeStamp).toISOString()
    },
    displayDate(): string {
      if (!this.date) return ''

      const formatter = new Intl.DateTimeFormat('en-GB', { dateStyle: 'long' })
      return formatter.format(this.date)
    },
    displayTime(): string {
      if (!this.time) return ''
      return `${this.time} UTC`
    },
  },
  methods: {
    clearDate() {
      this.$emit('focus')
      this.date = new Date()
    },
    clearTime() {
      this.$emit('focus')
      this.time = '00:00'
    },
    buildDateTime({ date, time }: { date?: Date; time?: string }): string | null {
      const dateToUse = date ?? this.date
      const timeToUse = time ?? this.time
      const currentTime = new Date().toUTCString().split(' ')[4].slice(0, 5)

      const splitDate = `${dateToUse.getFullYear()}-${`${dateToUse.getMonth() + 1}`.padStart(
        2,
        '0',
      )}-${dateToUse.getDate().toString().padStart(2, '0')}`
      if (dateToUse && timeToUse) {
        return splitDate.concat('T', timeToUse, ':00.000Z')
      } else if (dateToUse && !timeToUse) {
        return splitDate.concat('T', currentTime, '00.000Z')
      }
      return null
    },
    getValidDateInValidInterval(dateTime: Date): Date {
      const minDate = new Date(this.minValue ?? 0)
      const maxDate = new Date(this.maxValue ?? 32503680000000) // 01-01-3000

      // currently selected dateTime is lower then minimum allowed date
      if (dateTime < minDate) return minDate
      // currently selected dateTime is higher then maximum allowed date
      if (dateTime >= maxDate) return maxDate
      return dateTime
    },
    onLimitChange(newLimit: Date, oldLimit: Date) {
      if (!newLimit || !oldLimit || oldLimit === newLimit) return

      const dateTime = this.modelValue ? new Date(this.modelValue) : this.defaultDateTime
      const validDate = this.getValidDateInValidInterval(dateTime)
      if (dateTime.getTime() === validDate.getTime()) return

      this.$emit('update:model-value', validDate.toISOString())
    },
  },
  props: {
    modelValue: {
      type: String,
    },
    minValue: {
      type: String,
    },
    maxValue: {
      type: String,
    },
    maxWidth: {
      type: Number as PropType<number>,
    },
    clearable: {
      default: true,
      type: Boolean,
    },
    datePickerRules: {
      type: Object as PropType<[(value: string) => string]>,
    },
    timePickerRules: {
      type: Object as PropType<[(value: string) => string]>,
    },
    error: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    minValue: [
      {
        handler: 'onLimitChange',
      },
    ],
    maxValue: [
      {
        handler: 'onLimitChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.date-time-wrapper {
  display: flex;
  gap: 15px;
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-direction: row;
  }
}

.date-time-wrapper > * {
  flex: 1 1 0;
}
</style>
