import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7556d063"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fullwidth-banner-wrapper" }
const _hoisted_2 = { class: "fullwidth-banner" }
const _hoisted_3 = { class: "option-list" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "option-amount" }
const _hoisted_6 = { class: "option-label" }
const _hoisted_7 = { class: "greenspark-logo-banner public-profile-link" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WidgetDialog = _resolveComponent("WidgetDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalImpacts, (value, key, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "option-list-item"
          }, [
            (_openBlock(), _createElementBlock("svg", null, [
              _createElementVNode("use", {
                href: '#' + key + '-icon'
              }, null, 8, _hoisted_4)
            ])),
            _createElementVNode("h3", _hoisted_5, _toDisplayString(value.toLocaleString()), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t(key)), 1)
          ]))
        }), 128))
      ]),
      _createElementVNode("span", _hoisted_7, [
        _createElementVNode("img", {
          src: require('@/assets/greenspark-logo-beige.svg'),
          alt: "greenspark-logo"
        }, null, 8, _hoisted_8)
      ])
    ]),
    _createVNode(_component_WidgetDialog, { mode: "banner" })
  ]))
}