import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a605190"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "goals" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "goal-list" }
const _hoisted_5 = { class: "list-item-header" }
const _hoisted_6 = { class: "title-wrapper" }
const _hoisted_7 = { class: "goal-title mb-0" }
const _hoisted_8 = { class: "drawer" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.t('header_title')) + " ", 1),
      (_ctx.modelValue.length < 5)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            disabled: _ctx.openedIndex === _ctx.modelValue.length - 1 || !_ctx.isThereAnySavedGoal,
            class: "add-button",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openAddNewPanel && _ctx.openAddNewPanel(...args)), ["prevent"]))
          }, " New + ", 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (goal, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: goal.targetId || 'placeholder',
          class: "goal-list-item"
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: _normalizeClass(['checkmark', { saved: goal.targetId }])
              }, [
                (!!goal.targetId)
                  ? (_openBlock(), _createBlock(_component_v_icon, {
                      key: 0,
                      class: "checkmark-icon"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" mdi-check ")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("p", _hoisted_7, _toDisplayString(goal.title || _ctx.t('new_goal')), 1)
            ]),
            (!!goal.targetId)
              ? (_openBlock(), _createBlock(_component_v_menu, { key: 0 }, {
                  activator: _withCtx(({ props }) => [
                    _createElementVNode("button", _mergeProps({
                      class: ['open-drawer-button', { 'is-active': _ctx.openedDrawerIndex === index }],
                      ref_for: true
                    }, props), [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-dots-horizontal")
                        ]),
                        _: 1
                      })
                    ], 16)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("button", {
                        class: "drawer-button",
                        onClick: ($event: any) => (_ctx.openEditPanel(index))
                      }, [
                        _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" mdi-pencil ")
                          ]),
                          _: 1
                        }),
                        _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.edit')), 1)
                      ], 8, _hoisted_9),
                      _createElementVNode("button", {
                        class: "drawer-button",
                        onClick: ($event: any) => (_ctx.deleteGoal(goal.targetId ?? ''))
                      }, [
                        _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" mdi-delete-outline ")
                          ]),
                          _: 1
                        }),
                        _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.delete')), 1)
                      ], 8, _hoisted_10)
                    ])
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(['input-wrapper', { 'is-open': _ctx.openedIndex === index || !_ctx.isThereAnySavedGoal }])
          }, [
            _createVNode(_component_v_text_field, {
              modelValue: goal.title,
              "onUpdate:modelValue": ($event: any) => ((goal.title) = $event),
              label: _ctx.t('input_label'),
              class: "input",
              type: "text",
              variant: "outlined",
              color: "#3B755F",
              flat: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
            _createVNode(_component_v_textarea, {
              modelValue: goal.description,
              "onUpdate:modelValue": ($event: any) => ((goal.description) = $event),
              class: "textarea",
              variant: "outlined",
              counter: "",
              color: "#3B755F",
              "no-resize": "",
              maxlength: "240",
              rules: [_ctx.rules.maxlength()]
            }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_v_btn, {
                class: "save-button",
                color: "#3B755F",
                height: "44",
                disabled: !goal.description.length || !goal.title.length || _ctx.loading,
                loading: _ctx.loading,
                onClick: _withModifiers(($event: any) => (goal.targetId ? _ctx.editGoal(goal) : _ctx.saveGoal(goal)), ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.save')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "loading", "onClick"]),
              _createVNode(_component_v_btn, {
                color: "#FFFFFF",
                height: "44",
                class: "cancel-button",
                elevation: "0",
                onClick: _withModifiers(_ctx.cancelEdit, ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ], 2)
        ]))
      }), 128))
    ])
  ]))
}