<template>
  <div class="dialog">
    <div class="input-wrapper">
      <p class="description">
        {{ t('description') }}
      </p>
      <SustainabilityGoals v-model="localGoals" @reset="resetGoals" />
      <SustainabilitySiteSetup />
    </div>
    <div class="demo-feed">
      <DemoFeed :prop-feed-items="localGoals" type="goal" />
    </div>
  </div>
</template>

<script lang="ts">
import DemoFeed from '@/components/CustomerEngagementPortal/DemoFeed.vue'
import SustainabilityGoals from '@/components/CustomerEngagementPortal/SustainabilityGoals.vue'
import SustainabilitySiteSetup from '@/components/CustomerEngagementPortal/SustainabilitySiteSetup.vue'
import type { Account } from '@/store'
import type { SustainabilityGoal } from '@/helpers/interfaces'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TargetGoalSettingDialog',
  components: { DemoFeed, SustainabilityGoals, SustainabilitySiteSetup },
  data() {
    const localGoals: SustainabilityGoal[] = []

    return {
      localGoals,
      loading: false,
    }
  },
  created() {
    this.localGoals = this.sustainabilityGoals.length
      ? [...this.sustainabilityGoals]
      : [{ title: '', description: '' }]
  },
  methods: {
    t(key: string) {
      return this.$t(`TargetGoalSettingDialog.${key}`)
    },
    async resetGoals() {
      this.loading = true
      this.localGoals = this.sustainabilityGoals.length
        ? [...this.sustainabilityGoals]
        : [{ title: '', description: '' }]
      this.loading = false
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    sustainabilityGoals(): SustainabilityGoal[] {
      return this.$store.getters['getSustainabilityGoals']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.dialog {
  display: flex;
  gap: 40px;
  width: 100%;
}

.input-wrapper {
  background-color: var(--ui-white);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-wide);
  padding: 20px;
  flex: 1 1 100%;
}

.demo-feed {
  display: none;
}

.intro-textarea {
  max-height: none; // .v-input reset
  margin-bottom: 20px;
  width: 100%;
}

.intro-textarea:deep(textarea::placeholder) {
  color: var(--gray-light);
}

.description {
  margin-bottom: 20px;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.save-button {
  color: var(--ui-white);
  margin-bottom: 20px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .dialog {
    flex-direction: column;
  }

  .demo-feed {
    display: block;
    flex: 1 0 100%;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .dialog {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
  }

  .input-wrapper {
    flex: 1 1 50%;
  }

  .demo-feed {
    display: block;
    flex: 1 0 50%;
  }

  .button-wrapper {
    justify-content: flex-end;
    flex-direction: row;
    gap: 10px;
  }

  .save-button {
    order: 2;
    width: 150px;
  }

  .cancel-button {
    order: 1;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .dialog {
    margin: 0 auto;
    max-width: 1080px;
  }

  .input-wrapper {
    flex: 1 1 55%;
  }

  .demo-feed {
    display: block;
    flex: 1 0 45%;
  }
}
</style>
