<template>
  <div class="goals">
    <div class="header">
      {{ t('header_title') }}
      <button
        v-if="modelValue.length < 5"
        :disabled="openedIndex === modelValue.length - 1 || !isThereAnySavedGoal"
        class="add-button"
        @click.prevent="openAddNewPanel"
      >
        New +
      </button>
    </div>

    <div class="goal-list">
      <div
        v-for="(goal, index) in modelValue"
        :key="goal.targetId || 'placeholder'"
        class="goal-list-item"
      >
        <div class="list-item-header">
          <div class="title-wrapper">
            <div :class="['checkmark', { saved: goal.targetId }]">
              <v-icon v-if="!!goal.targetId" class="checkmark-icon"> mdi-check </v-icon>
            </div>
            <p class="goal-title mb-0">
              {{ goal.title || t('new_goal') }}
            </p>
          </div>
          <v-menu v-if="!!goal.targetId">
            <template v-slot:activator="{ props }">
              <button
                :class="['open-drawer-button', { 'is-active': openedDrawerIndex === index }]"
                v-bind="props"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </button>
            </template>
            <div class="drawer">
              <button class="drawer-button" @click="openEditPanel(index)">
                <v-icon class="drawer-icon"> mdi-pencil </v-icon>{{ $t('CommonUi.edit') }}
              </button>
              <button class="drawer-button" @click="deleteGoal(goal.targetId ?? '')">
                <v-icon class="drawer-icon"> mdi-delete-outline </v-icon>{{ $t('CommonUi.delete') }}
              </button>
            </div>
          </v-menu>
        </div>
        <div
          :class="['input-wrapper', { 'is-open': openedIndex === index || !isThereAnySavedGoal }]"
        >
          <v-text-field
            v-model="goal.title"
            :label="t('input_label')"
            class="input"
            type="text"
            variant="outlined"
            color="#3B755F"
            flat
          />
          <v-textarea
            v-model="goal.description"
            class="textarea"
            variant="outlined"
            counter
            color="#3B755F"
            no-resize
            maxlength="240"
            :rules="[rules.maxlength()]"
          />
          <div class="button-wrapper">
            <v-btn
              class="save-button"
              color="#3B755F"
              height="44"
              :disabled="!goal.description.length || !goal.title.length || loading"
              :loading="loading"
              @click.prevent="goal.targetId ? editGoal(goal) : saveGoal(goal)"
            >
              {{ $t('CommonUi.save') }}
            </v-btn>
            <v-btn
              color="#FFFFFF"
              height="44"
              class="cancel-button"
              elevation="0"
              @click.prevent="cancelEdit"
            >
              {{ $t('CommonUi.cancel') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Clearable, SustainabilityGoal } from '@/helpers/interfaces'
import type { Notification } from '@/store/notification'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SustainabilityGoals',
  emits: ['update:model-value', 'reset'],
  mixins: [RulesMixin],
  data() {
    return {
      loading: false,
      openedIndex: null,
      openedDrawerIndex: null,
    } as {
      loading: boolean
      openedIndex: Clearable<number>
      openedDrawerIndex: Clearable<number>
    }
  },
  computed: {
    isThereAnySavedGoal(): number | undefined {
      return this.modelValue?.filter(({ targetId }) => !!targetId).length
    },
  },
  created() {
    this.isThereAnySavedGoal ? (this.openedIndex = null) : (this.openedIndex = 0)
  },
  methods: {
    t(key: string) {
      return this.$t(`SustainabilityGoals.${key}`)
    },
    openAddNewPanel() {
      this.$emit('update:model-value', [...(this.modelValue ?? []), { description: '', title: '' }])
      this.$nextTick(() => {
        this.openedIndex = this.modelValue ? this.modelValue.length - 1 : 0
      })
    },
    openEditPanel(index: number) {
      this.openedIndex = index
      this.openedDrawerIndex = null
      this.$emit('reset')
    },
    cancelEdit() {
      this.openedIndex = null
      this.$emit('reset')
    },
    async saveGoal(goal: SustainabilityGoal) {
      this.loading = true
      try {
        await this.saveSustainabilityGoal(goal)
        this.$emit('reset')
        this.openedIndex = null
        this.$store.dispatch('notification/notify', { text: this.t('goal_saved') } as Notification)
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      }
      this.loading = false
    },
    async editGoal(goal: SustainabilityGoal) {
      this.loading = true
      try {
        await this.updateSustainabilityGoal(goal)
        this.$emit('reset')
        this.openedDrawerIndex = null
        this.openedIndex = null
        this.$store.dispatch('notification/notify', {
          text: this.t('goal_updated'),
        } as Notification)
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      }
      this.loading = false
    },
    async deleteGoal(targetID: string) {
      this.loading = true
      try {
        await this.deleteSustainabilityGoal(targetID)
        this.$emit('reset')
        this.openedDrawerIndex = null
        this.openedIndex = null
        this.$store.dispatch('notification/notify', {
          text: this.t('goal_deleted'),
        } as Notification)
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      }
      this.loading = false
    },
    saveSustainabilityGoal(goal: SustainabilityGoal): Promise<Promise<void>> {
      return this.$store.dispatch('saveSustainabilityGoal', goal)
    },
    updateSustainabilityGoal(goal: SustainabilityGoal): Promise<Promise<void>> {
      return this.$store.dispatch('updateSustainabilityGoal', goal)
    },
    deleteSustainabilityGoal(goalId: string): Promise<Promise<void>> {
      return this.$store.dispatch('deleteSustainabilityGoal', goalId)
    },
  },
  props: {
    modelValue: {
      type: Array as PropType<SustainabilityGoal[]>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.goals {
  background-color: #ffffff;
  border-radius: var(--border-radius-big);
  margin-bottom: 20px;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.save-button {
  color: var(--ui-white);
  margin-bottom: 20px;
  position: relative;
}

.textarea {
  max-height: none; // .v-input reset
}

.input-wrapper {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  padding: 0 10px;
  transition: all linear 0.2s;
}

.input-wrapper.is-open {
  max-height: 1000px;
  padding: 20px 10px;
  transition: all linear 0.2s;
  visibility: visible;
  border-top: 1px solid var(--gray);
}

.goal-list-item .input-wrapper.is-open {
  border-top: none;
}

.goal-list-item:not(:last-of-type) .input-wrapper.is-open {
  border-bottom: 1px solid var(--gray);
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 15px 10px;
  border-bottom: 1px solid var(--gray);
}

.add-button {
  border: 1px solid var(--ui-green);
  border-radius: 10px;
  padding: 0 5px;
  color: var(--ui-green);
  font-size: 14px;
}

.add-button:disabled {
  cursor: not-allowed;
}

.list-item-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 15px 10px;
  position: relative;
}

.goal-list-item:not(:last-of-type) .list-item-header {
  border-bottom: 1px solid var(--gray);
}

.title-wrapper {
  align-items: center;
  display: flex;
}

.checkmark {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 20px;
  margin-right: 5px;
  position: relative;
  width: 20px;
}

.goal-list-item .checkmark.saved {
  background-color: var(--ui-green);
  border: none;
}

.checkmark-icon {
  color: transparent;
  font-size: 16px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 50%;
  right: 50%;
  transform: translate(-50%, calc(-50% + 1px));
}

.goal-list-item .checkmark-icon {
  color: white;
}

.input {
  margin-bottom: 20px;
}

.textarea {
  margin-bottom: 20px;
}

.drawer {
  background-color: #ffffff;
  padding: 5px;
}

.drawer-button {
  align-items: center;
  display: flex;
  border-radius: var(--border-radius-big);
  text-align: left;
  padding: 5px;
  width: 100px;
  font-size: 14px;
  line-height: 16px;
}

.drawer-icon {
  font-size: 18px;
  color: var(--font-color-primary);
  margin-right: 10px;
}

.drawer-button:hover {
  background-color: var(--ui-green-light-20);
}

.title-wrapper.add-new {
  margin-bottom: 15px;
}

.open-drawer-button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.open-drawer-button.is-active {
  background-color: var(--gray-light);
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .button-wrapper {
    justify-content: flex-end;
    flex-direction: row;
    gap: 10px;
  }

  .save-button {
    order: 2;
    width: 150px;
    margin-bottom: 0;
  }

  .cancel-button {
    order: 1;
  }

  .drawer-button {
    text-transform: capitalize;
  }
}
</style>
