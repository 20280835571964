import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7126d73c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog" }
const _hoisted_2 = { class: "input-wrapper" }
const _hoisted_3 = { class: "description" }
const _hoisted_4 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_DemoFeed = _resolveComponent("DemoFeed")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t('description')), 1),
      _createVNode(_component_v_textarea, {
        modelValue: _ctx.description,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.description) = $event)),
        variant: "outlined",
        counter: "",
        color: "#3B755F",
        maxlength: "240",
        class: "intro-textarea",
        "no-resize": "",
        placeholder: _ctx.t('placeholder'),
        rules: [_ctx.rules.maxlength(240)]
      }, null, 8, ["modelValue", "placeholder", "rules"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_v_btn, {
          class: "save-button",
          color: "#3B755F",
          height: "44",
          loading: _ctx.loading,
          disabled: _ctx.loading,
          onClick: _ctx.saveIntroduction
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Save ")
          ]),
          _: 1
        }, 8, ["loading", "disabled", "onClick"]),
        _createVNode(_component_v_btn, {
          color: "#F9F9F9",
          height: "44",
          class: "cancel-button",
          elevation: "0",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$parent.isOpen = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Cancel ")
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_DemoFeed, {
      "prop-feed-items": [{ ..._ctx.propFeedItem, description: _ctx.description }],
      type: "introduction",
      class: "demo-feed"
    }, null, 8, ["prop-feed-items"])
  ]))
}