<template>
  <div>
    <div>
      <div class="add-impact">
        <ImpactSettings :model-value="impactSetting" @update:model-value="onImpactSettingChange" />
      </div>

      <div class="total">
        <p :class="[{ 'invalid-selection': total < 0 }]">{{ t('total') }} {{ total }}</p>
      </div>

      <div class="button-wrapper">
        <gs-button
          :disabled="isDisabled"
          :loading="loading"
          :full-width="true"
          size="large"
          @click.prevent="goHome"
        >
          {{ t('continue') }}
        </gs-button>
      </div>

      <div v-if="isDisabled" class="note">
        {{ t('note') }}
      </div>

      <div v-if="error" class="error-message">
        {{ t('error') }}
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script lang="ts">
import type { ClaimedAllowanceRequestBody, GetProjectsResponse } from '@api/index'
import { claimAllowance, getAppProjects } from '@api/index'
import type { Currency, OffsetType } from '@/helpers/interfaces'
import { Decimal } from 'decimal.js'
import ImpactSettings from '@/components/onboarding/ImpactSettings.vue'
import type { AutomationOffset } from '@/store/integrations'
import type { ProjectId } from '@/helpers/constants'
import { PARTNER_PROJECTS } from '@/helpers/constants'
import { getCurrencyCode } from '@/helpers/pricing'
import { includes } from '@/helpers/parsers'
import Loading from '@/components/tools/Loading.vue'
import { IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP } from '@/helpers/projects'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChooseAllowance',
  components: {
    ImpactSettings,
    Loading,
  },
  data() {
    return {
      impactSetting: [],
      projects: [],
      total: 0,
      error: false,
      loading: false,
    } as {
      impactSetting: Omit<Required<AutomationOffset>, 'source'>[]
      projects: GetProjectsResponse[]
      total: number
      error: boolean
      loading: boolean
    }
  },
  computed: {
    isDisabled(): boolean {
      return this.total !== 0
    },
    relationAllowanceAmount(): number {
      return this.$store.getters['getRelationAllowanceAmount']
    },
  },
  async created() {
    this.loading = true
    const { data } = await getAppProjects({ currency: getCurrencyCode(this.connectorCurrency) })
    this.projects = data.filter((project) => includes(PARTNER_PROJECTS, project.projectId))
    this.impactSetting = (this.projects || []).map((project) => ({
      type: project.type,
      projectId: project.projectId as ProjectId,
      amount: null,
    }))

    this.calculatePresetAllowance()
    this.total = this.relationAllowanceAmount
    this.setTotal()
    this.loading = false
  },
  methods: {
    t(key: string) {
      return this.$t(`ChooseAllowance.${key}`)
    },
    onImpactSettingChange(value: Omit<Required<AutomationOffset>, 'source'>[]) {
      this.impactSetting = value
      this.setTotal()
    },
    setTotal() {
      this.total = new Decimal(this.relationAllowanceAmount)
        .minus(
          (this.projects || [])
            .map((project) => this.getGreensparkCredits(project.projectId))
            .reduce(
              (prev, { projectId, price }) =>
                new Decimal(price)
                  .times(this.getImpactSettingByType(projectId)?.amount || 0)
                  .add(prev)
                  .toNumber(),
              0,
            ),
        )
        .toNumber()
    },
    calculatePresetAllowance() {
      let totalAllowance = new Decimal(this.relationAllowanceAmount || 0)
      const treesImpactSetting = this.getImpactSettingByType(
        IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP['trees'],
      )
      const plasticImpactSetting = this.getImpactSettingByType(
        IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP['plastic'],
      )
      const carbonImpactSetting = this.getImpactSettingByType(
        IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP['carbon'],
      )

      if (treesImpactSetting && plasticImpactSetting && carbonImpactSetting) {
        treesImpactSetting.amount = totalAllowance
          .dividedBy(2)
          .dividedBy(this.getGreensparkCredits(IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP['trees']).price)
          .floor()
          .toNumber()

        totalAllowance = totalAllowance.minus(
          new Decimal(
            this.getGreensparkCredits(IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP['trees']).price,
          ).times(treesImpactSetting.amount),
        )

        plasticImpactSetting.amount = totalAllowance
          .dividedBy(2)
          .dividedBy(this.getGreensparkCredits(IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP['plastic']).price)
          .floor()
          .toNumber()

        totalAllowance = totalAllowance.minus(
          new Decimal(
            this.getGreensparkCredits(IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP['plastic']).price,
          ).times(plasticImpactSetting.amount),
        )

        carbonImpactSetting.amount = totalAllowance
          .dividedBy(this.getGreensparkCredits(IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP['carbon']).price)
          .floor()
          .toNumber()
      }
    },
    getGreensparkCredits(projectId: string): {
      type: OffsetType
      price: number
      projectId: string
    } {
      const project = this.projects.find(
        (project) => project.projectId === projectId,
      ) as GetProjectsResponse
      return {
        type: project.type,
        price: project.type === 'carbon' ? 0.01 : project.price, // the carbon cost is always 0.01 for the allowance calculation, regardless the user's currency
        projectId: project.projectId,
      }
    },
    async goHome() {
      try {
        this.loading = true
        this.error = false
        const payload: ClaimedAllowanceRequestBody = {
          impacts: this.impactSetting
            .filter((setting) => !!setting.amount)
            .map((setting) => ({
              projectId: setting.projectId,
              amount: Number(setting.amount),
              type: setting.type,
            })),
        }
        await claimAllowance(payload, this.relationsId)
        this.setRequiredActionAlert()
        await this.$router.push('/')
      } catch (error) {
        this.loading = false
        this.error = true
        console.error('error', error)
      }
    },
    getImpactSettingByType(
      projectId: string,
    ): Omit<Required<AutomationOffset>, 'source'> | undefined {
      return this.impactSetting.find((setting) => setting.projectId === projectId)
    },
    setRequiredActionAlert(): Promise<void> {
      return this.$store.dispatch('setRequiredActionAlert')
    },
  },
  props: {
    relationsId: {
      type: String,
      required: true,
    },
    connectorCurrency: {
      type: String as PropType<Currency>,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.add-impact {
  margin: 20px 0;
}

.center {
  margin-left: 12px;
  margin-right: 12px;
  min-width: 264px;
}

.total {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  text-align: right;
  margin-bottom: 22px;
}

.invalid-selection {
  color: red;
}

.error-message {
  font-size: 16px;
  color: #f9f9f9;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: red;
  margin-bottom: 10px;
  margin-top: 18px;
}

.note {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #212121;
  text-align: center;
  margin-top: 12px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
}
</style>
