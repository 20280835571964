import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ea42824"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "badges-main-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "how-it-works"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "accordion-wrapper" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "accordion-header-wrapper" }
const _hoisted_8 = {
  class: "badge-container",
  style: {"padding-left":"0px","padding-right":"0px"}
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "accordion-header-wrapper" }
const _hoisted_11 = {
  class: "badge-container",
  style: {"padding-left":"0px","padding-right":"0px"}
}
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "accordion-header-wrapper" }
const _hoisted_14 = {
  class: "badge-container",
  style: {"padding-left":"0px","padding-right":"0px"}
}
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "accordion-header-wrapper" }
const _hoisted_17 = { class: "header-pill" }
const _hoisted_18 = { class: "header-pill" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "accordion-header-wrapper" }
const _hoisted_21 = { class: "header-pill" }
const _hoisted_22 = { class: "header-pill" }
const _hoisted_23 = {
  class: "badge-container",
  style: {"padding-left":"24px","padding-right":"24px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HowItWorkShare = _resolveComponent("HowItWorkShare")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_FullWidthBanner = _resolveComponent("FullWidthBanner")!
  const _component_WidgetRow = _resolveComponent("WidgetRow")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "back",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, [
      _createElementVNode("img", {
        class: "chevron",
        alt: "chevron",
        src: require('@/assets/icons/chevron_left.svg')
      }, null, 8, _hoisted_2),
      _createElementVNode("div", null, _toDisplayString(_ctx.t('back')), 1)
    ]),
    (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_HowItWorkShare)
        ]))
      : _createCommentVNode("", true),
    (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_v_expansion_panels, {
              variant: "accordion",
              flat: "",
              multiple: ""
            }, {
              default: _withCtx(() => [
                (_ctx.dynamicWidgets.length)
                  ? (_openBlock(), _createBlock(_component_v_expansion_panel, {
                      key: 0,
                      class: "v-expansion-panel-headers"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              src: require('@/assets/icons/leaf-icon.svg'),
                              style: {"max-width":"25px","margin-right":"8px"}
                            }, null, 8, _hoisted_6),
                            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('dynamic_header')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamicWidgets, (item, a) => {
                                return (_openBlock(), _createBlock(_component_Badge, {
                                  key: a,
                                  class: "badge",
                                  image: item.image,
                                  url: item.url,
                                  "overlay-text": _ctx.t('static'),
                                  colour: item.type.toLowerCase().includes('light') ? 'background: #b1acac;' : '',
                                  account: _ctx.account,
                                  "is-shopify-integrated": _ctx.isShopifyIntegrated,
                                  "shopify-urls": _ctx.shopifyUrls,
                                  "from-shopify-marketplace": _ctx.fromShopifyMarketplace
                                }, null, 8, ["image", "url", "overlay-text", "colour", "account", "is-shopify-integrated", "shopify-urls", "from-shopify-marketplace"]))
                              }), 128))
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_expansion_panel, { class: "v-expansion-panel-headers" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: require('@/assets/icons/leaf-icon.svg'),
                          style: {"max-width":"25px","margin-right":"8px"}
                        }, null, 8, _hoisted_9),
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t('static_header')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.staticWidgets, (item, a) => {
                            return (_openBlock(), _createBlock(_component_Badge, {
                              key: a,
                              class: "badge",
                              image: item.image,
                              url: item.url,
                              "overlay-text": _ctx.t('static'),
                              colour: item.type.toLowerCase().includes('light') ? 'background: #b1acac;' : '',
                              account: _ctx.account,
                              "is-shopify-integrated": _ctx.isShopifyIntegrated,
                              "shopify-urls": _ctx.shopifyUrls,
                              "from-shopify-marketplace": _ctx.fromShopifyMarketplace
                            }, null, 8, ["image", "url", "overlay-text", "colour", "account", "is-shopify-integrated", "shopify-urls", "from-shopify-marketplace"]))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel, { class: "v-expansion-panel-headers" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: require('@/assets/icons/leaf-icon.svg'),
                          style: {"max-width":"25px","margin-right":"8px"}
                        }, null, 8, _hoisted_12),
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t('round_header')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roundWidgets, (item, a) => {
                            return (_openBlock(), _createBlock(_component_Badge, {
                              key: a,
                              class: "badge",
                              image: item.image,
                              url: item.url,
                              "overlay-text": _ctx.t('static'),
                              "is-shopify-integrated": _ctx.isShopifyIntegrated,
                              colour: item.type.toLowerCase().includes('light') ? 'background: #b1acac;' : '',
                              account: _ctx.account,
                              "shopify-urls": _ctx.shopifyUrls
                            }, null, 8, ["image", "url", "overlay-text", "is-shopify-integrated", "colour", "account", "shopify-urls"]))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_expansion_panel, { class: "v-expansion-panel-headers" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: require('@/assets/icons/leaf-icon.svg'),
                          style: {"max-width":"25px","margin-right":"8px"}
                        }, null, 8, _hoisted_15),
                        _createElementVNode("div", _hoisted_16, [
                          _createTextVNode(_toDisplayString(_ctx.t('full_width_banner_header')) + " ", 1),
                          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('shopify_two')), 1),
                          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('widget_api')), 1)
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_FullWidthBanner, { "total-impacts": _ctx.fullWidthBannerData }, null, 8, ["total-impacts"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impactSettingWidgets, (item, a) => {
                  return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                    key: a,
                    class: "v-expansion-panel-headers"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: require('@/assets/icons/leaf-icon.svg'),
                            style: {"max-width":"25px","margin-right":"8px"}
                          }, null, 8, _hoisted_19),
                          _createElementVNode("div", _hoisted_20, [
                            _createTextVNode(_toDisplayString(_ctx.t(`${item.trigger}SectionTitle`)) + " ", 1),
                            _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('shopify_two')), 1),
                            _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('widget_api')), 1)
                          ])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_expansion_panel_text, { class: "accordion-content" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_23, [
                              _createVNode(_component_WidgetRow, { "widget-type": item }, null, 8, ["widget-type"])
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}