<template>
  <div class="create-api-key">
    <dl class="integration-name-wrapper section">
      <dd class="label integration">
        {{ t('integration') }}
      </dd>
      <OnboardingPill>
        {{ t(customIntegrationType ?? '') }}
      </OnboardingPill>
    </dl>
    <div class="integration-name-wrapper section">
      <p class="label integration">
        {{ t('source_detail') }}
      </p>
      <OnboardingPill
        v-for="(item, key) in customIntegrationSetting?.sourceDetails"
        :key="key"
        :is-copy-enabled="key.includes('Id')"
      >
        {{ item }}
      </OnboardingPill>
    </div>
    <div class="integration-name-wrapper section">
      <p class="label integration">
        {{ t('trigger_detail') }}
      </p>
      <OnboardingPill
        v-for="(item, key) in customIntegrationSetting?.triggerDetails"
        :key="key"
        :is-copy-enabled="key.includes('Id')"
      >
        {{ key === 'triggerType' ? t(snakeCase(item)) : item }}
      </OnboardingPill>
    </div>
    <div class="trigger-wrapper section">
      <v-form v-model="isFormValid">
        <div class="label-wrapper">
          <p class="label">
            {{ t(`apikey_label_${customIntegrationType}`) }}
          </p>
        </div>
        <p class="help-text">
          {{ t(`apikey_help_${customIntegrationType}`) }}
        </p>
        <gs-input
          v-model="apiKeyName"
          :hide-details="false"
          class="custom-input"
          :label="t('apikey_name')"
          :rules="[rules.required, rules.maxlength(maxLength), rules.minlength(minLength)]"
          counter
          :maxlength="maxLength"
        />
      </v-form>
    </div>
    <div class="button-wrapper">
      <gs-button
        type="secondary"
        icon="mdi-chevron-left"
        size="large"
        @click.prevent="$emit('back')"
      />
      <gs-button
        type="primary"
        size="large"
        full-width
        :disabled="!isFormValid || loading"
        :loading="loading"
        @click.prevent="generateApiKey"
      >
        {{ t('next') }}
      </gs-button>
    </div>
  </div>
</template>

<script lang="ts">
import type {
  CustomIntegrationSetting,
  CustomIntegrationType,
} from '@/components/onboarding/CustomIntegration.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import OnboardingPill from '@/components/onboarding/OnboardingPill.vue'
import { generateApiKey } from '@api/index'
import type { Notification } from '@/store/notification'
import type { ApiKeyType } from '@/components/account/ApiKey.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CreateApiKey',
  emits: ['back', 'keyGenerated'],
  components: { OnboardingPill },
  mixins: [RulesMixin, Utils],
  data() {
    return {
      loading: false,
      isFormValid: false,
      apiKeyName: '',
      maxLength: 40,
      minLength: 3,
    }
  },
  computed: {
    apiKeyType(): ApiKeyType {
      switch (this.customIntegrationType) {
        case 'zapier':
          return 'ZAPIER_KEY'
        case 'integrately':
          return 'INTEGRATELY_KEY'
        default:
          return 'OPEN_API_KEY'
      }
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`CreateApiKey.${key}`)
    },
    async generateApiKey() {
      this.loading = true
      try {
        const { data } = await generateApiKey(this.apiKeyName, this.apiKeyType)
        this.$emit('keyGenerated', data.apiKey)
      } catch (e) {
        console.error(e)
        this.$store.dispatch('notification/notify', {
          text: this.$t('CommonUi.error_generic'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
      }
      this.loading = false
    },
  },
  props: {
    customIntegrationSetting: {
      type: Object as PropType<CustomIntegrationSetting>,
    },
    customIntegrationType: {
      type: String as PropType<CustomIntegrationType>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.section {
  padding: 15px;
}

.section:not(:last-child) {
  border-bottom: 1px solid var(--ui-green-light);
}

.integration-name-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.label {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
}

.label-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 10px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.custom-input {
  max-width: 340px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .section {
    padding: 24px 18px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
