<template>
  <div class="data-table-wrapper elevation-1">
    <div v-show="!showLoader">
      <v-text-field
        v-model="search"
        append-inner-icon="mdi-magnify"
        placeholder="Search"
        hide-details
        color="green"
        class="product-search-field"
        @update:model-value="searchProducts()"
      />
      <div v-if="!!search && !items.length" class="not-found">
        <p class="not-found-text" v-html="t('not_found', { search })" />
      </div>
      <v-data-table-server
        v-if="showTable"
        :key="tableRender"
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        checkbox-color="#3B755F"
        show-select
        class="elevation-0 data-table"
        item-value="productId"
        width="100px"
        height="450"
        v-model:items-per-page="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: [5, 10, 20, 50] }"
        color="#3B755F"
        :items-length="itemsCount"
        @update:options="setItems"
        @toggle-select-all="setAllSelected"
      >
        <template v-slot:[`header.data-table-select`] />
        <template v-slot:[`item.image`]="{ item }">
          <img class="product-image" :src="item.image" />
        </template>

        <template v-if="!isOnboarding" v-slot:[`item.offsetType`]="{ item }">
          <div class="impact-pill-wrapper">
            <div
              v-for="({ automationName, offsets }, i) in getProductsFromOtherAutomationsWithOffsets(
                item.productId,
              )"
              :key="i"
            >
              <v-tooltip :open-on-click="isTouch" color="black" max-width="480px" location="left">
                <template v-slot:activator="{ props }">
                  <div class="impact-pill-row" v-bind="props">
                    <impact-pill
                      v-for="(offset, index) in offsets"
                      :key="index"
                      class="ma-0"
                      :impact="{ type: offset.type, amount: offset.amount }"
                    />
                  </div>
                </template>
                <div class="tooltip-content">
                  {{ automationName }}
                </div>
              </v-tooltip>
            </div>
          </div>
        </template>

        <template v-if="!isOnboarding" v-slot:[`item.active`]="{ item }">
          <v-chip
            :color="
              getProductsFromOtherAutomationsWithOffsets(item.productId).length
                ? '#3B755F'
                : '#E57066'
            "
          >
            {{
              getProductsFromOtherAutomationsWithOffsets(item.productId).length
                ? 'Active'
                : 'Inactive'
            }}
          </v-chip>
        </template>
      </v-data-table-server>
    </div>
    <LoaderBlock v-show="showLoader" />
  </div>
</template>

<script lang="ts">
import type {
  MerchantProduct,
  ProductsFetchStatusType,
  SetProductFetchStateByIntegrationIdParams,
} from '@/store'
import type { GetMerchantProductsResponse, MerchantProductSearchOptions } from '@api/index'
import { getMerchantProducts, initiateFetchProducts } from '@api/index'
import ImpactPill from '@/components/ImpactWallet/ImpactPill.vue'
import LoaderBlock from '@/components/tools/LoaderBlock.vue'
import type {
  ActiveProductsFromAutomationsWithGlobalOffsets,
  ActiveProductsFromOtherAutomationsWithOffsets,
  Automation,
} from '@/store/integrations'
import type { AxiosResponse } from 'axios'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { Utils } from '@/helpers/mixins/utilsMixin'

export default defineComponent({
  name: 'ProductTable',
  emits: ['isAllSelected', 'selectItems'],
  components: {
    LoaderBlock,
    ImpactPill,
  },
  mixins: [Utils],
  data() {
    return {
      search: '',
      isAllSelected: false,
      items: [],
      itemsCount: 0,
      tableRender: 0,
      itemsPerPage: 5,
      timer: null,
      headers: [
        {
          title: 'Image',
          key: 'image',
          sortable: false,
          width: '60px',
        },
        {
          title: 'Name',
          key: 'name',
          width: '120px',
        },
        {
          title: 'Type',
          key: 'type',
          width: '100px',
        },
        {
          title: 'Impact',
          key: 'offsetType',
          width: '210px',
          sortable: false,
        },
        {
          title: 'Status',
          key: 'active',
          width: '85px',
        },
      ],
    } as {
      search: string
      isAllSelected: boolean
      items: MerchantProduct[]
      itemsCount: number
      tableRender: number
      itemsPerPage: number
      timer: ReturnType<typeof setTimeout> | null
      headers: {
        title: string
        key: string
        sortable?: boolean
        width: string
      }[]
    }
  },
  computed: {
    areProductsFetched(): boolean {
      return (
        !!this.integrationId &&
        this.getProductFetchStatusByIntegrationId(this.integrationId) === 'ready'
      )
    },
    automation(): Automation | undefined {
      return this.automationId ? this.getAutomationById(this.automationId) : undefined
    },
    showLoader(): boolean {
      return !(this.items.length || this.search)
    },
    showTable(): boolean {
      return !!this.items.length
    },
    getProductFetchStatusByIntegrationId(): (integrationId: string) => ProductsFetchStatusType {
      return this.$store.getters['getProductFetchStatusByIntegrationId']
    },
    getAutomationById(): (automationId: string) => Automation {
      return this.$store.getters['getAutomationById']
    },
    getIntegrationIdByAutomationId(): (automationId: string) => string {
      return this.$store.getters['getIntegrationIdByAutomationId']
    },
    getActiveProductsFromOtherAutomationsWithOffsets(): (
      integrationId: string,
      automationId?: string,
    ) => ActiveProductsFromOtherAutomationsWithOffsets[] {
      return this.$store.getters['getActiveProductsFromOtherAutomationsWithOffsets']
    },
    getActiveProductsFromAutomationsWithGlobalOffsets(): (
      integrationId: string,
    ) => ActiveProductsFromAutomationsWithGlobalOffsets[] {
      return this.$store.getters['getActiveProductsFromAutomationsWithGlobalOffsets']
    },
    selectedItems: {
      get(): string[] {
        return this.selectedProducts ?? []
      },
      set(items) {
        this.$emit('selectItems', items)
      },
    },
  },
  async created() {
    if (this.isOnboarding) {
      this.headers = [
        {
          title: 'Image',
          key: 'image',
          sortable: false,
          width: '60px',
        },
        {
          title: 'Name',
          key: 'name',
          width: '120px',
        },
        {
          title: 'Type',
          key: 'type',
          width: '100px',
        },
      ]
    }

    this.$socket.client.on('saveProductsDone', this.onSaveProductsDone)

    if (!this.areProductsFetched) {
      await this.initiateFetch(true)
      do {
        await this.delay()
        await this.setItems()
      } while (!this.areProductsFetched)
    } else {
      await this.setItems()
    }
  },
  methods: {
    beforeDestroy() {
      this.$socket.client.off('saveProductsDone')
    },
    t(key: string, params?: Record<string, string>) {
      return this.$t(`ProductTable.${key}`, params ?? {})
    },
    onSaveProductsDone({ integrationId }) {
      this.setProductFetchState({
        integrationId,
        lastProductsFetchDate: new Date().toISOString(),
        state: 'ready',
      })
    },
    setAllSelected(payload) {
      this.isAllSelected = payload.value
      this.$emit('isAllSelected', this.isAllSelected)
    },
    delay() {
      return new Promise<void>((resolve) => setTimeout(resolve, 2000))
    },
    async searchProducts() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(async () => {
        if (this.search.length > 2 || !this.search.length) {
          await this.setItems({}, true)
        }
      }, 200)
    },
    async setItems(options?: Record<string, string>, refresh = false) {
      const transformedOptions: Partial<MerchantProductSearchOptions> = {
        ...options,
        sortBy: options?.sortBy?.[0] || '',
        sortDirection: options?.sortDesc?.[0] ? 'desc' : 'asc',
        search: this.search,
        limit: options?.itemsPerPage ? parseInt(options?.itemsPerPage) : 5,
      }
      const { data }: AxiosResponse<GetMerchantProductsResponse> = await getMerchantProducts(
        this.integrationId ?? '',
        this.automation?.id,
        transformedOptions || {},
      )
      this.items = data.data
      this.itemsCount = data.count
      if (refresh) this.tableRender++
    },
    async initiateFetch(eagerSave = false) {
      this.setProductFetchState({
        integrationId: this.integrationId ?? '',
        state: 'fetching',
        lastProductsFetchDate: null,
      })
      await initiateFetchProducts({
        integrationId: this.integrationId,
        eagerSave,
      })
    },
    getProductsFromOtherAutomationsWithOffsets(
      productId: string,
    ): ActiveProductsFromOtherAutomationsWithOffsets[] {
      // get all offsets per other automations for active products
      let activeProductsFromOtherAutomationsWithOffsets =
        this.getActiveProductsFromOtherAutomationsWithOffsets(
          this.integrationId ?? '',
          this.automationId,
        )
      // get all offsets per other automations with globalOffsetPerProduct active modifier
      const activeProductsFromAutomationsWithGlobalOffsets =
        this.getActiveProductsFromAutomationsWithGlobalOffsets(this.integrationId ?? '')
      // assign the productId to offsets per automation to be able to represent the global offsets for each product in the table
      activeProductsFromOtherAutomationsWithOffsets = [
        ...activeProductsFromOtherAutomationsWithOffsets,
        ...activeProductsFromAutomationsWithGlobalOffsets.map((item) => ({ ...item, productId })),
      ]

      // add current automation to the list
      if (
        this.automation?.source?.ids?.includes(productId) &&
        ['active', 'scheduled'].includes(this.automation.status) &&
        !this.automation.schedule.endDate
      ) {
        const current = {
          productId,
          automationName: this.automation?.name || '',
          offsets:
            this.automation?.offsets.map((offset) => ({
              type: offset.type,
              amount: offset.amount || 0,
            })) || [],
        }
        activeProductsFromOtherAutomationsWithOffsets = [
          current,
          ...activeProductsFromOtherAutomationsWithOffsets,
        ]
      }

      // represent offsets only for the current product
      return activeProductsFromOtherAutomationsWithOffsets.filter(
        (item) => item.productId === productId,
      )
    },
    setProductFetchState(params: SetProductFetchStateByIntegrationIdParams): void {
      return this.$store.commit('setProductFetchState', params)
    },
  },
  props: {
    automationId: {
      type: String,
    },
    integrationId: {
      type: String,
    },
    isOnboarding: {
      type: Boolean,
    },
    selectedProducts: {
      type: Array as PropType<string[]>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.data-table-wrapper {
  margin-bottom: 30px;
}

.product-search-field {
  padding-left: 15px;
  padding-right: 15px;
}

.impact-pill-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
}

.impact-pill-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.product-image {
  border-radius: var(--border-radius-small);
  object-fit: contain;
  object-position: center;
  width: 70px;
  height: auto;
  max-height: 140px;
}

.not-found {
  padding: 40px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.not-found-text::before,
.not-found-text::after {
  content: '';
  display: block;
  flex-shrink: 0;
  background: url('../../assets/reath-left.svg') no-repeat center/cover;
  height: 50px;
  width: 50px;
}

.not-found-text::after {
  background-image: url('../../assets/reath-right.svg');
}
</style>
