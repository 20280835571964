<template>
  <div>
    <!-- Drawer for invisible menu items -->
    <nav v-show="isDrawerOpen" class="drawer">
      <ul>
        <li style="padding: 0 15px">
          <OnboardingChecklistButton v-if="!isChecklistDismissed" :is-mobile="true" />
        </li>

        <li v-for="(item, a) in otherRoutes" :key="a">
          <button v-if="item.visible && item.isButton" class="drawer-item" @click="item.function">
            <div class="drawer-item__text">
              <template v-if="item.iconName">
                <GreenSparkLogo v-if="item.iconName === 'GreenSparkLogo'" class="icon svg" />
                <v-icon v-else class="icon svg">
                  {{ item.iconName }}
                </v-icon>
              </template>
              <span>
                {{ t(item.linkText) }}
              </span>
            </div>
          </button>
          <router-link
            v-if="item.visible && !item.isButton"
            :to="{ path: item.path }"
            class="drawer-item"
            :target="item.external ? '_blank' : false"
          >
            <div class="drawer-item__text">
              <template v-if="item.iconName">
                <GreenSparkLogo v-if="item.iconName === 'GreenSparkLogo'" class="icon svg" />
                <v-icon v-else class="icon svg">
                  {{ item.iconName }}
                </v-icon>
              </template>
              <span>
                {{ t(item.linkText) }}
              </span>
            </div>
          </router-link>
        </li>
      </ul>
    </nav>
    <!-- Mobile menu bar -->
    <nav class="mobile-menu-wrapper">
      <template v-for="(item, index) in visibleRoutes">
        <button
          v-if="item.isButton && item.visible"
          :key="index"
          class="menu-item"
          @click="item.function"
        >
          <template v-if="item.iconName">
            <GreenSparkLogo
              v-if="item.iconName === 'GreenSparkLogo'"
              class="icon svg material-icons-outlined"
            />
            <v-icon v-else class="icon svg material-icons-outlined">
              {{ item.iconName }}
            </v-icon>
          </template>
          <span class="menu-item-text">
            {{ t(item.linkText) }}
          </span>
        </button>
        <router-link
          v-if="item.visible && !item.isButton"
          :key="index"
          :to="{ path: item.path }"
          class="menu-item"
          :target="item.external ? '_blank' : false"
        >
          <template v-if="item.iconName">
            <GreenSparkLogo
              v-if="item.iconName === 'GreenSparkLogo'"
              class="icon svg material-icons-outlined"
            />
            <v-icon v-else class="icon svg material-icons-outlined">
              {{ item.iconName }}
            </v-icon>
          </template>
          <span class="menu-item-text">
            {{ t(item.linkText) }}
          </span>
        </router-link>
      </template>

      <button v-if="otherRoutes.length" class="more" @click="toggleDrawer">
        <img class="icon" alt="more" :src="require('@/assets/icons/quick-menu.svg')" />
      </button>
    </nav>
    <!-- Drawer for the Support menu items -->
    <ul v-show="isSupportMenuOpen" class="drawer-menu">
      <button
        v-if="isSupportMenuOpen"
        class="close-support-menu"
        @click.prevent="toggleSupportMenu()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </button>
      <p class="segment-header">
        {{ t('documentation') }}
      </p>
      <li class="drawer-menu__list-item">
        <a :href="KNOWLEDGE_BASE_URL" target="_blank" class="drawer-menu__link">
          <v-icon class="icon material-icons-outlined"> mdi-book-outline </v-icon>
          {{ t('knowledge_base') }}
        </a>
      </li>
      <li class="drawer-menu__list-item">
        <a :href="API_DOCUMENTATION_URL" target="_blank" class="drawer-menu__link">
          <v-icon class="icon"> mdi-link-variant </v-icon>
          {{ t('api_docs') }}
        </a>
      </li>

      <li class="drawer-menu__list-item" v-if="isBusiness">
        <a class="beamer-trigger-button drawer-menu__link">
          <v-icon class="icon"> mdi-bell-outline </v-icon>
          <span class="notification-item-text">{{ t('whats_new') }}</span>
        </a>
      </li>

      <p class="segment-header">
        {{ t('contact') }}
      </p>
      <li class="drawer-menu__list-item">
        <button class="drawer-menu__link" @click="openChatCloseDrawer">
          <v-icon class="icon material-icons-outlined"> mdi-message-text-outline </v-icon>
          {{ t('open_chat') }}
        </button>
      </li>
      <li class="drawer-menu__list-item">
        <a :href="SUPPORT_EMAIL_URL" target="_blank" class="drawer-menu__link">
          <v-icon class="icon material-icons-outlined"> mdi-email-outline </v-icon>
          {{ t('email') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { logOut } from '@api/index'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import OnboardingChecklistButton from '@/components/onboarding/OnboardingChecklistButton.vue'
import { API_DOCUMENTATION_URL, KNOWLEDGE_BASE_URL, SUPPORT_EMAIL_URL } from '@/helpers/constants'
import GreenSparkLogo from '@/components/icons/GreenSparkLogo.vue'
import { defineComponent } from 'vue'

type MenuItem = {
  path?: string
  iconName?: string
  linkText: string
  visible: boolean
  function?: (...params) => void
  isButton?: boolean
  external: boolean
}

export default defineComponent({
  name: 'MobileMenu',
  components: { OnboardingChecklistButton, GreenSparkLogo },
  mixins: [LoadCrispMixin],
  data() {
    return {
      API_DOCUMENTATION_URL: '',
      KNOWLEDGE_BASE_URL: '',
      SUPPORT_EMAIL_URL: '',
      isDrawerOpen: false,
      isSupportMenuOpen: false,
      otherRoutes: [],
      visibleRoutes: [],
    } as {
      API_DOCUMENTATION_URL: string
      KNOWLEDGE_BASE_URL: string
      SUPPORT_EMAIL_URL: string
      isDrawerOpen: boolean
      isSupportMenuOpen: boolean
      otherRoutes: MenuItem[]
      visibleRoutes: MenuItem[]
    }
  },
  computed: {
    isSupportButtonIsVisible(): boolean {
      const supportButton = this.routes.find((route) => route.linkText === 'support')
      return this.visibleRoutes.some((r) => r.path === supportButton?.path)
    },
    routes(): MenuItem[] {
      return [
        {
          path: '/carbon-report',
          iconName: 'mdi-shoe-print',
          linkText: 'carbon_footprint',
          visible: this.carbonFootprint,
          external: false,
        },
        {
          path: '/',
          iconName: this.hasImpactActions && !this.isBusiness ? 'mdi-shopping' : 'GreenSparkLogo',
          linkText: this.hasImpactActions && !this.isBusiness ? 'impact_wallet' : 'your_impact',
          visible: true,
          external: false,
        },
        {
          path: '/your-impact',
          iconName: 'GreenSparkLogo',
          linkText: 'your_impact',
          visible: this.hasImpactActions && !this.isBusiness,
          external: false,
        },
        {
          path: this.isBusiness ? '/add-impact' : '/add-impact/tailored-impact',
          iconName: 'mdi-plus-circle',
          linkText: 'add_impact',
          visible: true,
          external: false,
        },
        {
          path: '/shareables',
          iconName: 'mdi-marker-check',
          linkText: this.isBusiness ? 'engagement_hub' : 'shareables',
          visible: true,
          external: false,
        },
        {
          path: '/analytics',
          iconName: 'mdi-chart-box-outline',
          linkText: 'analytics',
          visible: this.isBusiness,
          external: false,
        },
        {
          path: '/team',
          iconName: 'mdi-account-group',
          linkText: 'team',
          visible: this.isBusiness,
          external: false,
        },
        {
          path: '/network',
          iconName: 'mdi-share-variant',
          linkText: 'network',
          visible: this.isBusiness,
          external: false,
        },
        {
          path: '/projects',
          iconName: 'mdi-hand-heart',
          linkText: 'supported_projects',
          visible: true,
          external: false,
        },
        {
          path: '/ledger',
          iconName: 'mdi-earth',
          linkText: 'public_ledger',
          visible: true,
          external: false,
        },
        {
          path: '/account?tab=profile',
          iconName: 'mdi-account',
          linkText: 'account',
          visible: true,
          external: false,
        },
        {
          path: this.livePagePath,
          iconName: 'mdi-file-account-outline',
          linkText: 'live_page',
          visible: true,
          external: true,
        },
        {
          iconName: 'mdi-comment-question',
          linkText: 'support',
          function: this.toggleSupportMenu,
          isButton: true,
          visible: true,
          external: false,
        },
        {
          iconName: 'mdi-logout',
          linkText: 'logout',
          function: this.logOut,
          isButton: true,
          visible: true,
          external: false,
        },
      ]
    },
    livePagePath(): string {
      const livePageParam = this.isBusiness
        ? this.account.companyName.replace(/\s/g, '-').toLowerCase()
        : this.currentUser.firstName.replace(/\s/g, '-').toLowerCase() +
          '-' +
          this.currentUser.lastName.replace(/\s/g, '-').toLowerCase()
      return `/dashboard/${this.account.livePageId}/${livePageParam}`
    },
    hasImpactActions(): boolean {
      return this.$store.getters['getHasImpactActions']
    },
    isChecklistDismissed(): boolean {
      return this.$store.getters['getChecklistDismissed']
    },
    carbonFootprint(): boolean {
      return this.$store.getters['getCarbonFootprintFeatureFlag']
    },
    isBusiness(): boolean {
      return this.$store.getters['getAccountTypeIsBusiness']
    },
  },
  created() {
    this.setVisibleRoutes()
    this.setItemsPosition()
    this.API_DOCUMENTATION_URL = API_DOCUMENTATION_URL
    this.KNOWLEDGE_BASE_URL = KNOWLEDGE_BASE_URL
    this.SUPPORT_EMAIL_URL = SUPPORT_EMAIL_URL
  },
  methods: {
    t(key: string) {
      return this.$t(`MobileMenu.${key}`)
    },
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen
      this.toggleModalClass(this.isDrawerOpen)
    },
    toggleSupportMenu() {
      // if the support button is not visible, it was clicked from the drawer, so we need to toggle the drawer
      this.isDrawerOpen = !this.isSupportButtonIsVisible
      this.isSupportMenuOpen = !this.isSupportMenuOpen
      this.toggleModalClass(this.isSupportMenuOpen)
    },
    toggleModalClass(currentStatus: boolean) {
      if (currentStatus) {
        document.documentElement.classList.add('modal-open')
        document.body.style.position = 'relative' // for mobile devices
      } else {
        document.documentElement.classList.remove('modal-open')
        document.body.style.position = 'static' // for mobile devices
      }
    },
    logOut() {
      this.isDrawerOpen = false
      document.documentElement.classList.remove('modal-open')
      logOut()
    },
    openChatCloseDrawer() {
      this.isDrawerOpen = false
      this.isSupportMenuOpen = false
      document.documentElement.classList.remove('modal-open')
      this.openChat()
    },
    setVisibleRoutes() {
      this.visibleRoutes = this.routes.filter((route) => route.visible)
    },
    setItemsPosition(isShrinking = true) {
      const paddingWidth = 40
      const contextButtonWidth = 24
      const menuItemWidth = 75
      const availableWidth = (this.windowWidth ?? 0) - paddingWidth - contextButtonWidth
      const minNumberOfMenuItems = 4

      // a menu item is 75px wide, so if there is less than 75px available, move the last item to the otherRoutes array
      if (
        isShrinking &&
        availableWidth - menuItemWidth * this.visibleRoutes.length < menuItemWidth
      ) {
        while (
          availableWidth - menuItemWidth * this.visibleRoutes.length < menuItemWidth &&
          this.visibleRoutes.length > minNumberOfMenuItems
        ) {
          const lastItem = this.visibleRoutes.pop()
          if (lastItem) this.otherRoutes.unshift(lastItem)
        }
        // if there is more than 75px available, move the first item from the otherRoutes array to the visibleRoutes array
      } else if (
        !isShrinking &&
        availableWidth - menuItemWidth * this.visibleRoutes.length >= menuItemWidth &&
        this.otherRoutes.length
      ) {
        while (
          availableWidth - menuItemWidth * this.visibleRoutes.length >= menuItemWidth &&
          this.otherRoutes.length
        ) {
          const firstItem = this.otherRoutes.shift()
          if (firstItem) this.visibleRoutes.push(firstItem)
        }
      }
    },
    onWindowWidthChange(newVal, oldVal) {
      const isShrinking = oldVal > newVal
      this.setItemsPosition(isShrinking)
    },
    onRouteChange() {
      this.isDrawerOpen = false
      document.documentElement.classList.remove('modal-open')
      document.body.style.position = 'static'
    },
  },
  props: {
    windowWidth: {
      type: Number,
    },
  },
  watch: {
    windowWidth: [
      {
        handler: 'onWindowWidthChange',
      },
    ],
    $route: [
      {
        handler: 'onRouteChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.mobile-menu-wrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  background: var(--ui-white);
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  z-index: 4;
  box-shadow: var(--box-shadow-light);
  max-width: 100vw;
}

.menu-item {
  background: var(--ui-white);
  width: 75px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 12px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: solid 5px var(--ui-white);
  flex: 1 0 0;
  text-decoration: none;
  color: var(--font-color-primary);
  min-width: 75px;
}

.menu-item:active {
  background: var(--ui-green-light);
}

.menu-item.router-link-active {
  border-top: solid 5px var(--ui-green);
}

.menu-item-text {
  font-size: 12px;
  font-weight: 500;
  color: inherit;
  line-height: 14px;
  height: 28px;
  text-align: center;
}

.icon {
  height: 24px;
  width: 24px;
  font-size: 24px;
  color: inherit;
  margin: 0 0 5px 0;
}

.drawer-item__text .icon,
.drawer-menu__link .icon {
  margin: 0 15px 0 0;
  width: 22px;
  font-size: 22px;
}

button.more {
  display: flex;
  align-items: center;
  width: 24px;
  min-width: 24px;
}

.drawer {
  background: var(--ui-white);
  min-height: 100px;
  min-width: 250px;
  padding-bottom: 25px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
  height: 100%;
  border-bottom: 1px solid var(--gray);
}

.drawer ul {
  list-style-type: none;
  padding: 0;
}

.appear-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.5s;
}

.drawer-item {
  display: block;
  color: var(--font-color-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

.beamer-trigger-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .notification-item-text {
    flex-grow: 1;
    flex-shrink: 0;
  }

  :deep(.beamer_icon.active) {
    align-self: flex-end;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    padding: 4px 8px;
    border-radius: 4px;
    color: var(--ui-black);
    background-color: var(--ui-emerald-green) !important;
    position: relative;
    height: auto;
    width: auto;
  }
}

.drawer-item.router-link-active {
  background: var(--ui-green);
  color: var(--ui-white);
}

.drawer-item__text {
  align-items: center;
  display: flex;
  color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 15px;
  padding: 15px 0;
  font-size: 18px;
  line-height: 26px;
  text-decoration: none;
  width: 100%;
}

.drawer-menu__link {
  align-items: center;
  display: flex;
  color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px 0;
  font-size: 18px;
  line-height: 26px;
  text-decoration: none;
  width: 100%;
}

.segment-header {
  padding: 15px 0;
  font-size: 16px;
  margin: 0;
}

.drawer-menu {
  background: var(--ui-white);
  min-height: 100px;
  min-width: 250px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
  height: 100%;
  border-bottom: 1px solid var(--gray);
  list-style-type: none;
  padding: 15px 15px 25px;
}

.close-support-menu {
  border-radius: var(--border-radius-small);
  border: 2px solid var(--gray-light);
  font-size: 18px;
  line-height: 24px;
  height: 30px;
  width: 30px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .mobile-menu-wrapper {
    display: none;
  }
}
</style>
