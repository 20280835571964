<template>
  <div class="employee-wrapper">
    <div class="main-wrapper">
      <div class="header-wrapper">
        <div class="header">
          {{ t('subscription') }}
        </div>
        <div class="back" @click="$router.push('/choose-signup')">
          <div>
            {{ t('cancel') }}
          </div>
        </div>
      </div>

      <div class="columns-wrapper">
        <!-- Positive Family Plan -->
        <div class="column">
          <div class="employee-type-wrapper">
            <div class="plan-name">
              {{ t('positive_family') }}
            </div>

            <!-- Adults -->
            <div class="plan-price-input-wrapper">
              <div class="plan-price-wrapper">
                <div v-if="account?.currency === 'euro'" class="plan-price">
                  {{ currencyEuro(positiveFamilyAdultPrice) }}
                </div>
                <div v-if="account?.currency === 'pound'" class="plan-price">
                  {{ currencyPound(positiveFamilyAdultPrice) }}
                </div>
                <div v-if="account?.currency === 'dollar'" class="plan-price">
                  {{ currencyDollar(positiveFamilyAdultPrice) }}
                </div>
                <div class="per-employee">
                  {{ t('per_adult_month') }}
                </div>
              </div>

              <div class="text-field-wrapper">
                <v-text-field
                  v-model="positiveFamilyAdultAmount"
                  :label="t('amount_adult')"
                  variant="outlined"
                  type="number"
                  flat
                  min="0"
                  color="#3B755F"
                  class="employee-input"
                  hide-details
                />
                <div class="plus-minus">
                  <div
                    class="add"
                    style="margin-bottom: 1px"
                    @click="
                      positiveFamilyAdultAmount !== null
                        ? positiveFamilyAdultAmount++
                        : (positiveFamilyAdultAmount = 1)
                    "
                  >
                    +
                  </div>
                  <div
                    class="add"
                    style="margin-top: 1px"
                    @click="positiveFamilyAdultAmount ? positiveFamilyAdultAmount-- : null"
                  >
                    -
                  </div>
                </div>
              </div>
            </div>

            <!-- Children -->
            <div class="plan-price-input-wrapper">
              <div class="plan-price-wrapper">
                <div v-if="account?.currency === 'euro'" class="plan-price">
                  {{ currencyEuro(positiveFamilyChildPrice) }}
                </div>
                <div v-if="account?.currency === 'pound'" class="plan-price">
                  {{ currencyPound(positiveFamilyChildPrice) }}
                </div>
                <div v-if="account?.currency === 'dollar'" class="plan-price">
                  {{ currencyDollar(positiveFamilyChildPrice) }}
                </div>
                <div class="per-employee">
                  {{ t('per_child_month') }}
                </div>
              </div>

              <div class="text-field-wrapper">
                <v-text-field
                  v-model="positiveFamilyChildAmount"
                  :label="t('amount_child')"
                  variant="outlined"
                  type="number"
                  flat
                  min="0"
                  color="#3B755F"
                  class="employee-input"
                  hide-details
                />
                <div class="plus-minus">
                  <div
                    class="add"
                    style="margin-bottom: 1px"
                    @click="
                      positiveFamilyChildAmount !== null
                        ? positiveFamilyChildAmount++
                        : (positiveFamilyChildAmount = 1)
                    "
                  >
                    +
                  </div>
                  <div
                    class="add"
                    style="margin-top: 1px"
                    @click="positiveFamilyChildAmount ? positiveFamilyChildAmount-- : null"
                  >
                    -
                  </div>
                </div>
              </div>
            </div>

            <!-- Pets -->
            <div class="plan-price-input-wrapper">
              <div class="plan-price-wrapper">
                <div v-if="account?.currency === 'euro'" class="plan-price">
                  {{ currencyEuro(positiveFamilyPetPrice) }}
                </div>
                <div v-if="account?.currency === 'pound'" class="plan-price">
                  {{ currencyPound(positiveFamilyPetPrice) }}
                </div>
                <div v-if="account?.currency === 'dollar'" class="plan-price">
                  {{ currencyDollar(positiveFamilyPetPrice) }}
                </div>
                <div class="per-employee">
                  {{ t('per_pet_month') }}
                </div>
              </div>

              <div class="text-field-wrapper">
                <v-text-field
                  v-model="positiveFamilyPetAmount"
                  :label="t('amount_pet')"
                  variant="outlined"
                  type="number"
                  flat
                  min="0"
                  color="#3B755F"
                  class="employee-input"
                  hide-details
                />
                <div class="plus-minus">
                  <div
                    class="add"
                    style="margin-bottom: 1px"
                    @click="
                      positiveFamilyPetAmount !== null
                        ? positiveFamilyPetAmount++
                        : (positiveFamilyPetAmount = 1)
                    "
                  >
                    +
                  </div>
                  <div
                    class="add"
                    style="margin-top: 1px"
                    @click="positiveFamilyPetAmount ? positiveFamilyPetAmount-- : null"
                  >
                    -
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Earth Positive Plan -->
        <div class="column">
          <div class="employee-type-wrapper">
            <div class="plan-name">
              {{ t('earth_positive_family') }}
            </div>

            <!-- Adults -->
            <div class="plan-price-input-wrapper">
              <div class="plan-price-wrapper">
                <div v-if="account?.currency === 'euro'" class="plan-price">
                  {{ currencyEuro(earthPositiveFamilyAdultPrice) }}
                </div>
                <div v-if="account?.currency === 'pound'" class="plan-price">
                  {{ currencyPound(earthPositiveFamilyAdultPrice) }}
                </div>
                <div v-if="account?.currency === 'dollar'" class="plan-price">
                  {{ currencyDollar(earthPositiveFamilyAdultPrice) }}
                </div>
                <div class="per-employee">
                  {{ t('per_adult_month') }}
                </div>
              </div>

              <div class="text-field-wrapper">
                <v-text-field
                  v-model="earthPositiveFamilyAdultAmount"
                  :label="t('amount_adult')"
                  variant="outlined"
                  type="number"
                  flat
                  min="0"
                  color="#3B755F"
                  class="employee-input"
                  hide-details
                />
                <div class="plus-minus">
                  <div
                    class="add"
                    style="margin-bottom: 1px"
                    @click="
                      earthPositiveFamilyAdultAmount !== null
                        ? earthPositiveFamilyAdultAmount++
                        : (earthPositiveFamilyAdultAmount = 1)
                    "
                  >
                    +
                  </div>
                  <div
                    class="add"
                    style="margin-top: 1px"
                    @click="
                      earthPositiveFamilyAdultAmount ? earthPositiveFamilyAdultAmount-- : null
                    "
                  >
                    -
                  </div>
                </div>
              </div>
            </div>

            <!-- Children -->
            <div class="plan-price-input-wrapper">
              <div class="plan-price-wrapper">
                <div v-if="account?.currency === 'euro'" class="plan-price">
                  {{ currencyEuro(earthPositiveFamilyChildPrice) }}
                </div>
                <div v-if="account?.currency === 'pound'" class="plan-price">
                  {{ currencyPound(earthPositiveFamilyChildPrice) }}
                </div>
                <div v-if="account?.currency === 'dollar'" class="plan-price">
                  {{ currencyDollar(earthPositiveFamilyChildPrice) }}
                </div>
                <div class="per-employee">
                  {{ t('per_child_month') }}
                </div>
              </div>

              <div class="text-field-wrapper">
                <v-text-field
                  v-model="earthPositiveFamilyChildAmount"
                  :label="t('amount_child')"
                  variant="outlined"
                  type="number"
                  flat
                  min="0"
                  color="#3B755F"
                  class="employee-input"
                  hide-details
                />
                <div class="plus-minus">
                  <div
                    class="add"
                    style="margin-bottom: 1px"
                    @click="
                      earthPositiveFamilyChildAmount !== null
                        ? earthPositiveFamilyChildAmount++
                        : (earthPositiveFamilyChildAmount = 1)
                    "
                  >
                    +
                  </div>
                  <div
                    class="add"
                    style="margin-top: 1px"
                    @click="
                      earthPositiveFamilyChildAmount ? earthPositiveFamilyChildAmount-- : null
                    "
                  >
                    -
                  </div>
                </div>
              </div>
            </div>

            <!-- Pets -->
            <div class="plan-price-input-wrapper">
              <div class="plan-price-wrapper">
                <div v-if="account?.currency === 'euro'" class="plan-price">
                  {{ currencyEuro(earthPositiveFamilyPetPrice) }}
                </div>
                <div v-if="account?.currency === 'pound'" class="plan-price">
                  {{ currencyPound(earthPositiveFamilyPetPrice) }}
                </div>
                <div v-if="account?.currency === 'dollar'" class="plan-price">
                  {{ currencyDollar(earthPositiveFamilyPetPrice) }}
                </div>
                <div class="per-employee">
                  {{ t('per_pet_month') }}
                </div>
              </div>

              <div class="text-field-wrapper">
                <v-text-field
                  v-model="earthPositiveFamilyPetAmount"
                  :label="t('amount_pet')"
                  variant="outlined"
                  type="number"
                  flat
                  min="0"
                  color="#3B755F"
                  class="employee-input"
                  hide-details
                />
                <div class="plus-minus">
                  <div
                    class="add"
                    style="margin-bottom: 1px"
                    @click="
                      earthPositiveFamilyPetAmount !== null
                        ? earthPositiveFamilyPetAmount++
                        : (earthPositiveFamilyPetAmount = 1)
                    "
                  >
                    +
                  </div>
                  <div
                    class="add"
                    style="margin-top: 1px"
                    @click="earthPositiveFamilyPetAmount ? earthPositiveFamilyPetAmount-- : null"
                  >
                    -
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="button-wrapper">
        <gs-button
          :disabled="totalMembers === 0"
          :full-width="true"
          size="large"
          @click.prevent="onContinue"
        >
          {{ t('continue') }}
        </gs-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChooseFamilyPlan',
  emits: ['nextClick'],
  methods: {
    t(key: string) {
      return this.$t(`ChooseFamilyPlan.${key}`)
    },
    onContinue() {
      this.$emit('nextClick')
    },
    setUserPersonalPlans(plan) {
      return this.$store.commit('setUserPersonalPlans', plan)
    },
  },
  computed: {
    totalMembers() {
      return (
        (this.positiveFamilyAdultAmount ?? 0) +
        (this.positiveFamilyChildAmount ?? 0) +
        (this.positiveFamilyPetAmount ?? 0) +
        (this.earthPositiveFamilyAdultAmount ?? 0) +
        (this.earthPositiveFamilyChildAmount ?? 0) +
        (this.earthPositiveFamilyPetAmount ?? 0)
      )
    },
    positiveFamilyAdultAmount: {
      get(): number | null {
        return this.account?.personalPlans.positiveFamilyAdult ?? null
      },
      set(value: string) {
        const parsed = parseInt(value)
        if (Number.isNaN(parsed)) {
          this.setUserPersonalPlans({ positiveFamilyAdult: null })
          return
        }

        this.setUserPersonalPlans({ positiveFamilyAdult: parsed >= 0 ? parsed : 0 })
      },
    },
    positiveFamilyChildAmount: {
      get(): number | null {
        return this.account?.personalPlans.positiveFamilyChild ?? null
      },
      set(value: string) {
        const parsed = parseInt(value)
        if (Number.isNaN(parsed)) {
          this.setUserPersonalPlans({ positiveFamilyChild: null })
          return
        }

        this.setUserPersonalPlans({ positiveFamilyChild: parsed >= 0 ? parsed : 0 })
      },
    },
    positiveFamilyPetAmount: {
      get(): number | null {
        return this.account?.personalPlans.positiveFamilyPet ?? null
      },
      set(value: string) {
        const parsed = parseInt(value)
        if (Number.isNaN(parsed)) {
          this.setUserPersonalPlans({ positiveFamilyPet: null })
          return
        }

        this.setUserPersonalPlans({ positiveFamilyPet: parsed >= 0 ? parsed : 0 })
      },
    },
    earthPositiveFamilyAdultAmount: {
      get(): number | null {
        return this.account?.personalPlans.earthPositiveFamilyAdult ?? null
      },
      set(value: string) {
        const parsed = parseInt(value)
        if (Number.isNaN(parsed)) {
          this.setUserPersonalPlans({ earthPositiveFamilyAdult: null })
          return
        }

        this.setUserPersonalPlans({ earthPositiveFamilyAdult: parsed >= 0 ? parsed : 0 })
      },
    },
    earthPositiveFamilyChildAmount: {
      get(): number | null {
        return this.account?.personalPlans.earthPositiveFamilyChild ?? null
      },
      set(value: string) {
        const parsed = parseInt(value)
        if (Number.isNaN(parsed)) {
          this.setUserPersonalPlans({ earthPositiveFamilyChild: null })
          return
        }

        this.setUserPersonalPlans({ earthPositiveFamilyChild: parsed >= 0 ? parsed : 0 })
      },
    },
    earthPositiveFamilyPetAmount: {
      get(): number | null {
        return this.account?.personalPlans.earthPositiveFamilyPet ?? null
      },
      set(value: string) {
        const parsed = parseInt(value)
        if (Number.isNaN(parsed)) {
          this.setUserPersonalPlans({ earthPositiveFamilyPet: null })
          return
        }

        this.setUserPersonalPlans({ earthPositiveFamilyPet: parsed >= 0 ? parsed : 0 })
      },
    },
  },
  props: {
    account: {
      type: Object as PropType<Account>,
    },
    positiveFamilyAdultPrice: {
      type: Number,
    },
    positiveFamilyChildPrice: {
      type: Number,
    },
    positiveFamilyPetPrice: {
      type: Number,
    },
    earthPositiveFamilyAdultPrice: {
      type: Number,
    },
    earthPositiveFamilyChildPrice: {
      type: Number,
    },
    earthPositiveFamilyPetPrice: {
      type: Number,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.employee-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-wrapper {
  height: 100%;
  position: relative;
  padding: 24px 36px;
  background: var(--ui-white);
  box-shadow: var(--box-shadow-wide);
  border-radius: var(--border-radius-big);
}

.back {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: var(--ui-green);
  cursor: pointer;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #212121;
}

.columns-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.column {
  width: 48%;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 10px;
}

.employee-type-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-name {
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: var(--font-color-primary);
}

.plan-price-input-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 30px;
}

.plan-price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-price {
  color: var(--font-color-primary);
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

.per-employee {
  color: var(--gray);
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
}

.text-field-wrapper {
  display: flex;
}

.employee-input {
  min-width: 80%;
  margin-bottom: 14px;
}

.plus-minus {
  min-width: 10%;
  cursor: pointer;
  height: 56px;
  margin-left: 6px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ui-white);
  font-weight: 600;
  background: var(--ui-green);
  font-size: 18px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
}

.icon {
  width: 22px;
  padding-top: 3px;
  filter: invert(81%) sepia(73%) saturate(459%) hue-rotate(132deg) brightness(78%) contrast(88%);
}

@media #{map-get($display-breakpoints, 'md')} {
  .main-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
  }

  .button-wrapper {
    position: unset;
    width: 100%;
  }

  .header-wrapper {
    width: 100%;
  }

  .columns-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
  }

  .column {
    width: 100%;
  }

  .text-field-wrapper {
    width: 50%;
  }

  .employee-wrapper {
    margin-bottom: 20px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .header {
    font-size: 30px;
    text-align: center;
    margin-top: 15px;
  }

  .column {
    width: 100%;
    margin-bottom: 15px;
  }

  .columns-wrapper {
    width: 100%;
    flex-direction: column-reverse;
  }

  .button-wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .back {
    justify-content: flex-start;
    margin-bottom: 5px;
  }

  .main-wrapper {
    height: auto;
    padding: 24px 10px;
  }

  .header-wrapper {
    flex-direction: column-reverse;
  }

  .plan-price-input-wrapper {
    flex-direction: column;
    margin-top: 10px;
  }

  .plan-price-wrapper {
    flex-direction: row;
    align-items: baseline;
  }

  .per-employee {
    margin-left: 10px;
  }

  .plan-name {
    font-size: 26px;
  }

  .plan-price {
    font-size: 25px;
  }

  .employee-wrapper {
    margin-bottom: 20px;
  }
}
</style>
