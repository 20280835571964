import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fa17bb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "menu-bar" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "menu-item-label" }
const _hoisted_5 = { class: "accordion-wrapper" }
const _hoisted_6 = { class: "accordion-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_ProjectReceipt = _resolveComponent("ProjectReceipt")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_TransferredImpact = _resolveComponent("TransferredImpact")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['public-ledger', { live: _ctx.livePageData }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        class: _normalizeClass(['header-title', { centered: _ctx.isHeaderCentered }])
      }, _toDisplayString(_ctx.t('header')), 3),
      _createElementVNode("div", {
        class: _normalizeClass(['header-description-wrapper', { centered: _ctx.isHeaderCentered }])
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(['header-description', { centered: _ctx.isHeaderCentered }])
        }, _toDisplayString(_ctx.livePageData ? _ctx.t('text', { name: _ctx.livePageData.companyName }) : _ctx.t('public_ledger_text')), 3)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (type) => {
        return (_openBlock(), _createElementBlock("button", {
          key: type,
          class: _normalizeClass(['menu-item', { 'is-active': _ctx.activeTab === type }]),
          onClick: ($event: any) => (_ctx.setActiveTab(type))
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t(type)), 1)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_v_expansion_panels, {
        "model-value": _ctx.accordion,
        variant: "accordion",
        flat: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.receiptsByType(_ctx.activeTab), (value, key) => {
            return (_openBlock(), _createBlock(_component_v_expansion_panel, { key: key }, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panel_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value.date), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_v_expansion_panel_text, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.receipts, (receipt, b) => {
                        return (_openBlock(), _createBlock(_component_ProjectReceipt, {
                          key: b,
                          name: receipt.receiptName,
                          "image-url": receipt.imageUrl
                        }, null, 8, ["name", "image-url"]))
                      }), 128))
                    ])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["model-value"])
    ]),
    (_ctx.isLifePageDataShown)
      ? (_openBlock(), _createBlock(_component_TransferredImpact, {
          key: 0,
          "live-page-data": _ctx.livePageData,
          live: true
        }, null, 8, ["live-page-data"]))
      : _createCommentVNode("", true)
  ], 2))
}