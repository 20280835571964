import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c6dea31"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "integrations-table"
}
const _hoisted_2 = { class: "integration-icon-wrapper" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "mb-0 automations-pill" }
const _hoisted_7 = { class: "drawer" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "table-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_data_table, {
          items: _ctx.integrationList,
          headers: _ctx.headers,
          page: _ctx.page,
          "onUpdate:page": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event)),
          "hide-default-footer": "",
          "row-props": { class: _ctx.rowClass },
          "items-per-page": _ctx.itemsPerPage,
          search: _ctx.search,
          height: "423"
        }, {
          top: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              "append-inner-icon": "mdi-magnify"
            }, null, 8, ["modelValue"])
          ]),
          [`item.image`]: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_2, [
              (item.platform === 'custom' && !item.icon)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    class: "integration-icon custom"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-puzzle-outline ")
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    class: "integration-icon",
                    src: item.icon,
                    alt: item.platform
                  }, null, 8, _hoisted_3))
            ])
          ]),
          [`item.platform`]: _withCtx(({ item }) => [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getIntegrationPlatformName(item.platform)), 1)
          ]),
          [`item.id`]: _withCtx(({ item }) => [
            _createElementVNode("span", {
              onClick: _withModifiers(($event: any) => (_ctx.copyTextToClipboard(item.id)), ["prevent"])
            }, [
              _createTextVNode(_toDisplayString(_ctx.shortenText(item.id)) + " ", 1),
              _createVNode(_component_v_icon, {
                size: 18,
                class: "copy ml-1"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isCopied ? 'mdi-check-circle' : 'mdi-content-copy'), 1)
                ]),
                _: 1
              })
            ], 8, _hoisted_5)
          ]),
          [`item.automationNumber`]: _withCtx(({ item }) => [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.isCustom
              ? _ctx.tc('triggers', item.automationNumber)
              : _ctx.tc('automations', item.automationNumber)), 1)
          ]),
          [`item.status`]: _withCtx(({ item }) => [
            _createElementVNode("span", {
              class: _normalizeClass(['status-pill', _ctx.getIntegrationStatus(item)])
            }, _toDisplayString(_ctx.t(_ctx.getIntegrationStatus(item))), 3)
          ]),
          [`item.context`]: _withCtx(({ item }) => [
            (_ctx.mode === 'manage')
              ? (_openBlock(), _createBlock(_component_v_menu, { key: 0 }, {
                  activator: _withCtx(({ props }) => [
                    _createElementVNode("button", _mergeProps({ class: "context-menu-button" }, props), [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-dots-horizontal")
                        ]),
                        _: 1
                      })
                    ], 16)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      (_ctx.hasIntegrationError(item))
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "drawer-button",
                            onClick: ($event: any) => (_ctx.handleIntegrationError(item))
                          }, [
                            _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" mdi-lock-reset ")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(_toDisplayString(_ctx.t('reauth')), 1)
                          ], 8, _hoisted_8))
                        : _createCommentVNode("", true),
                      _createElementVNode("button", {
                        class: "drawer-button",
                        onClick: ($event: any) => (_ctx.selectIntegration(item))
                      }, [
                        _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" mdi-creation ")
                          ]),
                          _: 1
                        }),
                        _createTextVNode(_toDisplayString(_ctx.t('add_automation')), 1)
                      ], 8, _hoisted_9),
                      (_ctx.isIntegrationSyncable(item))
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            class: "drawer-button",
                            onClick: ($event: any) => (_ctx.openSyncDialog(item))
                          }, [
                            _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" mdi-sync ")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.sync')), 1)
                          ], 8, _hoisted_10))
                        : _createCommentVNode("", true),
                      _createElementVNode("button", {
                        class: "drawer-button",
                        onClick: ($event: any) => (_ctx.openDeleteIntegrationDialog(item))
                      }, [
                        _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" mdi-delete-outline ")
                          ]),
                          _: 1
                        }),
                        _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.delete')), 1)
                      ], 8, _hoisted_11)
                    ])
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "select-button",
                  onClick: ($event: any) => (_ctx.selectIntegration(item))
                }, _toDisplayString(_ctx.t('select')), 9, _hoisted_12))
          ]),
          _: 2
        }, 1032, ["items", "headers", "page", "row-props", "items-per-page", "search"]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_v_pagination, {
            "total-visible": 3,
            modelValue: _ctx.page,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.page) = $event)),
            length: _ctx.pageCount
          }, null, 8, ["modelValue", "length"])
        ])
      ]))
}