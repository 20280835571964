import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06fee894"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog" }
const _hoisted_2 = { class: "input-wrapper" }
const _hoisted_3 = { class: "description" }
const _hoisted_4 = { class: "demo-feed" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SustainabilityGoals = _resolveComponent("SustainabilityGoals")!
  const _component_SustainabilitySiteSetup = _resolveComponent("SustainabilitySiteSetup")!
  const _component_DemoFeed = _resolveComponent("DemoFeed")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t('description')), 1),
      _createVNode(_component_SustainabilityGoals, {
        modelValue: _ctx.localGoals,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localGoals) = $event)),
        onReset: _ctx.resetGoals
      }, null, 8, ["modelValue", "onReset"]),
      _createVNode(_component_SustainabilitySiteSetup)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_DemoFeed, {
        "prop-feed-items": _ctx.localGoals,
        type: "goal"
      }, null, 8, ["prop-feed-items"])
    ])
  ]))
}