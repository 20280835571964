<template>
  <li :class="['card-wrapper', { isHighlighted: isHighlighted }]">
    <p v-if="isHighlighted" class="highlight-text">
      {{ t('highlight') }}
    </p>
    <div class="plan-name">
      {{ t(snakeCase(planType)) }}
    </div>
    <div v-if="theme === 'priced'" class="plan-price">
      <span class="currency-symbol">{{ userCurrencySymbol }}</span>
      <span class="currency-value">{{ formatedPriceAmount }}</span>
      <span class="currency-code">{{ getUserCurrencyCode }}</span>
    </div>
    <div v-if="theme === 'unpriced'" class="plan-price unpriced">
      {{ t('contact_us') }}
    </div>
    <div v-if="theme === 'priced'" class="plan-billing">
      {{ planBilling }}
    </div>
    <ul class="plan-checks">
      <li
        v-for="(item, index) in planCheckItems"
        :key="index"
        :class="['plan-check-item', { 'hide-checkmark': hideCheckmark }]"
        v-html="item"
      />
    </ul>

    <div class="button-wrapper">
      <gs-button
        v-if="planType !== 'enterpriseBusiness'"
        full-width
        size="large"
        :disabled="buttonLoading || isButtonDisabled"
        @click="selectPlan()"
      >
        {{ buttonText }}
      </gs-button>
      <gs-button
        type="link"
        v-if="planType === 'enterpriseBusiness'"
        href="https://www.getgreenspark.com/book-a-demo"
        full-width
        size="large"
      >
        {{ buttonText }}
      </gs-button>
      <p v-if="showTrial" class="trial-text">
        {{ planType !== 'enterpriseBusiness' ? t('trial') : null }}
      </p>
    </div>
  </li>
</template>

<script lang="ts">
import type { CurrencyCode } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type {
  TechnicalAndBusinessSubscriptionType,
  NewPaidBusinessSubscriptionType,
} from '@/helpers/pricing'
import { formatCurrencyValueForDisplay, isProductUpgrade } from '@/helpers/pricing'
import type { SubscriptionItem } from '@/store/subscriptions'
import store from '@/store'
import { eventBus } from '@/main'
import { setUpRecurringChargeShopify } from '@api/index'
import type { TranslateResult } from 'vue-i18n'
import type { Integration } from '@/store/integrations'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { BusinessSubscriptionType } from '@/helpers/constants'

export type UpgradeCardTheme = 'priced' | 'unpriced'

export default defineComponent({
  name: 'UpgradeCard',
  emits: ['openChangePopup', 'setLoading'],
  mixins: [Utils],
  data() {
    return {
      buttonLoading: false,
      dialog: false,
    }
  },
  computed: {
    buttonText(): TranslateResult {
      if (this.planType === 'enterpriseBusiness') {
        return this.t('book_demo')
      } else if (!this.hasOwnPaidSubscriptionItem && !this.hasOwnFreeSubscriptionItem) {
        if (this.$route.path === '/upgrade-plan') {
          return this.t('select')
        } else {
          return this.t('start_free')
        }
      } else if (
        this.getPendingSubscription &&
        this.getPendingSubscription.product === this.planType
      )
        return this.t('pending')
      return this.t(isProductUpgrade(this.currentPlanType ?? '', this.planType))
    },
    showTrial(): boolean {
      return this.$route.path !== '/upgrade-plan' && this.getAccountTypeIsBusiness
    },
    isButtonDisabled(): boolean {
      // disable button when
      return (
        // current plan and card plan is the same, and current billing cycle and selected billing cycle is the same
        ((this.planType === this.currentPlanType &&
          this.isCurrentSubscriptionYearly === this.isSubscriptionYearly) ||
          // current billing cycle is yearly but the selected is monthly
          (this.isCurrentSubscriptionYearly && !this.isSubscriptionYearly) ||
          // card plan and pending plan is the same
          (this.getPendingSubscription && this.getPendingSubscription.product === this.planType)) &&
        this.hasOwnPaidSubscriptionItem
      )
    },
    hideCheckmark(): boolean {
      return (
        this.planType === 'growthBusinessYearly' ||
        this.planType === 'growthBusiness' ||
        this.planType === 'premiumBusinessYearly' ||
        this.planType === 'premiumBusiness' ||
        this.planType === 'enterpriseBusiness'
      )
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    userCurrency(): string {
      return this.$store.getters['getUserCurrency']
    },
    userCurrencyCode(): CurrencyCode {
      return this.$store.getters['getUserCurrencyCode']
    },
    formatedPriceAmount(): string {
      return formatCurrencyValueForDisplay(this.amount)
    },
    getPendingSubscription(): SubscriptionItem {
      return this.$store.getters['getPendingSubscription']
    },
    hasOwnPaidSubscriptionItem(): boolean {
      return this.$store.getters['hasOwnPaidSubscriptionItem']
    },
    hasOwnFreeSubscriptionItem(): boolean {
      return this.$store.getters['hasOwnFreeSubscriptionItem']
    },
    getAccountTypeIsBusiness(): boolean {
      return this.$store.getters['getAccountTypeIsBusiness']
    },
    getUserStores(): Integration[] {
      return this.$store.getters['getUserStores']
    },
    getLastShopifyIntegration(): Integration {
      return this.$store.getters['getLastShopifyIntegration']
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`UpgradeCard.${key}`)
    },
    async selectPlan() {
      // upgrade / downgrade
      if (this.getAccountTypeIsBusiness) {
        if (
          this.$route.path === '/upgrade-plan' &&
          this.currentPlanType &&
          (this.hasOwnPaidSubscriptionItem || this.hasOwnFreeSubscriptionItem)
        ) {
          this.$emit('openChangePopup', this.planType)
          // for signing up from shopify
        } else if (this.$route.name === 'DirectMarketplaceSignup') {
          eventBus.emit('planSelected', { planType: this.planType })
          // partner wants to subscribe
        } else if (
          this.$route.path === '/upgrade-plan' &&
          !this.hasOwnPaidSubscriptionItem &&
          !this.hasOwnFreeSubscriptionItem
        ) {
          if (this.hasPaymentDetails) {
            this.$emit('openChangePopup', this.planType)
          } else {
            await this.$router.push({
              path: '/checkout',
              query: {
                type: 'subscription',
                plan: this.planType,
                total: this.isSubscriptionYearly
                  ? (this.amount ?? 0 * 12).toString()
                  : this.amount?.toString(),
              },
            })
          }
        } else if (store.state.publicToken && this.getLastShopifyIntegration) {
          // setup recurring charge if the account already connected a store, but not created a subscription yet
          this.$emit('setLoading', true)
          const { data: account } = await setUpRecurringChargeShopify({
            integrationId: this.getLastShopifyIntegration.id,
            product: this.planType,
            url: `${window.location.origin}/success`,
          })
          store.commit('setAccount', account)
          store.commit('setPublicToken', '')
          this.$emit('setLoading', false)
          await this.$router.push('/')
        } else {
          // regular signup. total as 0 as we use a trial period hence we don't add a fee right away
          await this.$router.push({
            path: this.pathToNavigate,
            query: {
              type: 'subscription',
              plan: this.planType,
              total: '0',
              trial: this.$route.path === '/select-plan' ? 'true' : 'false',
            },
          })
        }
      } else {
        await this.$router.push({
          path: '/checkout',
          query: {
            type: 'subscription',
            plan: this.planType,
            total: this.amount?.toString(),
          },
        })
      }
    },
  },
  props: {
    planType: {
      type: String as PropType<BusinessSubscriptionType | NewPaidBusinessSubscriptionType>,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    planBilling: {
      type: String as PropType<string>,
    },
    planCheckItems: {
      type: Array as PropType<Array<string>>,
    },
    isHighlighted: {
      type: Boolean,
    },
    pathToNavigate: {
      type: String,
    },
    isSubscriptionYearly: {
      type: Boolean,
    },
    hasPaymentDetails: {
      type: Boolean,
    },
    theme: {
      type: String as PropType<UpgradeCardTheme>,
    },
    currentPlanType: {
      type: String as PropType<TechnicalAndBusinessSubscriptionType>,
    },
    isCurrentSubscriptionYearly: {
      type: Boolean,
    },
    isTrialPeriod: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 26px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: var(--box-shadow-light);
  flex: 1 1 0;
  position: relative;
  max-width: 500px;
  width: 100%;
  container-name: card-wrapper;
  container-type: inline-size;
}

.card-wrapper.isHighlighted {
  border: 2px solid var(--ui-blue);
  padding: 22px 24px;
}

.highlight-text {
  position: absolute;
  border-radius: 16px;
  background: var(--ui-blue);
  color: var(--ui-white);
  text-transform: uppercase;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
  top: -14px;
}

.plan-name {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: var(--font-color-primary);
  margin-bottom: 10px;
}

.plan-price {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  color: var(--ui-green);
  margin-bottom: 10px;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: flex-end;
  padding-top: 8px;
}

.plan-price.unpriced {
  margin-bottom: 25px;
  text-align: center;
  line-height: 50px;
  height: 100px;
  display: block;
}

@container card-wrapper (width < 180px) {
  .plan-price.unpriced.plan-price.unpriced {
    margin-bottom: 47px;
  }
}

.currency-value {
  line-height: 42px;
}

.currency-symbol,
.currency-code {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--font-color-primary);
}

.currency-symbol {
  justify-self: flex-end;
  margin-right: 8px;
}

.plan-billing {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--ui-green);
  margin-bottom: 36px;
}

.plan-checks {
  list-style-type: none;
  padding: 0 0 30px;
  width: 100%;
}

.plan-check-item {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--font-color-primary);
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}

.plan-check-item.hide-checkmark:first-child {
  padding-left: 0;
}

.plan-check-item.hide-checkmark:first-child::before {
  display: none;
}

.plan-check-item::before {
  content: '';
  background: url('../../assets/icons/check_green.svg');
  height: 24px;
  width: 24px;
  position: absolute;
  left: 0;
}

.button-wrapper {
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-text {
  color: var(--ui-white);
  font-weight: 600;
  font-size: 16px;
}

.button {
  margin-bottom: 16px;
}

.button:hover {
  opacity: 0.8;
}

.trial-text {
  color: var(--ui-green);
  margin-bottom: 0;
  margin-top: 12px;
  font-weight: 600;
  min-height: 24px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .card-wrapper {
    max-width: none;
    width: auto;
  }
}
</style>
