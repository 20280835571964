import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2551bd46"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "no-automations"
}
const _hoisted_2 = { class: "text-wrapper" }
const _hoisted_3 = { class: "no-automations-text" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "edit-automation-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_automations_table = _resolveComponent("automations-table")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_edit_automation = _resolveComponent("edit-automation")!
  const _component_full_width_popup = _resolveComponent("full-width-popup")!

  return (_openBlock(), _createElementBlock("section", null, [
    (_ctx.hasAnyAutomation)
      ? (_openBlock(), _createBlock(_component_automations_table, {
          key: 0,
          onAutomationSelected: _ctx.openEditPopup
        }, null, 8, ["onAutomationSelected"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t('no_automations_text')), 1)
          ]),
          _createVNode(_component_gs_button, {
            size: "large",
            width: "200",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'AddIntegrationsAndAutomations' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('add_automation')), 1)
            ]),
            _: 1
          })
        ])),
    _createVNode(_component_full_width_popup, {
      ref: "popup",
      "popup-title": _ctx.t('edit_popup_title')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('edit_automation_description')), 1),
          _createVNode(_component_edit_automation, { "selected-automation-id": _ctx.selectedAutomationId }, null, 8, ["selected-automation-id"])
        ])
      ]),
      _: 1
    }, 8, ["popup-title"])
  ]))
}