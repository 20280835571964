<template>
  <div class="automations-table">
    <v-data-table
      :items="getAutomationsDataForTableWithAccumulatedOffsets"
      :headers="headers"
      v-model:page="page"
      hide-default-footer
      :row-props="{ class: rowClass }"
      :items-per-page="itemsPerPage"
      height="423"
    >
      <template v-slot:[`item.image`]="{ item }">
        <div class="icon-wrapper">
          <v-icon class="trigger-icon">
            {{ getAutomationIcon(item.trigger) }}
          </v-icon>
        </div>
      </template>
      <template v-slot:[`item.schedule`]="{ item }">
        <v-tooltip :open-on-click="isTouch" color="black" location="top">
          <template v-slot:activator="{ props }">
            <p class="mb-0" v-bind="props">
              {{ item.scheduleLabel }}
              <v-icon
                v-if="item.status === 'active' && !item.schedule.endDate"
                class="ongoing-icon"
              >
                mdi-all-inclusive
              </v-icon>
            </p>
          </template>
          <p class="mb-0 tooltip-text">
            {{ item.scheduleLabelUTC }}
            <v-icon
              v-if="item.status === 'active' && !item.schedule.endDate"
              class="ongoing-icon tooltip"
            >
              mdi-all-inclusive
            </v-icon>
          </p>
        </v-tooltip>
      </template>
      <template v-slot:[`item.offsets`]="{ item }">
        <div
          v-if="item.offsets.length && item.trigger !== 'offsetByPercentageOrder'"
          class="impact-pill-wrapper"
        >
          <impact-pill
            v-for="(offset, index) in item.offsets"
            :key="index"
            :impact="{ type: offset.type, amount: offset.amount }"
            theme="filled"
          />
        </div>
        <span
          v-else-if="
            item.platform === 'custom' ||
            item.platform === 'zapier' ||
            item.platform === 'integrately'
          "
          class="mb-0 perproduct-pill"
        >
          {{ t('custom') }}
        </span>
        <div v-else class="impact-pill-wrapper">
          <impact-pill :impact="{ type: item.offsets[0].type, amount: 0 }" theme="filled" />
        </div>
      </template>
      <template v-slot:[`item.platform`]="{ item }">
        <v-tooltip :open-on-click="isTouch" color="black" location="top">
          <template v-slot:activator="{ props }">
            <p class="mb-0 platform-pill" v-bind="props">
              {{ getIntegrationPlatformName(item.platform) }}
            </p>
          </template>
          <span>{{ item.integrationName }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.trigger`]="{ item }">
        <p class="mb-0">
          {{ $t(`IntegrationMixin.${snakeCase(item.trigger)}`) }}
        </p>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <span @click.prevent="copyTextToClipboard(item.id)">
          {{ shortenText(item.id) }}
          <v-icon :size="18" class="copy ml-1">
            {{ isCopied ? 'mdi-check-circle' : 'mdi-content-copy' }}
          </v-icon>
        </span>
      </template>
      <template v-slot:[`item.statusLabel`]="{ item }">
        <span :class="['status-pill', item.status]">
          {{ item.statusLabel }}
        </span>
      </template>
      <template v-slot:[`item.context`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ props }">
            <button class="context-menu-button" v-bind="props">
              <v-icon>mdi-dots-horizontal</v-icon>
            </button>
          </template>
          <div class="drawer">
            <button class="drawer-button" @click="openEditAutomationDialog(item)">
              <v-icon class="drawer-icon"> mdi-pencil-outline </v-icon>{{ $t('CommonUi.edit') }}
            </button>
            <button
              v-if="showUpdate(item)"
              class="drawer-button"
              @click="
                updateAutomation({
                  automationId: item.id,
                  createAutomationRequestBody: { enabled: item.status !== 'active' },
                })
              "
            >
              <v-icon class="drawer-icon">
                {{
                  item.status === 'active' ? 'mdi-stop-circle-outline' : 'mdi-play-outline'
                }} </v-icon
              >{{ item.status === 'active' ? 'Deactivate' : 'Activate' }}
            </button>
            <button class="drawer-button" @click="openDeleteAutomationDialog(item)">
              <v-icon class="drawer-icon"> mdi-delete-outline </v-icon>{{ $t('CommonUi.delete') }}
            </button>
          </div>
        </v-menu>
      </template>
    </v-data-table>
    <div class="table-footer">
      <v-pagination :total-visible="3" v-model="page" :length="pageCount" />
    </div>
  </div>
</template>

<script lang="ts">
import type { AutomationOffset, AutomationTableData } from '@/store/integrations'
import ImpactPill from '@/components/ImpactWallet/ImpactPill.vue'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { DialogUpdatePayload } from '@/store/dialog'
import { Dialogs } from '@/store/dialog'
import type { UpdateAutomationRequestBody } from '@api/index'
import { includes } from '@/helpers/parsers'
import { CUSTOM_SOURCES, INTEGRATOR_SOURCES } from '@/helpers/interfaces'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AutomationsTable',
  emits: ['automationSelected'],
  components: { ImpactPill },
  mixins: [IntegrationsMixin, Utils],
  data() {
    return {
      headers: [
        {
          title: '',
          align: 'start',
          sortable: false,
          key: 'image',
          class: 'data-table-header',
        },
        {
          title: 'Name',
          align: 'start',
          sortable: true,
          key: 'name',
          class: 'data-table-header',
          width: '100px',
        },
        {
          title: 'Trigger',
          align: 'start',
          key: 'trigger',
          sortable: true,
          class: 'data-table-header',
        },
        {
          title: 'Platform',
          align: 'start',
          key: 'platform',
          sortable: true,
          class: 'data-table-header',
          width: '120px',
        },
        {
          title: 'Automation ID',
          align: 'start',
          key: 'id',
          sortable: true,
          class: 'data-table-header',
          width: '145px',
        },
        {
          title: 'Schedule',
          align: 'start',
          key: 'schedule',
          sortable: true,
          sort: (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
          class: 'data-table-header',
          width: '120px',
        },
        {
          title: 'Offsets',
          align: 'start',
          key: 'offsets',
          sortable: false,
          class: 'data-table-header',
        },
        {
          title: 'Status',
          align: 'start',
          key: 'statusLabel',
          sortable: true,
          class: 'data-table-header',
        },
        { title: '', align: 'start', key: 'context', sortable: false, class: 'data-table-header' },
      ],
      page: 1,
      itemsPerPage: 5,
    }
  },
  computed: {
    getAutomationsDataForTableWithAccumulatedOffsets(): AutomationTableData[] {
      return this.getAutomationsDataForTable.map((automation) => {
        const copyAutomation = { ...automation }
        copyAutomation.offsets = this.getAccumulatedOffsetsByType(copyAutomation.offsets)
        return copyAutomation
      })
    },
    getAutomationsDataForTable(): AutomationTableData[] {
      return this.$store.getters['getAutomationsDataForTable']
    },
    pageCount(): number {
      return Math.ceil(
        this.getAutomationsDataForTableWithAccumulatedOffsets.length / this.itemsPerPage,
      )
    },
  },
  methods: {
    rowClass() {
      return 'data-table-row'
    },
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`AutomationsTable.${key}`, params ?? {})
    },
    openDeleteAutomationDialog(automation: AutomationTableData) {
      this.openDialog({
        name: Dialogs.TEMPLATE.DELETE,
        texts: {
          title: this.t('delete_automation_dialog_title'),
          description: this.t('delete_automation_description', { automation: automation.name }),
          primaryButtonText: this.t('delete_automation'),
          secondaryButtonText: this.$t('CommonUi.cancel'),
          deleteCheckboxLabel: this.t('delete_automation_checkbox_label'),
        },
        options: {
          persistent: true,
        },
        actions: {
          onPrimaryButtonClick: async () => {
            if (includes([...CUSTOM_SOURCES, ...INTEGRATOR_SOURCES], automation.platform)) {
              await this.deleteTrigger({
                customIntegrationId: automation.sourceDetails?.sourceId || '',
                triggerId: automation.id,
              })
              await this.setCustomIntegrationList()
            } else {
              await this.deleteAutomation(automation.id)
            }
          },
        },
      })
    },
    openEditAutomationDialog(item: AutomationTableData) {
      this.$emit('automationSelected', item.id)
    },
    showUpdate(item: AutomationTableData) {
      return (
        !includes(CUSTOM_SOURCES, item.platform) && !includes(INTEGRATOR_SOURCES, item.platform)
      )
    },
    getAccumulatedOffsetsByType(offsets: AutomationOffset[]): AutomationOffset[] {
      return offsets.reduce((acc, { type, amount }) => {
        const item = acc.find((item) => item.type === type)
        const amountToAdd = amount || 0

        if (!item) {
          acc.push({ type, amount: amountToAdd })
        } else {
          item.amount = item.amount ? item.amount + amountToAdd : amountToAdd
        }
        return acc
      }, [] as AutomationOffset[])
    },
    openDialog(dialog: DialogUpdatePayload): Promise<void> {
      return this.$store.dispatch('openDialog', dialog)
    },
    deleteAutomation(automationId: string): Promise<void> {
      return this.$store.dispatch('deleteAutomation', automationId)
    },
    updateAutomation(payload: {
      automationId: string
      createAutomationRequestBody: UpdateAutomationRequestBody
    }): Promise<void> {
      return this.$store.dispatch('updateAutomation', payload)
    },
    deleteTrigger(payload: { customIntegrationId: string; triggerId: string }): Promise<void> {
      return this.$store.dispatch('deleteTrigger', payload)
    },
    setCustomIntegrationList(): Promise<void> {
      return this.$store.dispatch('setCustomIntegrationList')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.automations-table {
  border-radius: 10px;
  overflow: hidden;
}

.impact-pill-wrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: self-start;
}

.ongoing-icon {
  color: var(--font-color-primary);
  font-size: 16px;
}

.ongoing-icon.tooltip {
  color: var(--ui-white);
  margin-left: 5px;
}

.tooltip-text {
  display: flex;
  align-items: center;
}

.status-pill {
  border-radius: 8px;
  padding: 2px 10px;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid var(--gray-light);
  color: var(--gray-light);
  margin: 0;
  text-transform: capitalize;
}

.perproduct-pill {
  padding: 2px 5px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 8px;
  background-color: var(--ui-beige);
  color: var(--blue);
}

.status-pill.active,
.status-pill.scheduled {
  color: var(--ui-green);
  border: 1px solid var(--ui-green);
}

:deep(.data-table-row) {
  padding: 0 15px;
  height: 130px;
}

:deep(.data-table-row > td.text-start) {
  border-bottom: none !important;
}

:deep(.data-table-row:nth-child(odd)) {
  background-color: var(--ui-green-light-20);
}

:deep(.data-table-row:nth-child(odd):hover) {
  background-color: var(--ui-green-light-20) !important;
}

:deep(.data-table-row:nth-child(even):hover) {
  background-color: white !important;
}

:deep(.v-data-table-header tr) {
  background-color: var(--ui-beige);
}

:deep(.v-data-table-header th) {
  border-bottom: none !important;
}

.icon-wrapper {
  height: 50px;
  width: 50px;
  color: var(--font-color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../assets/backgrounds/icon-background.png') center/contain no-repeat;
}

.trigger-icon {
  color: var(--font-color-primary);
  font-size: 30px;
}

.drawer {
  background-color: #ffffff;
  padding: 5px;
}

.drawer-button {
  align-items: center;
  display: flex;
  border-radius: var(--border-radius-big);
  text-align: left;
  padding: 5px;
  width: 100px;
  font-size: 14px;
  line-height: 16px;
}

.drawer-icon {
  font-size: 18px;
  color: var(--font-color-primary);
  margin-right: 10px;
}

.drawer-button:hover {
  background-color: var(--ui-green-light-20);
}

.table-footer {
  background-color: var(--ui-beige);
  border-radius: 0 0 10px 10px;
  padding: 10px;
}

.copy {
  cursor: pointer;
}

:deep(.v-pagination) {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

:deep(.v-pagination li > button.v-pagination__item--active.v-pagination__item) {
  background-color: var(--ui-white) !important;
  border: 2px solid var(--ui-green);
  color: var(--ui-green) !important;
}

:deep(.v-pagination li > button) {
  height: 50px;
  width: 50px;
  border-radius: 16px;
  box-shadow: none;
  transition: none !important;
  color: var(--ui-green) !important;
}

:deep(.v-pagination li:first-child > button),
:deep(.v-pagination li:last-child > button) {
  background-color: white;
  color: var(--ui-green);
}

:deep(.v-pagination li > button:hove)r,
:deep(.v-pagination li > button:hover) {
  opacity: 0.8;
  color: var(--ui-green) !important;
  border: 2px solid var(--ui-green) !important;
}

:deep(.v-pagination li:first-child > button > i.v-icon),
:deep(.v-pagination li:last-child > button > i.v-icon) {
  font-size: 30px !important;
  color: var(--ui-green) !important;
}

:deep(.v-pagination li:first-child) {
  margin-right: auto;
}

:deep(.v-pagination li:last-child) {
  margin-left: auto;
}

.context-menu-button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.context-menu-button:hover {
  background-color: var(--gray-light);
}

.platform-pill {
  cursor: pointer;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
