import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, mergeProps as _mergeProps, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, createSlots as _createSlots, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47c6204b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "data-table-wrapper elevation-1" }
const _hoisted_2 = {
  key: 0,
  class: "not-found"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "impact-pill-wrapper" }
const _hoisted_6 = { class: "tooltip-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_impact_pill = _resolveComponent("impact-pill")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_LoaderBlock = _resolveComponent("LoaderBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.search,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchProducts()))
        ],
        "append-inner-icon": "mdi-magnify",
        placeholder: "Search",
        "hide-details": "",
        color: "green",
        class: "product-search-field"
      }, null, 8, ["modelValue"]),
      (!!_ctx.search && !_ctx.items.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", {
              class: "not-found-text",
              innerHTML: _ctx.t('not_found', { search: _ctx.search })
            }, null, 8, _hoisted_3)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showTable)
        ? (_openBlock(), _createBlock(_component_v_data_table_server, {
            key: _ctx.tableRender,
            modelValue: _ctx.selectedItems,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItems) = $event)),
            headers: _ctx.headers,
            items: _ctx.items,
            "checkbox-color": "#3B755F",
            "show-select": "",
            class: "elevation-0 data-table",
            "item-value": "productId",
            width: "100px",
            height: "450",
            "items-per-page": _ctx.itemsPerPage,
            "onUpdate:itemsPerPage": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
            "footer-props": { itemsPerPageOptions: [5, 10, 20, 50] },
            color: "#3B755F",
            "items-length": _ctx.itemsCount,
            "onUpdate:options": _ctx.setItems,
            onToggleSelectAll: _ctx.setAllSelected
          }, _createSlots({
            [`header.data-table-select`]: _withCtx(() => []),
            [`item.image`]: _withCtx(({ item }) => [
              _createElementVNode("img", {
                class: "product-image",
                src: item.image
              }, null, 8, _hoisted_4)
            ]),
            _: 2
          }, [
            (!_ctx.isOnboarding)
              ? {
                  name: `item.offsetType`,
                  fn: _withCtx(({ item }) => [
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getProductsFromOtherAutomationsWithOffsets(
                item.productId,
              ), ({ automationName, offsets }, i) => {
                        return (_openBlock(), _createElementBlock("div", { key: i }, [
                          _createVNode(_component_v_tooltip, {
                            "open-on-click": _ctx.isTouch,
                            color: "black",
                            "max-width": "480px",
                            location: "left"
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createElementVNode("div", _mergeProps({
                                class: "impact-pill-row",
                                ref_for: true
                              }, props), [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(offsets, (offset, index) => {
                                  return (_openBlock(), _createBlock(_component_impact_pill, {
                                    key: index,
                                    class: "ma-0",
                                    impact: { type: offset.type, amount: offset.amount }
                                  }, null, 8, ["impact"]))
                                }), 128))
                              ], 16)
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, _toDisplayString(automationName), 1)
                            ]),
                            _: 2
                          }, 1032, ["open-on-click"])
                        ]))
                      }), 128))
                    ])
                  ]),
                  key: "0"
                }
              : undefined,
            (!_ctx.isOnboarding)
              ? {
                  name: `item.active`,
                  fn: _withCtx(({ item }) => [
                    _createVNode(_component_v_chip, {
                      color: 
              _ctx.getProductsFromOtherAutomationsWithOffsets(item.productId).length
                ? '#3B755F'
                : '#E57066'
            
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getProductsFromOtherAutomationsWithOffsets(item.productId).length
                ? 'Active'
                : 'Inactive'), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"])
                  ]),
                  key: "1"
                }
              : undefined
          ]), 1032, ["modelValue", "headers", "items", "items-per-page", "items-length", "onUpdate:options", "onToggleSelectAll"]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, !_ctx.showLoader]
    ]),
    _withDirectives(_createVNode(_component_LoaderBlock, null, null, 512), [
      [_vShow, _ctx.showLoader]
    ])
  ]))
}