<template>
  <div :class="['impact-pill', theme, impact?.type]">
    <svg class="impact-icon">
      <use :href="'#' + impact?.type + '-icon'" />
    </svg>
    <p v-if="impact?.amount" class="impact-amount mb-0">
      {{ formatTotalAmounts(impact.amount) }}
    </p>
  </div>
</template>

<script lang="ts">
import type { Impact } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImpactPill',
  mixins: [Utils],
  props: {
    impact: {
      type: Object as PropType<Impact>,
    },
    theme: {
      default: 'outlined',
      type: String as PropType<'outlined' | 'filled'>,
    },
  },
})
</script>

<style lang="scss" scoped>
.impact-pill {
  align-items: center;
  display: flex;
  flex-shrink: 0;
}

.impact-pill.outlined {
  color: var(--ui-green);
  border-radius: 20px;
  border: 2px solid var(--ui-green);
  padding: 5px 10px;
  gap: 5px;
}

.impact-pill.filled {
  color: var(--ui-beige);
  border-radius: 10px;
  padding: 5px;
  gap: 4px;
}

.impact-pill.outlined .impact-icon {
  height: 20px;
  width: 20px;
  font-size: 20px;
  flex-shrink: 0;
}

.impact-pill.filled .impact-icon {
  height: 16px;
  width: 16px;
  font-size: 16px;
}

.impact-pill.outlined .impact-amount {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  flex-shrink: 0;
}

.impact-pill.filled .impact-amount {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  flex-shrink: 0;
}

.impact-pill.filled.trees {
  background-color: var(--ui-green);
}

.impact-pill.filled.kelp {
  background-color: var(--ui-torquoise);
}

.impact-pill.filled.carbon {
  background-color: var(--ui-beige);
  color: var(--ui-blue);
}

.impact-pill.filled.plastic {
  background-color: var(--ui-blue);
}
</style>
