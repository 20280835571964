import { defineComponent } from 'vue'
import * as constants from '@/helpers/constants'
import { Decimal } from 'decimal.js'
import type { TranslateResult } from 'vue-i18n'

export const RulesMixin = defineComponent({
  data() {
    return {
      rules: {
        maxTwoDecimal: (value) => {
          // when an input is cleared, the value is null
          // rendering the input initially empty the value is undefined
          // should be modulo 0.01 to allow for 2 decimal places
          return value === null || value === undefined
            ? true
            : Number(value) >= 0 && new Decimal(Number(value)).modulo(0.01).toNumber() === 0
        },
        positiveInteger: (value) => {
          // when an input is cleared, the value is null
          // rendering the input initially empty the value is undefined
          return value === null || value === undefined
            ? true
            : Number.isSafeInteger(Number(value)) && Number(value) >= 0
        },
        maxlength:
          (maxLength = 240) =>
          (v) =>
            (v || '').length <= maxLength || this.$t(`RulesMixin.max_length`, { limit: maxLength }),
        minlength: (minLength) => (v) =>
          !((v || '').length > 0 && (v || '').length < minLength) ||
          this.$t(`RulesMixin.min_length`, { limit: minLength }),
        maxValue:
          (maxValue = Number.MAX_SAFE_INTEGER) =>
          (v) =>
            Number(v) <= maxValue || this.$t(`RulesMixin.max_value`, { limit: maxValue }),
        url: (value) => {
          const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
              '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
              '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
              '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
              '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
              '(\\#[-a-z\\d_]*)?$',
            'i',
          ) // fragment locator
          if (value) {
            return pattern.test('https://' + value) || this.$t(`RulesMixin.url`)
          }
          return !value || this.$t(`RulesMixin.required`)
        },
        domain: (value) => {
          const pattern = new RegExp(/^(?!https:\/\/)(?!www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/, 'g') // domain name
          if (value) {
            return pattern.test(value)
          }
          return !value
        },
        required: (value) => !!value || this.$t(`RulesMixin.required`),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t(`RulesMixin.email`)
        },
        emailName: (value) => {
          const pattern = new RegExp(
            /^(?:(?:[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(?:\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*))$/,
            'g',
          )
          if (value) {
            return pattern.test(value)
          }
          return !value
        },
        password: (value) => {
          const pattern = /^(?=.{6,}$)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)).*$/
          return pattern.test(value)
        },
        unique:
          <Type>(collection: Array<Type>) =>
          (value: Type) => {
            const index = collection.indexOf(value)
            return !collection.some((item, i) => item === value && i !== index)
          },
      },
    } as {
      rules: {
        positiveInteger: (value: number) => boolean
        maxlength: (
          max?: number,
        ) => <Type>(value: string | Array<Type>) => boolean | TranslateResult
        minlength: (min: number) => <Type>(value: string | Array<Type>) => boolean | TranslateResult
        maxValue: (max: number) => (value: number | string) => boolean | TranslateResult
        url: (value: string) => boolean
        domain: (value: string) => boolean
        required: (value: number | string) => boolean | TranslateResult
        email: (value: string) => boolean | TranslateResult
        emailName: (value: string) => boolean
        password: (value: string) => boolean
        unique: <Type>(value: Array<Type>) => (value: Type) => boolean
      }
    }
  },
  computed: {
    IMPACT_LOWER_PRICE_LIMIT(): number {
      return constants.IMPACT_LOWER_PRICE_LIMIT
    },
  },
})
