<template>
  <div class="warning-dialog">
    <v-icon class="warning-icon"> mdi-alert-outline </v-icon>
    <h2 class="warning-text">
      <span>
        {{ t('warning_text', { apiKeyName }) }}
      </span>
    </h2>
    <div class="action-wrapper">
      <v-btn class="accept button" height="54" width="150" @click="generateApiKey">
        {{ t('accept') }}
      </v-btn>
      <v-btn class="cancel button" height="54" @click="closePopup">
        {{ t('cancel') }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import type { ApiKeyType } from '@/components/account/ApiKey.vue'
import { getApiKeyNameByValue } from '@/components/account/ApiKey.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WarningDialog',
  emits: ['generateApiKey', 'closePopup'],
  computed: {
    apiKeyName(): string {
      return getApiKeyNameByValue(this.apiKeyType)
    },
  },
  methods: {
    t(key: string, params?: { [key: string]: string | ApiKeyType }) {
      return params
        ? this.$t(`WarningDialog.${key}`, params ?? {})
        : this.$t(`WarningDialog.${key}`)
    },
    generateApiKey() {
      this.$emit('generateApiKey')
    },
    closePopup() {
      this.$emit('closePopup')
    },
  },
  props: {
    apiKeyType: {
      required: true,
      type: Object as PropType<ApiKeyType>,
    },
  },
})
</script>

<style lang="scss" scoped>
.warning-dialog {
  background-color: white;
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.warning-icon {
  color: #f5c26f;
  font-size: 60px;
  flex-shrink: 0;
  margin-bottom: 20px;
}

.warning-text {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}

.action-wrapper {
  align-items: center;
  display: flex;
  gap: 20px;
}

.accept.button {
  background-color: #3b755f;
  color: white;
}
</style>
