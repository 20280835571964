import type { SustainabilityGoal } from '@/helpers/interfaces'
import type { CustomIntegrationTrigger } from '@api/index'
import {
  deleteSustainabilityGoal,
  fetchAccount,
  fetchDefaults,
  fetchDomains,
  getEmailTemplates,
  getImpactActions,
  getSustainabilityGoals,
  saveSustainabilityGoal,
  updateSustainabilityGoal,
} from '@api/index'
import { formatLineBreaksToWhiteSpace } from '@/helpers/parsers'
import type { ActionContext } from 'vuex'
import type { StateInterface } from '@/store/index'
import { BILLING_AUTOMATION_TRIGGERS } from './integrations'

export const TEMPLATE_TRIGGER = [
  'offsetPerOrder',
  'offsetPerProduct',
  'offsetBySpend',
  'offsetByStoreRevenue',
  'offsetByPercentageOrder',
  'offsetPerSubscription',
  'offsetPerReview',
  'perCompletedForm',
  'perSelectedAnswer',
  ...BILLING_AUTOMATION_TRIGGERS,
]

export type CustomNotificationTemplateTriggerType = CustomIntegrationTrigger['name']
export type TemplateTrigger =
  | (typeof TEMPLATE_TRIGGER)[number]
  | CustomNotificationTemplateTriggerType

export const TEMPLATE_TYPE = ['invitation', 'update', 'nudge']
export type TemplateType = (typeof TEMPLATE_TYPE)[number]

export interface Domain {
  id: string
  domain: string
  valid: boolean
  dns: Dns
}

export interface DnsRecord {
  valid: boolean
  type: string
  host: string
  data: string
}

export interface Dns {
  mailCname: DnsRecord
  dkim1: DnsRecord
  dkim2: DnsRecord
}

export interface EmailTemplateContent {
  headerImage: string
  body: string
  isFooterHidden: boolean
  isTotalImpactHidden: boolean
}
interface EmailTemplateType {
  name: string
  template: TemplateType
  trigger: TemplateTrigger[] | null
  schedule: number[] | null
  isScheduleCustom: boolean
  isTriggerCustom: boolean
}
export interface EmailTemplateSetting {
  from: { name: string; domain: string }
  alias: string
  subject: string
  preheader: string
  schedule: { startDate: string; endDate?: string }
}
export interface EmailTemplate {
  id: string
  isActive: boolean
  type: EmailTemplateType
  settings: EmailTemplateSetting
  content: EmailTemplateContent
}
export interface EmailTemplateSettingsWithStatus extends EmailTemplateSetting {
  isFromCustom: boolean
  isAliasCustom: boolean
  isSubjectCustom: boolean
  isPreheaderCustom: boolean
}

export interface EmailTemplateContentWithStatus extends EmailTemplateContent {
  isHeaderImageCustom: boolean
  isBodyCustom: boolean
  isFooterHiddenCustom: boolean
  isTotalImpactHiddenCustom: boolean
}

export interface EmailTemplateWithStatus extends EmailTemplate {
  isCustom: boolean
  settings: EmailTemplateSettingsWithStatus
  content: EmailTemplateContentWithStatus
}

interface CustomerEngagementState {
  sustainabilityGoals: SustainabilityGoal[]
  sustainabilitySite: string
  customerEngagementPortal: {
    activationDate: string
    deactivationDate: string
    active: boolean
  }
  hasImpactActions: boolean
  savedDomainList: Domain[]
  emailTemplateList: EmailTemplateWithStatus[]
  defaultTemplates: EmailTemplateWithStatus[]
  defaultVariables: Record<string, string>
}

export const customerEngagementModule = {
  state: () =>
    ({
      sustainabilityGoals: [],
      sustainabilitySite: '',
      customerEngagementPortal: {
        activationDate: '',
        deactivationDate: '',
        active: false,
      },
      hasImpactActions: false,
      savedDomainList: [],
      emailTemplateList: [],
      defaultTemplates: [],
      defaultVariables: {},
    } as CustomerEngagementState),
  mutations: {
    saveSustainabilityGoal(s, sustainabilityGoal: SustainabilityGoal) {
      s.sustainabilityGoals.push(sustainabilityGoal)
    },
    setSustainabilityGoals(s, data: SustainabilityGoal[]) {
      s.sustainabilityGoals = data
    },
    deleteSustainabilityGoal(s, targetId: string) {
      const foundIndex = s.sustainabilityGoals.findIndex((goal) => goal.targetId === targetId)
      s.sustainabilityGoals.splice(foundIndex, 1)
    },
    setSustainabilitySite(s, url: string) {
      s.sustainabilitySite = url
    },
    setImpactActionsState(s, hasImpactActions: boolean) {
      s.hasImpactActions = hasImpactActions
    },
    setDomainList(s, domainList: Domain[]) {
      s.savedDomainList = domainList
    },
    setEmailTemplateList(s, emailTemplateList: EmailTemplateWithStatus[]) {
      s.emailTemplateList = emailTemplateList
    },
    setDefaultTemplates(s, defaultTemplates: EmailTemplateWithStatus[]) {
      s.defaultTemplates = defaultTemplates
    },
    setDefaultVariables(s, defaultVariables) {
      s.defaultVariables = defaultVariables
    },
    setCustomerEngagementPortal(s, customerEngagementPortal) {
      s.customerEngagementPortal = customerEngagementPortal
    },
  },
  actions: {
    async setCustomerEngagementPortal({
      commit,
    }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await fetchAccount()
      commit('setCustomerEngagementPortal', data.customerEngagementPortal)
    },

    async setSustainabilityGoals({
      commit,
    }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await getSustainabilityGoals()
      const formattedData: SustainabilityGoal[] = data.map(({ title, description, _id }) => {
        return { title, description, targetId: _id }
      })
      commit('setSustainabilityGoals', formattedData)
    },
    async setSustainabilitySite({
      commit,
    }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await fetchAccount()
      commit('setSustainabilitySite', data.sustainabilitySite)
    },
    async setImpactActionsState({
      commit,
    }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await getImpactActions()
      commit('setImpactActionsState', !!data.length)
    },
    async setDomainList({ commit }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await fetchDomains()
      commit('setDomainList', data)
    },
    async setEmailTemplateList({ commit }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await getEmailTemplates()
      const emailTemplateListWithFormattedBody = data.map((emailTemplate) => {
        return {
          ...emailTemplate,
          content: {
            ...emailTemplate.content,
            body: formatLineBreaksToWhiteSpace(emailTemplate.content.body),
          },
        }
      })
      commit('setEmailTemplateList', emailTemplateListWithFormattedBody)
    },
    async setDefaultValues({ commit }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await fetchDefaults()
      const defaultsWithStatus: EmailTemplateWithStatus[] = []
      data.templates.forEach((template) => {
        defaultsWithStatus.push({
          id: '',
          isActive: true,
          isCustom: false,
          settings: {
            ...template.settings,
            schedule: {
              endDate: template.settings.schedule.endDate,
              startDate: '',
            },
            isFromCustom: false,
            isAliasCustom: false,
            isSubjectCustom: false,
            isPreheaderCustom: false,
          },
          content: {
            ...template.content,
            isHeaderImageCustom: false,
            isBodyCustom: false,
            isFooterHiddenCustom: false,
            isTotalImpactHiddenCustom: false,
            isFooterHidden: false,
            isTotalImpactHidden: false,
          },
          type: {
            ...template.type,
            name: '',
          },
        })
      })
      commit('setDefaultTemplates', defaultsWithStatus)
      commit('setDefaultVariables', data.variables)
    },
    async updateSustainabilityGoal(
      { commit, state }: ActionContext<CustomerEngagementState, StateInterface>,
      sustainabilityGoal: SustainabilityGoal,
    ) {
      const {
        data: { title, description, _id },
      } = await updateSustainabilityGoal(sustainabilityGoal)
      const updatedArray = state.sustainabilityGoals.map((goal) => {
        if (goal.targetId === _id) {
          return { title, description, targetId: _id }
        }
        return goal
      })
      commit('setSustainabilityGoals', updatedArray)
    },
    async saveSustainabilityGoal(
      { commit }: ActionContext<CustomerEngagementState, StateInterface>,
      sustainabilityGoal: SustainabilityGoal,
    ) {
      const {
        data: { title, description, _id },
      } = await saveSustainabilityGoal(sustainabilityGoal)
      commit('saveSustainabilityGoal', { title, description, targetId: _id })
    },
    async deleteSustainabilityGoal(
      { commit }: ActionContext<CustomerEngagementState, StateInterface>,
      targetId: string,
    ) {
      const {
        data: { _id },
      } = await deleteSustainabilityGoal(targetId)
      commit('deleteSustainabilityGoal', _id)
    },
  },
  getters: {
    getCustomDomainAmount: (s) => s.savedDomainList.filter((domain) => domain.id).length,
    getActiveEmailTemplateAmount: (s) => {
      return s.emailTemplateList.filter((emailTemplate) => emailTemplate.isActive).length
    },
    getCustomActiveEmailTemplateAmount: (s) => {
      return s.emailTemplateList.filter(
        (emailTemplate) => emailTemplate.isActive && emailTemplate.isCustom,
      ).length
    },
    getCustomEmailTemplateAmount: (s) => {
      return s.emailTemplateList.filter((emailTemplate) => emailTemplate.isCustom).length
    },
    getIfB2B2C: (state, getters): boolean => {
      return state.hasImpactActions && !getters.getAccountTypeIsBusiness
    },
    getCustomerEngagementPortal: (s) => s.customerEngagementPortal,
    getCustomerEngagementPortalActive: (s): boolean => s.customerEngagementPortal.active,
    getSustainabilityGoals: (s): SustainabilityGoal[] => s.sustainabilityGoals,
    getSustainabilitySite: (s): string => s.sustainabilitySite,
    getHasImpactActions: (s): boolean => s.hasImpactActions,
    getSavedDomainList: (s): Domain[] => s.savedDomainList,
    getCustomerEngagementDefaultVariables: (s): Record<string, string> => s.defaultVariables,
    getCustomerEngagementEmailTemplateList: (s): EmailTemplateWithStatus[] => s.emailTemplateList,
    getCustomerEngagementDefaultTemplates: (s): EmailTemplateWithStatus[] => s.defaultTemplates,
  },
}
