<template>
  <div :class="['setting-card', { 'is-active': isActive }]">
    <div class="setting-card-text-wrapper">
      <div class="setting-card-title-wrapper">
        <div class="checkmark">
          <v-icon class="checkmark-icon"> mdi-check </v-icon>
        </div>
        <p class="card-title">
          <slot name="title" />
        </p>
        <p v-if="activeAmount" class="counter-text">
          {{ tc('active', activeAmount) }}
        </p>
      </div>
      <p class="card-description">
        <slot name="desc" />
      </p>
    </div>
    <slot name="button">
      <gs-button :type="isActive ? 'secondary' : 'primary'" @click="$emit('button-click')">
        {{ buttonText }}
      </gs-button>
    </slot>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SettingCard',
  emits: ['button-click'],
  components: {},
  methods: {
    tc(key: string, amount?: number) {
      return this.$t(`SettingCard.${key}`, amount ?? 0)
    },
  },
  props: {
    isActive: {
      type: Boolean,
    },
    activeAmount: {
      type: Number,
    },
    buttonText: {
      type: String as PropType<string>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.setting-card {
  border-radius: var(--border-radius-small);
  border: 2px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px;
}

.setting-card.is-active {
  border: 2px solid var(--ui-green);
}

.setting-card-text-wrapper {
  max-width: 50ch;
}

.setting-card-title-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.checkmark {
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 20px;
  margin-right: 5px;
  position: relative;
  width: 20px;
}

.setting-card.is-active .checkmark {
  background-color: var(--ui-green);
  border: none;
}

.checkmark-icon {
  color: transparent;
  font-size: 16px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 50%;
  right: 50%;
  transform: translate(-50%, calc(-50% + 1px));
}

.setting-card.is-active .checkmark-icon {
  color: white;
}

.card-title {
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

.counter-text {
  background-color: var(--ui-green);
  border-radius: var(--border-radius-big);
  color: var(--ui-beige);
  margin: 0 0 0 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 0 5px;
  white-space: nowrap;
}

.card-description {
  color: var(--gray);
  font-weight: 400;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .setting-card {
    flex-direction: row;
  }
}
</style>
