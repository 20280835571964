<template>
  <section class="onboarding-panel">
    <div class="onboarding-panel__text-wrapper">
      <h1 class="onboarding-panel__title">
        {{ title }}
      </h1>
      <slot name="navigationBack" />
      <p class="onboarding-panel__description" v-html="description" />
    </div>

    <button v-if="isSkippable" class="skip-button" role="button" @click.prevent="onSkip">
      {{ skipButtonText }}
    </button>
    <div class="onboarding-panel__content" :style="{ backgroundColor }">
      <slot />
    </div>
  </section>
</template>

<script lang="ts">
import VueI18n from 'vue-i18n'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

import TranslateResult = VueI18n.TranslateResult

export default defineComponent({
  name: 'OnboardingPanel',
  props: {
    title: {
      type: String as PropType<string | TranslateResult>,
    },
    description: {
      type: String as PropType<string | TranslateResult>,
    },
    isSkippable: {
      type: Boolean,
    },
    backgroundColor: {
      default: 'white',
      type: String as PropType<'transparent' | 'white'>,
    },
    onSkip: {
      type: Function as PropType<() => void>,
    },
    skipButtonText: {
      type: String as PropType<string | TranslateResult>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.onboarding-panel {
  background-color: white;
  padding: 10px;
  width: 100%;
  position: relative;
  max-width: 1192px;
}

.skip-button {
  position: absolute;
  right: 10px;
  top: 16px;
  /* top padding: 10px + title line height/2: 17px - button line height/2: 11px */
  cursor: pointer;
  color: var(--ui-green);
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

.onboarding-panel__title {
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 10px;
  padding-right: 142px;
}

.onboarding-panel__description {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
}

.onboarding-panel__content {
  border-radius: var(--border-radius-big);
  background-color: #ffffff;
}

.onboarding-panel__description:deep(b) {
  border-radius: 8px;
  background-color: var(--ui-beige);
  padding: 0 5px 0;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .onboarding-panel {
    border-radius: var(--border-radius-big);
    box-shadow: var(--box-shadow-wide);
    padding: 20px;
  }

  .skip-button {
    right: 20px;
    top: 26px;
    /* top padding: 20px + title line height/2: 17px - button line height/2: 11px */
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .onboarding-panel {
    padding: 50px;
  }

  .onboarding-panel__title {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 10px;
  }

  .onboarding-panel__description {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 34px;
    max-width: 37ch;
  }

  .skip-button {
    right: 20px;
    top: 20px;
  }
}
</style>
