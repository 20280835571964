<template>
  <div class="partner-grid-outer">
    <!-- if there are partners -->
    <div v-if="!empty">
      <MainTable
        :menu-items="menuItems"
        :rows="rowItems"
        :column-width="columnWidth"
        :footer-items="footerItems"
        :hide-partner-image="false"
        :hide-search="false"
        :no-results="noSearchResults"
        :table-loading="tableLoading"
        :active-page="activePage"
        @quickMenuClick="quickMenuClick"
        @emitSearch="search"
        @emitSort="sort"
        @footerClick="footerClick"
        @arrowClick="arrowClick"
      />

      <!-- Quick menu items -->
      <v-dialog v-model="quickMenuDialog" width="510" persistent :fullscreen="isMobile">
        <!-- Reward -->
        <Reward
          v-if="activeQuickMenuItem === 'reward'"
          :relations-id="relationsIdQuickMenuItem"
          :connectee-id-quick-menu-item="connecteeIdQuickMenuItem"
          @dialogClick="dialogClick()"
          @showErrorAlert="$emit('showErrorAlert')"
          @showSuccessAlert="$emit('showSuccessAlert')"
          @getFreshDataClick="$emit('getFreshDataClick')"
        />

        <!-- Edit -->
        <Edit
          v-if="activeQuickMenuItem === 'edit' || activeQuickMenuItem === 'editContact'"
          :edit-items="editItems"
          :is-edit="activeQuickMenuItem === 'edit'"
          :is-contact-edit="activeQuickMenuItem === 'editContact'"
          :relations-id="relationsIdQuickMenuItem"
          @dialogClick="dialogClick()"
          @showErrorAlert="$emit('showErrorAlert')"
          @showSuccessAlert="$emit('showSuccessAlert')"
          @getFreshDataClick="$emit('getFreshDataClick')"
        />

        <!-- History -->
        <History
          v-if="activeQuickMenuItem === 'history'"
          :window-width="windowWidth"
          :relations-id="relationsIdQuickMenuItem"
          :partner-name="partnerName"
          @dialogClick="dialogClick()"
        />

        <!-- Stop Partnership -->
        <StopPartnership
          v-if="activeQuickMenuItem === 'stop'"
          :relations-id="relationsIdQuickMenuItem"
          @getFreshDataClick="$emit('getFreshDataClick')"
          @dialogClick="dialogClick()"
        />
      </v-dialog>
    </div>

    <!-- Loading spinner -->
    <LoadingOpaque v-if="loading" />

    <!-- No partners -->
    <div v-if="empty" class="empty">
      <Empty
        :text="t('empty')"
        :text1="t('empty1')"
        :button-text="t('invite')"
        @buttonClick="$emit('emptyButtonClick')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MainTable from '@/components/tools/Table/MainTable.vue'
import Reward from '@/components/Network/Reward.vue'
import Edit from '@/components/Network/Edit.vue'
import History from '@/components/Network/History.vue'
import StopPartnership from '@/components/Network/StopPartnership.vue'
import LoadingOpaque from '@/components/tools/LoadingOpaque.vue'
import Empty from '@/components/Network/Empty.vue'
import { listPartners, sendReminder } from '@api/index'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

import type { PartnerData } from '@api/index'
import type { TranslateResult } from 'vue-i18n'

interface RowItem {
  id: string
  relationsId: string
  image: string
  name: string
  firstName: string
  lastName: string
  labels: { text?: string; type: string }[] // object with either type: text, impact
  status: { text: string; customWidth: string }
  quickMenuItems: { label: string; value: string }[]
}

export default defineComponent({
  name: 'PartnerGrid',
  emits: [
    'showErrorAlert',
    'showSuccessAlert',
    'getFreshDataClick',
    'emptyButtonClick',
    'emptyClick',
    'hideButtonClick',
    'dialogClick',
    'setCardDialogClick',
  ],
  mixins: [Utils],
  components: {
    MainTable,
    Reward,
    Edit,
    History,
    StopPartnership,
    LoadingOpaque,
    Empty,
  },
  data() {
    const partnersData: PartnerData[] = []
    const editItems: PartnerData[] = []
    const footerItems: { label: string | number; value: string | number; active: boolean }[] = []
    const rowItems: RowItem[] = []
    const menuItems: {
      label: TranslateResult
      value: string
      sortDirection: 'desc' | 'asc'
      sortedBy?: boolean
      sortable: boolean
      active?: boolean
      customWidth?: number
    }[] = []

    return {
      menuItems,
      rowItems,
      rowItemsAmount: 5,
      footerItems,
      tableWidth: 0,
      columnWidth: 0,
      quickMenuDialog: false,
      activeQuickMenuItem: '',
      relationsIdQuickMenuItem: '',
      connecteeIdQuickMenuItem: '',
      partnerName: '',
      editItems,
      partnersData,
      loading: false,
      empty: false,
      searchString: '',
      noSearchResults: false,
      sortDirection: 'desc',
      sortBy: 'added',
      skip: 0,
      tableLoading: false,
      partnerCount: 0,
      activePage: 0,
    }
  },
  computed: {
    accountId(): string {
      return this.$store.getters['getAccountId']
    },
    hasSubscription(): boolean {
      return this.$store.getters['hasPaidSubscriptionItem']
    },
  },
  async created() {
    this.loading = true
    await this.getPartners()
    if (this.rowItems.length < 1) {
      this.$emit('hideButtonClick', true)
      this.empty = true
      this.$emit('emptyClick', 'partner')
    } else {
      this.$emit('hideButtonClick', false)
      this.empty = false
      this.$emit('emptyClick', '')
    }
    this.setMenuItems()
    this.reportTableSize()
    this.setFooterItems()
    this.loading = false
  },
  methods: {
    t(key: string) {
      return this.$t(`PartnerGrid.${key}`)
    },
    async getPartners() {
      this.rowItems = []
      this.partnersData = []
      const {
        data: { data: partners, count },
      } = await listPartners(this.sortBy, this.sortDirection, this.skip, 10, this.searchString)
      this.partnersData = partners
      if (this.partnersData.length > 0) this.$emit('hideButtonClick', false)
      this.partnerCount = count

      this.rowItems = partners.map((item) => {
        const payload: RowItem = {
          id: '',
          relationsId: '',
          image: '',
          name: '',
          firstName: '',
          lastName: '',
          labels: [], // object with either type: text, impact
          status: { text: '', customWidth: '' },
          quickMenuItems: [],
        }

        // check if partner is connector or connectee
        let userIsConnectee = false
        if (item.connectee.id === this.accountId) {
          userIsConnectee = true
        }
        // set the id of the user
        payload.id = userIsConnectee ? item.connector.id : item.connectee.id

        // set the id of the relationship
        payload.relationsId = item._id

        // set the image URL
        payload.image = userIsConnectee ? item.connector.imageURL : item.connectee.imageURL

        // set the name
        payload.name = userIsConnectee
          ? item.connector.displayName + ' (funder)'
          : item.connectee.displayName
        // this.rowItemsAmount++

        // set labels
        // added date
        const date = new Date(item.inviteSentAt)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        const addedDate = `${day}.${month}.${year}`
        payload.labels.push({ text: addedDate, type: 'text' })
        // this.rowItemsAmount++

        // grab the impact settings that correspond to this user
        // object to combine impact labels before adding to grid
        const impactObject: {
          type: string
          labels: { text: string; type: string }[]
          customWidth: number
        } = {
          type: 'impact',
          labels: [],
          customWidth: 143,
        }
        let fundedDateSet = false
        if (item.impactSettings.length > 0) {
          const impactSettings = item.impactSettings.filter(
            (impactSettings) => impactSettings.userId === item.connector.id,
          )

          for (let i = 0; i < impactSettings.length; i++) {
            // set funding end date if it exists
            if (
              impactSettings[i].fundingEndsDate !== '' &&
              impactSettings[i].fundingEndsDate !== null &&
              !fundedDateSet &&
              !item.stoppedAt
            ) {
              const date1 = new Date(impactSettings[i].fundingEndsDate)
              const day1 = date1.getDate()
              const month1 = date1.getMonth() + 1
              const year1 = date1.getFullYear()
              const fundingEnd = `${day1}.${month1}.${year1}`
              payload.labels.push({ text: fundingEnd, type: 'text' })
              // this.rowItemsAmount++
              fundedDateSet = true
            } else if (
              impactSettings[i].fundingEndsDate === null &&
              impactSettings.every(({ source }) => source.includes('oneTime'))
            ) {
              payload.labels.push({ text: 'No funding', type: 'text' })
              fundedDateSet = true
            } else if (impactSettings[i].fundingEndsDate === null && !fundedDateSet) {
              payload.labels.push({ text: 'Ongoing', type: 'text' })
              fundedDateSet = true
            } else if (item.stoppedAt && !fundedDateSet) {
              payload.labels.push({ text: 'Stopped', type: 'text' })
              fundedDateSet = true
            }

            // get the impact pills
            if (impactSettings[i].impacts && impactSettings[i].impacts.length > 0) {
              // set the impact labels
              for (let j = 0; j < impactSettings[i].impacts.length; j++) {
                impactObject.labels.push({
                  text: `${
                    impactSettings[i].impacts[j].type === 'carbon'
                      ? impactSettings[i].impacts[j].amount + 'kg'
                      : impactSettings[i].impacts[j].amount
                  } ${
                    impactSettings[i].impacts[j].type === 'carbon'
                      ? 'CO2'
                      : impactSettings[i].impacts[j].type
                  }`,
                  type: impactSettings[i].impacts[j].type,
                })
              }
            }

            // set the source type
            switch (impactSettings[i].source) {
              case 'oneTimeImpact':
                impactObject.labels.push({ text: 'One-time impact', type: 'plan' })
                break
              case 'oneTimeAllowance':
                impactObject.labels.push({ text: 'One-time allowance', type: 'plan' })
                break
              case 'monthlyAllowance':
                impactObject.labels.push({ text: 'Monthly allowance', type: 'plan' })
                break
              case 'monthlyImpact':
                impactObject.labels.push({ text: 'Monthly impact', type: 'plan' })
                break
            }

            if (impactSettings[i].subscriptions && impactSettings[i].subscriptions.length > 0) {
              for (let j = 0; j < impactSettings[i].subscriptions.length; j++) {
                const type = impactSettings[i].subscriptions[j].type
                switch (type) {
                  case 'ECOMMERCE':
                  case 'ecommerce':
                    impactObject.labels.push({
                      text: `${impactSettings[i].subscriptions[j].amount} Ecommerce`,
                      type: 'subscription',
                    })
                    break
                  case 'starterBusiness':
                    impactObject.labels.push({
                      text: `${impactSettings[i].subscriptions[j].amount} Starter`,
                      type: 'subscription',
                    })
                    break
                  case 'growthBusiness':
                    impactObject.labels.push({
                      text: `${impactSettings[i].subscriptions[j].amount} Growth`,
                      type: 'subscription',
                    })
                    break
                  case 'premiumBusiness':
                    impactObject.labels.push({
                      text: `${impactSettings[i].subscriptions[j].amount} Premium`,
                      type: 'subscription',
                    })
                    break
                }
              }
            }
          }
          payload.labels.push(impactObject)
        }

        // set status
        if (item.stoppedAt) {
          payload.status.text = 'stopped'
        } else {
          if (item.inviteAcceptedAt !== '' && item.inviteAcceptedAt !== null) {
            payload.status.text = 'active'
          } else {
            payload.status.text = 'pending'
          }
        }
        // Check last nudge
        let nudgingActive = false
        if (item.lastInviteNudge) {
          const lastNudge = new Date(item.lastInviteNudge)
          const now = new Date()
          const msBetweenDates = Math.abs(lastNudge.getTime() - now.getTime())
          const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000)
          if (hoursBetweenDates > 24) nudgingActive = true
        } else {
          nudgingActive = true
        }

        // set quickmenu items
        if (userIsConnectee) {
          payload.quickMenuItems = [
            { label: 'Reward', value: 'reward' },
            { label: 'Funding history', value: 'history' },
          ]
        } else if (item.connectee.id === null && nudgingActive) {
          payload.quickMenuItems = [
            { label: 'Funding history', value: 'history' },
            { label: 'Send reminder (1 / day)', value: 'remind' },
            { label: 'Edit', value: 'editContact' },
            { label: 'Stop partnership', value: 'stop' },
          ]
        } else if (item.connectee.id === null && !nudgingActive) {
          payload.quickMenuItems = [
            { label: 'Funding history', value: 'history' },
            { label: 'Reminder sent (1 / day)', value: 'remindNotActive' },
            { label: 'Stop partnership', value: 'stop' },
          ]
        } else if (
          item.impactSettings.some(({ source }) => source === 'oneTimeAllowance') ||
          item.impactSettings.some(({ source }) => source === 'oneTimeImpact')
        ) {
          payload.quickMenuItems = [
            { label: 'Reward', value: 'reward' },
            { label: 'Funding history', value: 'history' },
            { label: 'Stop partnership', value: 'stop' },
          ]
        } else if (
          item.impactSettings.some(({ source }) => source === 'monthlyAllowance') &&
          !item.impactSettings
            .find(
              (setting) =>
                setting.userId === this.accountId && setting.source === 'monthlyAllowance',
            )
            ?.allowances.every((allowance) => allowance.claimedAt)
        ) {
          payload.quickMenuItems = [
            { label: 'Funding history', value: 'history' },
            { label: 'Stop partnership', value: 'stop' },
          ]
        } else if (item.stoppedAt) {
          payload.quickMenuItems = [
            { label: 'Reward', value: 'reward' },
            { label: 'Funding history', value: 'history' },
          ]
        } else {
          payload.quickMenuItems = [
            { label: 'Reward', value: 'reward' },
            { label: 'Edit', value: 'edit' },
            { label: 'Funding history', value: 'history' },
            { label: 'Stop partnership', value: 'stop' },
          ]
        }

        return payload
      })
      if (this.rowItems.length === 0) {
        this.noSearchResults = true
      } else {
        this.noSearchResults = false
      }
    },
    setMenuItems() {
      this.menuItems = []
      this.menuItems.push(
        {
          label: this.t('partner_name'),
          value: 'name',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('added'),
          value: 'added',
          sortDirection: 'desc',
          sortedBy: true,
          sortable: true,
        },
        {
          label: this.t('funding_end'),
          value: 'ended',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('impact'),
          value: 'impact',
          sortDirection: 'desc',
          customWidth: 143,
          sortable: false,
        },
        {
          label: this.t('status'),
          value: 'status',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
      )
    },
    resetMenuItems() {
      this.menuItems = []
      this.menuItems.push(
        {
          label: this.t('partner_name'),
          value: 'name',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('added'),
          value: 'added',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('funding_end'),
          value: 'ended',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('impact'),
          value: 'impact',
          sortDirection: 'desc',
          customWidth: 143,
          sortable: false,
        },
        {
          label: this.t('status'),
          value: 'status',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
      )
    },
    setFooterItems() {
      const totalPages = this.partnerCount / 10
      this.footerItems = []

      if (totalPages > 1) {
        for (let i = 0; i < totalPages; i++) {
          if (i === this.activePage) {
            this.footerItems.push({ label: `${i + 1}`, value: i + 1, active: true })
          } else {
            this.footerItems.push({ label: `${i + 1}`, value: i + 1, active: false })
          }
        }
      } else {
        this.footerItems.push({ label: 1, value: 1, active: true })
      }
    },
    reportTableSize() {
      const table = document.querySelector<HTMLElement>('.menu-bar')

      if (!table) return
      this.tableWidth = table.offsetWidth
      this.setColumnWidth()
    },
    setColumnWidth() {
      this.columnWidth = (this.tableWidth - 160) / this.rowItemsAmount
    },
    setEditItems(relationsId) {
      this.editItems = this.partnersData.filter((item) => item._id === relationsId)
    },
    async quickMenuClick(relationsId, id, name, value) {
      if (value === 'reward' && !this.hasSubscription) {
        this.$emit('setCardDialogClick')
      } else {
        this.$emit('dialogClick')
        this.activeQuickMenuItem = value
        this.relationsIdQuickMenuItem = relationsId
        this.connecteeIdQuickMenuItem = id
        this.partnerName = name
        if (value === 'edit') this.setEditItems(relationsId)
        if (value === 'editContact') this.setEditItems(relationsId)
        if (value === 'remind') {
          await sendReminder(relationsId)
          this.$emit('getFreshDataClick')
        }
        if (value !== 'remindNotActive' && value !== 'remind') this.quickMenuDialog = true
      }
    },
    dialogClick() {
      if (this.quickMenuDialog) {
        this.quickMenuDialog = false
        this.activeQuickMenuItem = ''
      } else {
        this.quickMenuDialog = true
      }
    },
    async search(value) {
      this.searchString = value
      this.skip = 0
      setTimeout(async () => {
        if (value.length >= 3) {
          this.tableLoading = true
          await this.getPartners()
          this.setFooterItems()
          this.tableLoading = false
        }
        if (value.length < 1) {
          this.tableLoading = true
          await this.getPartners()
          this.setFooterItems()
          this.tableLoading = false
        }
      }, 500)
    },
    async sort(value, sortDirection, index) {
      this.tableLoading = true
      this.resetMenuItems()
      this.menuItems[index].active = !sortDirection
      this.menuItems[index].sortedBy = true
      if (sortDirection === 'asc') {
        this.menuItems[index].sortDirection = 'desc'
        this.sortDirection = 'desc'
      } else {
        this.menuItems[index].sortDirection = 'asc'
        this.sortDirection = 'asc'
      }
      this.sortBy = value
      if (this.activePage > 0) {
        this.activePage = 0
        this.skip = 0
        this.setFooterItems()
      }
      await this.getPartners()
      this.tableLoading = false
    },
    async footerClick(value) {
      this.tableLoading = true
      this.activePage = value
      this.skip = this.activePage * 10
      this.setFooterItems()
      await this.getPartners()
      this.tableLoading = false
    },
    async arrowClick(value) {
      const maxPages = this.partnerCount / 10
      if (value === 'next' && this.activePage + 1 < maxPages) {
        this.tableLoading = true
        this.activePage++
        this.skip = this.activePage * 10
        this.setFooterItems()
        await this.getPartners()
        this.tableLoading = false
      }

      if (value === 'back' && this.activePage > 0) {
        this.tableLoading = true
        this.activePage--
        this.skip = this.activePage * 10
        this.setFooterItems()
        await this.getPartners()
        this.tableLoading = false
      }
    },
    onWindowWidthChange() {
      this.reportTableSize()
    },
    onLoadingEnd() {
      if (this.empty) {
        this.$emit('hideButtonClick', true)
      } else {
        this.$emit('hideButtonClick', false)
      }
    },
    async onGetFreshDataChange() {
      this.loading = true
      await this.getPartners()
      if (this.partnersData.length < 1) {
        this.empty = true
        this.$emit('hideButtonClick', true)
      } else {
        this.empty = false
        this.$emit('emptyClick', '')
        this.$emit('hideButtonClick', false)
      }
      this.setMenuItems()
      this.setFooterItems()
      this.reportTableSize()
      this.loading = false
    },
  },
  props: {
    getFreshData: {
      type: Boolean,
    },
  },
  watch: {
    windowWidth: [
      {
        handler: 'onWindowWidthChange',
      },
    ],
    loading: [
      {
        handler: 'onLoadingEnd',
      },
    ],
    getFreshData: [
      {
        handler: 'onGetFreshDataChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.partner-grid-outer {
  margin-top: 24px;
}

.empty {
  margin-top: 48px;
}
</style>
