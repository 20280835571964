<template>
  <div>
    <div v-if="loaded" class="outer-wrapper">
      <div class="success-p">
        <div class="success-wrapper">
          <div class="success-partner">
            <div class="header-wrapper">
              <img
                v-if="updateAllowance"
                class="chevron"
                style="width: 30px"
                alt="back"
                src="@/assets/icons/chevron_left.svg"
                @click="$router.back()"
              />
              <div class="header" style="text-align: left">
                {{ header }}
              </div>
            </div>

            <div class="subheader" v-html="subheader" />
            <ChooseAllowance
              v-if="fundingType === 'monthlyAllowance' || fundingType === 'oneTimeAllowance'"
              :allowance-amount="relationAllowanceAmount"
              :relations-id="$route.params.relationsId"
              :connector-currency="connectorCurrency"
            />

            <NoAllowance
              v-if="fundingType !== 'monthlyAllowance' && fundingType !== 'oneTimeAllowance'"
              :impacts="impacts"
              :one-time-impacts="oneTimeImpacts"
              :funding-type="fundingType"
            />
          </div>
        </div>
      </div>
    </div>
    <loading v-if="!loaded" />
  </div>
</template>

<script lang="ts">
import ChooseAllowance from '@/components/register/partner/ChooseAllowance.vue'
import NoAllowance from '@/components/register/partner/NoAllowance.vue'
import { getRelation } from '@api/index'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import type { SubscriptionItem } from '@/store/subscriptions'
import type { Currency, Emptyable, TotalImpact } from '@/helpers/interfaces'
import Loading from '@/components/tools/Loading.vue'
import { defineComponent } from 'vue'
import type { NavigationFailure } from 'vue-router'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'PartnerSuccessView',
  components: {
    Loading,
    ChooseAllowance,
    NoAllowance,
  },
  mixins: [LoadCrispMixin],
  data() {
    return {
      connectorName: '',
      connectorCurrency: '',
      header: '',
      oneTimeImpacts: [],
      impacts: [],
      subscriptions: [],
      allowances: [],
      fundingMonths: 0,
      fundingEnds: '',
      fundingType: '',
      updateAllowance: false,
      loaded: false,
    } as {
      connectorCurrency: Emptyable<Currency>
      header: TranslateResult
      oneTimeImpacts: TotalImpact[]
      impacts: TotalImpact[]
      connectorName: string
      subscriptions: unknown[]
      allowances: unknown[]
      fundingMonths: number
      fundingEnds: string
      fundingType: string
      updateAllowance: boolean
      loaded: boolean
    }
  },
  computed: {
    planName(): string {
      switch (this.activeSubscription.product) {
        case 'starterBusinessYearly':
        case 'starterBusiness':
          return 'Starter'
        case 'premiumBusiness':
        case 'premiumBusinessYearly':
          return 'Premium'
        case 'growthBusiness':
        case 'growthBusinessYearly':
          return 'Growth'
        case 'enterpriseBusiness':
          return 'Enterprise'
        default:
          return 'Starter'
      }
    },
    subheader(): TranslateResult {
      switch (this.fundingType) {
        case 'monthlyImpact':
          if (this.fundingEnds) {
            return this.t('monthly_impact', {
              connectorName: this.connectorName,
              fundingEnds: this.fundingEnds,
            })
          } else {
            return this.t('monthly_impact_ongoing', { connectorName: this.connectorName })
          }
        case 'oneTimeImpact':
          if (this.fundingEnds) {
            return this.t('onetime_impact', {
              connectorName: this.connectorName,
              fundingEnds: this.fundingEnds,
            })
          } else {
            return this.t('onetime_impact_ongoing', { connectorName: this.connectorName })
          }
        case 'oneTimeAllowance':
          if (this.fundingEnds) {
            return this.t('onetime_allowance', {
              connectorName: this.connectorName,
              allowanceAmount: this.relationAllowanceAmount,
              fundingEnds: this.fundingEnds,
            })
          } else {
            return this.t('onetime_allowance_ongoing', {
              connectorName: this.connectorName,
              allowanceAmount: this.relationAllowanceAmount,
            })
          }
        case 'monthlyAllowance':
          if (this.fundingEnds) {
            return this.t('monthly_allowance', {
              connectorName: this.connectorName,
              allowanceAmount: this.relationAllowanceAmount,
              fundingEnds: this.fundingEnds,
            })
          } else {
            return this.t('monthly_allowance_ongoing', {
              connectorName: this.connectorName,
              allowanceAmount: this.relationAllowanceAmount,
            })
          }
        case 'subscription':
          if (this.fundingEnds) {
            return this.t('subscription', {
              connectorName: this.connectorName,
              fundingEnds: this.fundingEnds,
              planName: this.planName,
            })
          } else {
            return this.t('subscription_ongoing', {
              connectorName: this.connectorName,
              planName: this.planName,
            })
          }
        default:
          return ''
      }
    },
    relationAllowanceAmount(): number {
      return this.$store.getters['getRelationAllowanceAmount']
    },
    hasSubscription(): boolean {
      return this.$store.getters['hasPaidSubscriptionItem']
    },
    activeSubscription(): SubscriptionItem {
      return this.$store.getters['getActivePaidSubscriptionItem']
    },
  },
  async created() {
    this.routeCheck()
    await this.getRelationInfo()
    await this.setRequiredActionAlert()

    await this.setAccount()
    const redirectionRoute = await this.setSubscriptionItems()
    if (!redirectionRoute) await this.setOnboardingSkipped()

    this.toggleChatBubbleVisibility(false)
    this.loaded = true
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`PartnerSuccessView.${key}`, params ?? {})
    },
    async getRelationInfo(): Promise<void> {
      const {
        data: {
          impactSettings,
          connector: { displayName: connector, currency },
          temporaryImpacts,
        },
      } = await getRelation(`${this.$route.params.relationsId}`)
      // set the name of the person that invited
      this.connectorName = connector
      this.connectorCurrency = currency

      // grab the correct impactSettings
      for (let i = 0; i < impactSettings.length; i++) {
        // get funding months
        this.fundingMonths = impactSettings[i].fundingMonths

        // get funding ends
        if (impactSettings[i].fundingEndsDate) {
          this.fundingEnds = new Date(impactSettings[i].fundingEndsDate).toLocaleDateString(
            'en-us',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
          )
        }

        if (this.updateAllowance) {
          this.header = this.t('update')
        } else {
          this.header = this.t('success')
        }

        const hasImpactOrAllowance = impactSettings.some(
          (setting) => setting.impacts.length > 0 || setting.allowances.length > 0,
        )
        let subscriptionCounter = 0
        switch (impactSettings[i].source) {
          case 'monthlyImpact':
            this.fundingType = 'monthlyImpact'
            this.impacts = impactSettings[i].impacts.map(({ type, amount }) => ({ type, amount }))
            break
          case 'monthlyAllowance':
            this.fundingType = 'monthlyAllowance'
            break
          case 'oneTimeImpact':
            this.fundingType = 'oneTimeImpact'
            this.oneTimeImpacts = impactSettings[i].impacts.map(({ type, amount }) => ({
              type,
              amount,
            }))
            break
          case 'oneTimeAllowance':
            this.fundingType = 'oneTimeAllowance'
            break
          case 'subscription':
            if (!hasImpactOrAllowance) {
              this.fundingType = 'subscription'
            }
            if (subscriptionCounter === 0) {
              const impacts = temporaryImpacts
                .filter((impact) => impact.source === impactSettings[i].subscriptions[0].type)
                .map(({ type, amount }) => ({ type, amount }))
              this.impacts.push(...impacts)
            }
            subscriptionCounter++
            break
        }
      }
    },
    routeCheck(): void {
      this.updateAllowance = !!this.$route.query.update
    },
    setAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
    setOnboardingSkipped(): Promise<void> {
      return this.$store.dispatch('setOnboardingSkipped')
    },
    setSubscriptionItems(): Promise<void | NavigationFailure> {
      return this.$store.dispatch('setSubscriptionItems')
    },
    setRequiredActionAlert(): Promise<void> {
      return this.$store.dispatch('setRequiredActionAlert')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.outer-wrapper {
  padding: 8vh 2%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/backgrounds/signup-background.svg');
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100vw;
  min-width: 100vw;
  top: 0;
  left: 0;
  z-index: 4;
}

.success-p {
  max-width: 1100px;
  height: 100%;
  min-height: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.success-wrapper {
  width: 100%;
  display: flex;
  padding: 50px;
  flex-direction: column;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #212121;
  background: #f9f9f9;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
  border-radius: 8px;
}

.success-partner {
  display: flex;
  flex-direction: column;
}

.header-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.chevron {
  cursor: pointer;
}

.header {
  width: 100%;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #212121;
}

.subheader {
  text-align: left;
  color: #212121;
  font-size: 22px;
  line-height: 29px;
  max-width: 600px;
  margin-bottom: 16px;
}

.subheader:deep(b) {
  background-color: var(--ui-green);
  color: var(--ui-beige);
  border-radius: 5px;
  padding: 0 5px;
}

.button-wrapper {
  margin-top: 45px;
}

.open-popup-button {
  color: var(--ui-green);
  text-decoration: underline;
  margin-left: 5px;
}

@media #{map-get($display-breakpoints, 'md')} {
  .success-t {
    padding: 8vh 5%;
  }

  .success-wrapper {
    padding: 30px;
  }
}

@media #{map-get($display-breakpoints, 'sm')} {
  .success-p {
    padding: 8vh 4%;
  }

  .success-wrapper {
    padding: 20px;
  }
}

@media #{map-get($display-breakpoints, 'xs')} {
  .success-wrapper {
    padding: 20px;
  }

  .outer-wrapper {
    padding: 0;
  }

  .success-p {
    padding: 0;
    height: 100%;
    min-height: 100vh;
  }

  .success-wrapper {
    justify-content: center;
  }
}
</style>
