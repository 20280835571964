<template>
  <div class="demo-feed">
    <h2 class="demo-feed-title">
      {{ t('title') }}
    </h2>
    <p class="desc">
      {{ t('description') }}
    </p>
    <div class="feed">
      <ul :class="['feed-wrapper', type]">
        <NewsFeedItem :feed-item="dummyFeedItem" />
        <NewsFeedItem
          v-for="(feedItem, index) in sortedFeedItems"
          :key="index"
          :feed-item="{
            ...feedItem,
            type: type,
            companyName: account.companyName,
            url: sustainabilitySite,
          }"
        />
        <NewsFeedItem :feed-item="dummyFeedItemMap">
          <template v-slot:cms-content>
            <div class="image-wrapper">
              <img
                class="map-image"
                width="600"
                height="300"
                :src="require('@/assets/backgrounds/maps-image.svg')"
                alt="map"
              />
            </div>
          </template>
        </NewsFeedItem>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import NewsFeedItem from '@/components/ImpactWallet/NewsFeedItem.vue'
import type { FeedItem, FeedItemType } from '@/helpers/interfaces'
import type { Account } from '@/store'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DemoFeed',
  components: { NewsFeedItem },
  data() {
    return {
      dummyFeedItemMap: {
        _id: '',
        type: 'PROJECT_DESCRIPTION',
        title: 'Tree planting',
        description: '2 trees will be planted in Bosawas, Nicaragua',
        link: {
          url: 'https://gooogle.com',
          label: 'View details',
        },
        image: '',
        createdAt: '',
      },
      dummyFeedItem: {
        _id: '',
        type: 'ORDER_CREATED',
        title: 'Order placed',
        description:
          'Your order offsets 12kgs of carbon, plants 2 trees, and rescues 4 bottles of plastic',
        link: {
          url: 'https://gooogle.com',
          label: 'View order',
        },
        createdAt: '',
        image: '',
      },
    } as {
      dummyFeedItemMap: FeedItem
      dummyFeedItem: FeedItem
    }
  },
  computed: {
    sortedFeedItems(): Record<string, string>[] {
      // always show the feeditem without targetId first
      return [...(this.propFeedItems ?? [])].sort((a, b) => {
        if (a.targetId && !b.targetId) return 1
        if (!a.targetId && b.targetId) return -1
        return 0
      })
    },
    sustainabilitySite(): string {
      return this.$store.getters['getSustainabilitySite']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`DemoFeed.${key}`)
    },
  },
  props: {
    propFeedItems: {
      type: Array as PropType<Record<string, string>[]>,
    },
    type: {
      type: String as PropType<FeedItemType>,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 10px;
}

.desc {
  margin-bottom: 20px;
}

.demo-feed {
  background-color: var(--ui-white);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-wide);
  padding: 20px;
}

.demo-feed-title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}

.feed {
  background-color: var(--ui-black);
  border-radius: 20px;
  border: 20px solid var(--ui-black);
  position: relative;
}

.feed::before {
  border-top: 22px solid var(--ui-black);
  border-bottom: 22px solid var(--ui-black);
  content: '';
  display: block;
  height: 52px;
  width: 2px;
  left: calc(100% + 20px);
  top: 15px;
  position: absolute;
}

.feed::after {
  content: '';
  display: block;
  background-color: var(--ui-black);
  height: 2px;
  width: 22px;
  bottom: calc(100% + 20px);
  right: 20px;
  position: absolute;
}

.feed-wrapper {
  border-radius: 10px;
  background-color: var(--ui-white);
  list-style-type: none;
  padding: 20px;
  overflow: hidden;
  max-height: 600px;
}

.feed-wrapper.goal {
  overflow-y: auto;
}

.image-wrapper {
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: var(--border-radius-big);
}

.map-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transform: scale(1.4);
}
</style>
