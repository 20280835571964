import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentTag), {
    class: _normalizeClass([
      'gs-button',
      `gs-${_ctx.type}`,
      _ctx.size,
      { 'is-active': _ctx.active },
      { 'full-width': _ctx.fullWidth },
      { outlined: _ctx.outlined },
    ]),
    style: _normalizeStyle({ width: _ctx.width + 'px' }),
    disabled: _ctx.disabled,
    href: _ctx.href,
    target: _ctx.target
  }, {
    default: _withCtx(() => [
      (!!_ctx.icon)
        ? (_openBlock(), _createBlock(_component_v_icon, {
            key: 0,
            class: "gs-icon material-icons-outlined"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.icon), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass([
        'text',
        { 'is-loading': _ctx.loading },
        { 'with-icon': _ctx.icon },
        { 'text-uppercase': _ctx.uppercased },
        { 'text-capitalize': _ctx.capitalized },
      ])
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_v_progress_circular, {
            key: 2,
            id: "loader",
            size: _ctx.loaderSize,
            indeterminate: "",
            color: _ctx.spinnerColor
          }, null, 8, ["size", "color"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class", "style", "disabled", "href", "target"])), [
    [_directive_ripple, _ctx.componentTag === 'a' ? false : { class: `${_ctx.rippleColor}--text` }]
  ])
}