<template>
  <div class="how-it-works-wrapper">
    <div class="how-it-works-header">
      {{ t('header') }}
    </div>
    <div class="how-it-works-text">
      {{ isBusiness ? t('text') : t('text_personal') }}
      <span v-if="isBusiness" class="small"
        >( {{ t('integrations') }}
        <span class="link" @click="$emit('integrationClick')">{{ t('click_here') }}</span> )</span
      >
    </div>

    <!-- <div class="divider-line" /> -->

    <div class="how-it-works-subheader">
      {{ t('how_it_works') }}
    </div>

    <div class="number-explanation-wrapper">
      <div class="number">1</div>
      <div class="explanation">
        {{ t('choose_impact') }}
      </div>
    </div>

    <div class="number-explanation-wrapper">
      <div class="number">2</div>
      <div class="explanation">
        {{
          account.accountType === 'personal' || account.accountType === 'family'
            ? t('decide_personal')
            : t('decide')
        }}
      </div>
    </div>

    <div class="number-explanation-wrapper" style="margin-bottom: 0">
      <div class="number">✓</div>
      <div class="explanation">
        {{ t('impact_added') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HowItWorks',
  emits: ['integrationClick'],
  methods: {
    t(key: string) {
      return this.$t(`HowItWorks.${key}`)
    },
  },
  computed: {
    isBusiness(): boolean {
      return this.$store.getters['getAccountTypeIsBusiness']
    },
  },
  props: {
    account: {
      type: Object as PropType<Account>,
    },
  },
})
</script>

<style lang="scss" scoped>
hr {
  opacity: 0.2;
  margin-top: 14px;
}

.how-it-works-wrapper {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
}

.how-it-works-header {
  color: #212121;
  font-size: 38px;
  line-height: 48px;
  font-weight: 600;
}

.how-it-works-text {
  color: #212121;
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: 4px;
}

.small {
  font-size: 12px;
  color: #757d82;
}

.link {
  cursor: pointer;
  color: #3b755f;
  text-decoration: underline;
  font-weight: bold;
}

// .divider-line {
//   height: 1px;
//   width: 100%;
//   background-color: black;
//   opacity: 0.2;
//   margin-top: 14px;
// }

.how-it-works-subheader {
  color: #212121;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 16px;
  margin-top: 30px;
}

.number-explanation-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.number {
  background: #f2ebdb;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212121;
  margin-right: 8px;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.explanation {
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
</style>
