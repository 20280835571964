<template>
  <div v-if="!loading">
    <div class="description">
      <div class="description-header">Update Ledger</div>
      <div class="description-text">
        Update the receipt ledger below. These are shown to all users in their dashboard.
      </div>
    </div>
    <gs-button class="button-add" depressed @click="addMonth = true">
      <div class="button-text">Add Month</div>
    </gs-button>

    <AddMonth v-if="addMonth" :loading="loading" @addMonthToArray="addMonthToArray" />

    <v-tabs v-model="tab" bg-color="transparent" color="#3B755F" grow style="margin-bottom: 20px">
      <v-tab class="tabs">
        <div class="tab-header">Carbon</div>
      </v-tab>
      <v-tab class="tabs">
        <div class="tab-header">Plastic</div>
      </v-tab>
      <v-tab class="tabs">
        <div class="tab-header">Trees</div>
      </v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <!-- Carbon -->
      <v-window-item transition="fade-transition">
        <div class="ledger-container">
          <v-expansion-panels variant="accordion" flat>
            <v-expansion-panel
              v-for="(item, a) in items.filter(({ type }) => type === 'carbon')"
              :key="a"
            >
              <v-expansion-panel-title>
                <div class="header-wrapper">
                  <div class="header">
                    {{ item.date }}
                  </div>
                  <div class="header">
                    {{ item.type }}
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div class="buttons-wrapper">
                  <gs-button class="button" :type="'delete'" @click="deleteReceipt(item.receiptId)">
                    Delete Month
                  </gs-button>
                  <gs-button class="button" @click="addReceipt(item.receiptId)">
                    Add Receipt
                  </gs-button>
                </div>
                <div v-for="(thing, b) in item.receipts" :key="b" class="accordion-content">
                  <component
                    :is="receipt.template"
                    v-for="(receipt, c) in receipts"
                    :key="c"
                    :item="thing"
                    :item-index="a"
                    :receipt-id="item.receiptId"
                    :number-of-receipts="item.receipts.length"
                    :this-is-receipt-number="b"
                    @deleteReceiptItem="deleteReceiptItem"
                    @setReceiptClick="setReceipt"
                  />
                </div>
                <gs-button
                  class="button"
                  depressed
                  @click="saveReceipts(item.receiptId)"
                  :disabled="loading"
                >
                  Save
                  <v-progress-circular v-if="loading" indeterminate color="#F9F9F9" />
                </gs-button>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-window-item>

      <!-- Plastic -->
      <v-window-item transition="fade-transition">
        <div class="tab-content">
          <div class="ledger-container">
            <v-expansion-panels variant="accordion" flat>
              <v-expansion-panel
                v-for="(item, a) in items.filter(({ type }) => type === 'plastic')"
                :key="a"
              >
                <v-expansion-panel-title>
                  <div class="header-wrapper">
                    <div class="header">
                      {{ item.date }}
                    </div>
                    <div class="header">
                      {{ item.type }}
                    </div>
                  </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <div class="buttons-wrapper">
                    <gs-button class="button" @click="deleteReceipt(item.receiptId)">
                      Delete Month
                    </gs-button>
                    <gs-button class="button" @click="addReceipt(item.receiptId)">
                      Add Receipt
                    </gs-button>
                  </div>
                  <div v-for="(thing, b) in item.receipts" :key="b" class="accordion-content">
                    <component
                      :is="receipt.template"
                      v-for="(receipt, c) in receipts"
                      :key="c"
                      :item="thing"
                      :item-index="a"
                      :receipt-id="item.receiptId"
                      :number-of-receipts="item.receipts.length"
                      :this-is-receipt-number="b"
                      @deleteReceiptItem="deleteReceiptItem"
                      @setReceiptClick="setReceipt"
                    />
                  </div>
                  <gs-button
                    class="button"
                    depressed
                    @click="saveReceipts(item.receiptId)"
                    :disabled="loading"
                  >
                    Save
                    <v-progress-circular v-if="loading" indeterminate color="#F9F9F9" />
                  </gs-button>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </v-window-item>

      <!-- Trees -->
      <v-window-item transition="fade-transition">
        <div class="tab-content">
          <div class="ledger-container">
            <v-expansion-panels variant="accordion" flat>
              <v-expansion-panel
                v-for="(item, a) in items.filter(({ type }) => type === 'trees')"
                :key="a"
              >
                <v-expansion-panel-title>
                  <div class="header-wrapper">
                    <div class="header">
                      {{ item.date }}
                    </div>
                    <div class="header">
                      {{ item.type }}
                    </div>
                  </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <div class="buttons-wrapper">
                    <gs-button class="button" @click="deleteReceipt(item.receiptId)">
                      Delete Month
                    </gs-button>
                    <gs-button class="button" @click="addReceipt(item.receiptId)">
                      Add Receipt
                    </gs-button>
                  </div>
                  <div v-for="(thing, b) in item.receipts" :key="b" class="accordion-content">
                    <component
                      :is="receipt.template"
                      v-for="(receipt, c) in receipts"
                      :key="c"
                      :item="thing"
                      :item-index="a"
                      :receipt-id="item.receiptId"
                      :number-of-receipts="item.receipts.length"
                      :this-is-receipt-number="b"
                      @deleteReceiptItem="deleteReceiptItem"
                      @setReceiptClick="setReceipt"
                    />
                  </div>
                  <gs-button
                    class="button"
                    depressed
                    @click="saveReceipts(item.receiptId)"
                    :disabled="loading"
                  >
                    Save
                    <v-progress-circular v-if="loading" indeterminate color="#F9F9F9" />
                  </gs-button>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import UpdateProjectReceipt from '@/components/Admin/UpdateProjectReceipt.vue'
import AddMonth from '@/components/Admin/AddMonth.vue'
import type { Receipt } from '@api/index'
import {
  createReceipt,
  deleteReceipt,
  deleteReceiptItem,
  getAllReceipts,
  updateReceipt,
} from '@api/index'
import type { Account } from '@/store'
import type { OffsetType } from '@/helpers/interfaces'
import de from '@/i18n/langs/de'
import { defineComponent } from 'vue'
import type { Notification } from '@/store/notification'

export default defineComponent({
  name: 'UpdateLedger',
  computed: {
    de() {
      return de
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
  components: {
    UpdateProjectReceipt,
    AddMonth,
  },
  data() {
    return {
      loading: false,
      addMonth: false,
      newReceipt: { template: 'UpdateProjectReceipt' },
      receipts: [{ template: 'UpdateProjectReceipt' }],
      items: [],
      tab: 0,
    } as {
      loading: boolean
      addMonth: boolean
      newReceipt: { template: string }
      receipts: { template: string }[]
      items: Array<Receipt>
      tab: number
    }
  },
  async created() {
    await this.getReceipts()
  },
  methods: {
    t(key: string) {
      return this.$t(`UpdateLedger.${key}`)
    },
    async getReceipts() {
      this.loading = true
      this.items = await getAllReceipts()
      this.loading = false
    },
    addReceipt(receiptId: string) {
      this.items = this.items.map((receipt) => {
        if (receipt.receiptId === receiptId)
          return {
            ...receipt,
            receipts: [...receipt.receipts, { receiptName: '', imageUrl: '', id: '' }],
          }
        else return receipt
      })
    },
    async addMonthToArray(date: string, type: OffsetType) {
      try {
        await createReceipt(date, type)
        await this.getReceipts()
        this.addMonth = false
      } catch (e) {
        this.$store.dispatch('notify', {
          text: e.message,
          isError: true,
          isClosable: false,
          buttonText: 'close',
        } as Notification)
      }
    },
    async deleteReceipt(receiptId: string) {
      try {
        await deleteReceipt(receiptId)
        await this.getReceipts()
      } catch (e) {
        this.$store.dispatch('notify', {
          text: e.message,
          isError: true,
          isClosable: false,
          buttonText: 'close',
        } as Notification)
      }
    },
    async deleteReceiptItem(receiptId: string, itemId: string) {
      try {
        if (
          itemId &&
          this.items.some((receipt) => receipt.receipts.some((item) => item.id === itemId))
        )
          await deleteReceiptItem(receiptId, itemId)
        await this.getReceipts()
      } catch (e) {
        this.$store.dispatch('notify', {
          text: e.message,
          isError: true,
          isClosable: false,
          buttonText: 'close',
        } as Notification)
      }
    },
    setReceipt(name: string, image: string, receiptId: string, receiptIndex: number) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].receiptId === receiptId) {
          this.items[i].receipts[receiptIndex].receiptName = name
          this.items[i].receipts[receiptIndex].imageUrl = image
        }
      }
    },
    async saveReceipts(receiptId: string) {
      try {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].receiptId === receiptId) {
            await updateReceipt(receiptId, this.items[i].receipts)
          }
        }
        await this.getReceipts()
      } catch (e) {
        this.$store.dispatch('notify', {
          text: e.message,
          isError: true,
          isClosable: false,
          buttonText: 'close',
        } as Notification)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.description {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  margin-top: 35px;
  margin-bottom: 25px;
}

.description-header {
  margin-bottom: 10px;
  color: #212121;
  font-size: 22px;
  font-weight: 600;
}

.description-text {
  color: #212121;
  font-size: 16px;
  margin-top: 4px;
}

.button-add {
  margin-bottom: 20px;
}

.accordion-content {
  padding-bottom: 10px;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
}

.button {
  margin-left: 5px;
}

.buttons-wrapper {
  display: flex;
  margin-bottom: 10px;
}
</style>
