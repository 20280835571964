import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a7c2e7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel" }
const _hoisted_2 = { class: "panel__text-wrapper" }
const _hoisted_3 = { class: "panel__title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "panel__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", {
        class: "panel__description",
        innerHTML: _ctx.description
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}