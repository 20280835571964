<template>
  <div class="maintenance-view">
    <div class="maintenance-content-wrapper">
      <img
        :src="require('@/assets/greenspark-logo.svg')"
        alt="greenspark logo"
        class="greenspark-logo"
      />
      <h1 class="maintenance-title">
        {{ t('title') }}
      </h1>
      <p class="maintenance-text">
        {{
          t('text', { date: new Date($route.query.maintenanceUntil).toLocaleString(userLocale) })
        }}
      </p>
      <a href="https://www.getgreenspark.com/" class="homepage-link">
        {{ t('home_link_text') }}
      </a>
      <button class="contact-link animated-link" @click="openChat()">
        {{ t('contact_link_text') }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { Utils } from '@/helpers/mixins/utilsMixin'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MaintenanceView',
  mixins: [Utils, LoadCrispMixin],
  methods: {
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`MaintenanceView.${key}`, params ?? {})
    },
  },
})
</script>
<style lang="scss" scoped>
@import '~vuetify/settings';

.maintenance-view {
  background-color: var(--ui-beige);
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.maintenance-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px 0;
}

.greenspark-logo {
  height: 48px;
  margin-bottom: 40px;
}

.maintenance-title {
  font-size: 54px;
  line-height: 60px;
  text-align: center;
  color: var(--ui-green);
  margin-bottom: 40px;
}

.maintenance-text {
  font-size: 24px;
  line-height: 28px;
  color: var(--ui-green);
  text-align: center;
  margin-bottom: 40px;
  max-width: 45ch;
}

.homepage-link {
  background-color: var(--ui-green);
  color: var(--ui-white);
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 8px;
  font-weight: 600;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 420px;
  font-size: 24px;
  margin-bottom: 30px;
}

.homepage-link:hover {
  opacity: 0.8;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .greenspark-logo {
    height: 64px;
  }

  .maintenance-title {
    font-size: 72px;
    line-height: 78px;
  }

  .maintenance-text {
    font-size: 32px;
    line-height: 36px;
  }

  .contact-link {
    text-decoration: none;
  }
}
</style>
