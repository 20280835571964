<template>
  <div class="employee-wrapper">
    <div class="main-wrapper">
      <div class="header-wrapper">
        <div class="header">
          {{ t('subscription') }}
        </div>
        <div class="back" @click="$router.push('/choose-signup')">
          <div>
            {{ t('cancel') }}
          </div>
        </div>
      </div>

      <div class="columns-wrapper">
        <!-- Personal Plan -->
        <div class="column">
          <div class="employee-type-wrapper">
            <div class="plan-name">
              {{ t('personal') }}
            </div>
            <div v-if="account.currency === 'euro'" class="plan-price">
              {{ currencyEuro(personalPrice) }}
            </div>
            <div v-if="account.currency === 'pound'" class="plan-price">
              {{ currencyPound(personalPrice) }}
            </div>
            <div v-if="account.currency === 'dollar'" class="plan-price">
              {{ currencyDollar(personalPrice) }}
            </div>
            <div class="per-employee">
              {{ t('per_month') }}
            </div>

            <div class="plan-description">
              {{ t('personal_description') }}
            </div>

            <div class="text-field-wrapper">
              <v-text-field
                v-model.number="personalAmount"
                :label="t('amount')"
                variant="outlined"
                type="number"
                flat
                min="0"
                color="#3B755F"
                class="employee-input"
              />
              <div class="plus-minus">
                <div class="add" style="margin-bottom: 1px" @click="personalAmount++">+</div>
                <div
                  class="add"
                  style="margin-top: 1px"
                  @click="personalAmount !== 0 ? personalAmount-- : ''"
                >
                  -
                </div>
              </div>
            </div>

            <div class="description">
              <div class="item">
                <img class="icon" alt="check" :src="require('@/assets/icons/check_simple.svg')" />
                <div class="text">
                  <strong>{{ t('plastic') }}</strong> {{ t('removed_month') }}
                </div>
              </div>

              <div class="item">
                <img class="icon" alt="check" :src="require('@/assets/icons/check_simple.svg')" />
                <div class="text">
                  <strong>1 {{ t('mads_footprint') }}*</strong> {{ t('removed_year') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Plastic Hero Plan -->
        <div class="column">
          <div class="employee-type-wrapper">
            <div class="plan-name">
              {{ t('plastic_hero') }}
            </div>
            <div v-if="account.currency === 'euro'" class="plan-price">
              {{ currencyEuro(plasticHeroPrice) }}
            </div>
            <div v-if="account.currency === 'pound'" class="plan-price">
              {{ currencyPound(plasticHeroPrice) }}
            </div>
            <div v-if="account.currency === 'dollar'" class="plan-price">
              {{ currencyDollar(plasticHeroPrice) }}
            </div>
            <div class="per-employee">
              {{ t('per_month') }}
            </div>

            <div class="plan-description">
              {{ t('plastic_hero_description') }}
            </div>

            <div class="text-field-wrapper">
              <v-text-field
                v-model.number="plasticHeroAmount"
                :label="t('amount')"
                variant="outlined"
                type="number"
                flat
                min="0"
                color="#3B755F"
                class="employee-input"
              />
              <div class="plus-minus">
                <div class="add" style="margin-bottom: 1px" @click="plasticHeroAmount++">+</div>
                <div
                  class="add"
                  style="margin-top: 1px"
                  @click="plasticHeroAmount !== 0 ? plasticHeroAmount-- : ''"
                >
                  -
                </div>
              </div>
            </div>

            <div class="description">
              <div class="item">
                <img class="icon" alt="check" :src="require('@/assets/icons/check_simple.svg')" />
                <div class="text">
                  <strong>{{ t('plastic_plus') }}</strong> {{ t('removed_month') }}
                </div>
              </div>

              <div class="item">
                <img class="icon" alt="check" :src="require('@/assets/icons/check_simple.svg')" />
                <div class="text">
                  <strong>1 {{ t('mads_footprint') }}s*</strong> {{ t('removed_year') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Earth Hero Plan -->
        <div class="column">
          <div class="employee-type-wrapper">
            <div class="plan-name">
              {{ t('earth_hero') }}
            </div>
            <div v-if="account.currency === 'euro'" class="plan-price">
              {{ currencyEuro(earthHeroPrice) }}
            </div>
            <div v-if="account.currency === 'pound'" class="plan-price">
              {{ currencyPound(earthHeroPrice) }}
            </div>
            <div v-if="account.currency === 'dollar'" class="plan-price">
              {{ currencyDollar(earthHeroPrice) }}
            </div>
            <div class="per-employee">
              {{ t('per_month') }}
            </div>

            <div class="plan-description">
              {{ t('earth_hero_description') }}
            </div>

            <div class="text-field-wrapper">
              <v-text-field
                v-model.number="earthHeroAmount"
                :label="t('amount')"
                variant="outlined"
                type="number"
                flat
                min="0"
                color="#3B755F"
                class="employee-input"
              />
              <div class="plus-minus">
                <div class="add" style="margin-bottom: 1px" @click="earthHeroAmount++">+</div>
                <div
                  class="add"
                  style="margin-top: 1px"
                  @click="earthHeroAmount !== 0 ? earthHeroAmount-- : ''"
                >
                  -
                </div>
              </div>
            </div>

            <div class="description">
              <div class="item">
                <img class="icon" alt="check" :src="require('@/assets/icons/check_simple.svg')" />
                <div class="text">
                  <strong>{{ t('plastic_plus') }}</strong> {{ t('removed_month') }}
                </div>
              </div>

              <div class="item">
                <img class="icon" alt="check" :src="require('@/assets/icons/check_simple.svg')" />
                <div class="text">
                  <strong>4 {{ t('mads_footprint') }}s*</strong> {{ t('removed_year') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="button-wrapper">
        <gs-button
          :disabled="totalEmployees === 0"
          :full-width="true"
          size="large"
          @click.prevent="onNextClicked"
        >
          {{ t('continue') }}
        </gs-button>
      </div>
      <div class="small-info">*{{ t('info') }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChoosePersonalPlan',
  emits: ['nextClick'],
  data() {
    return {
      personalCarbon: 1.42,
      personalPlastic: 100,
      plasticHeroCarbon: 1.42,
      plasticHeroPlastic: 350,
      earthHeroCarbon: 5.7,
      earthHeroPlastic: 350,
    } as {
      personalCarbon: number
      personalPlastic: number
      plasticHeroCarbon: number
      plasticHeroPlastic: number
      earthHeroCarbon: number
      earthHeroPlastic: number
    }
  },
  computed: {
    earthHeroAmount: {
      get(): number | null {
        return this.account?.personalPlans.earthHero ?? null
      },
      set(value: string) {
        const parsed = parseInt(value)
        if (Number.isNaN(parsed)) {
          this.setUserPersonalPlans({ earthHero: null })
          return
        }

        this.setUserPersonalPlans({ earthHero: parsed >= 0 ? parsed : 0 })
      },
    },
    plasticHeroAmount: {
      get(): number | null {
        return this.account?.personalPlans.plasticHero ?? null
      },
      set(value: string) {
        const parsed = parseInt(value)
        if (Number.isNaN(parsed)) {
          this.setUserPersonalPlans({ plasticHero: null })
          return
        }

        this.setUserPersonalPlans({ plasticHero: parsed >= 0 ? parsed : 0 })
      },
    },
    personalAmount: {
      get(): number | null {
        return this.account?.personalPlans.personal ?? null
      },
      set(value: string) {
        const parsed = parseInt(value)
        if (Number.isNaN(parsed)) {
          this.setUserPersonalPlans({ personal: null })
          return
        }

        this.setUserPersonalPlans({ personal: parsed >= 0 ? parsed : 0 })
      },
    },
    totalEmployees(): number {
      return (
        (this.personalAmount ?? 0) + (this.plasticHeroAmount ?? 0) + (this.earthHeroAmount ?? 0)
      )
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`ChoosePersonalPlan.${key}`)
    },
    onNextClicked() {
      this.$emit('nextClick')
    },
    setUserPersonalPlans(plan) {
      return this.$store.commit('setUserPersonalPlans', plan)
    },
  },
  props: {
    account: {
      type: Object as PropType<Account>,
    },
    personalPrice: {
      type: Number,
    },
    plasticHeroPrice: {
      type: Number,
    },
    earthHeroPrice: {
      type: Number,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.plan-description {
  color: #212121;
  text-align: center;
  font-size: 14px;
  margin-bottom: 12px;
  height: 41px;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13.5px;
  color: #212121;
  padding: 0 12px 10px;
  width: 100%;
  margin-top: 8px;
}

.item {
  display: flex;
  text-align: left;
  align-items: flex-start;
  margin-top: 15px;
}

.text {
  margin-left: 7px;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
}

.employee-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-wrapper {
  position: relative;
  padding: 24px 36px;
  background: #f9f9f9;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
  border-radius: 8px;
}

.back {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #3b755f;
  cursor: pointer;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #212121;
}

.columns-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.column {
  width: 32%;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 10px;
}

.employee-type-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-name {
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #212121;
}

.plan-price {
  color: #212121;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

.per-employee {
  color: #b0b0b0;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
}

.text-field-wrapper {
  display: flex;
}

.employee-input {
  min-width: 80%;
  margin-bottom: 14px;
}

.plus-minus {
  min-width: 10%;
  cursor: pointer;
  height: 56px;
  margin-left: 6px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  font-weight: 600;
  background: #3b755f;
  font-size: 18px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
}

.icon {
  width: 22px;
  padding-top: 3px;
  filter: invert(81%) sepia(73%) saturate(459%) hue-rotate(132deg) brightness(78%) contrast(88%);
}

.button {
  margin-top: 20px;
}

.small-info {
  color: #8c959e;
  font-size: 12px;
  width: 100%;
  display: flex;
  margin-top: 16px;
}

@media #{map-get($display-breakpoints, 'md')} {
  .main-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
  }

  .button-wrapper {
    position: unset;
    width: 100%;
  }

  .header-wrapper {
    width: 100%;
  }

  .columns-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
  }

  .column {
    width: 100%;
  }

  .text-field-wrapper {
    width: 50%;
  }

  .employee-wrapper {
    margin-bottom: 20px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .header {
    font-size: 30px;
    text-align: center;
    margin-top: 15px;
  }

  .column {
    width: 100%;
    margin-bottom: 15px;
  }

  .columns-wrapper {
    width: 100%;
    flex-direction: column-reverse;
  }

  .button-wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .back {
    justify-content: flex-start;
    margin-bottom: 5px;
  }

  .main-wrapper {
    height: auto;
    padding: 24px 10px;
  }

  .header-wrapper {
    flex-direction: column-reverse;
  }

  .per-employee {
    margin-left: 10px;
  }

  .plan-name {
    font-size: 26px;
  }

  .plan-price {
    font-size: 25px;
  }

  .employee-wrapper {
    margin-bottom: 20px;
  }
}
</style>
