import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39c2dbbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "outer-wrapper"
}
const _hoisted_2 = { class: "success-p" }
const _hoisted_3 = { class: "success-wrapper" }
const _hoisted_4 = { class: "success-partner" }
const _hoisted_5 = { class: "header-wrapper" }
const _hoisted_6 = {
  class: "header",
  style: {"text-align":"left"}
}
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChooseAllowance = _resolveComponent("ChooseAllowance")!
  const _component_NoAllowance = _resolveComponent("NoAllowance")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.updateAllowance)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "chevron",
                        style: {"width":"30px"},
                        alt: "back",
                        src: "@/assets/icons/chevron_left.svg",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.header), 1)
                ]),
                _createElementVNode("div", {
                  class: "subheader",
                  innerHTML: _ctx.subheader
                }, null, 8, _hoisted_7),
                (_ctx.fundingType === 'monthlyAllowance' || _ctx.fundingType === 'oneTimeAllowance')
                  ? (_openBlock(), _createBlock(_component_ChooseAllowance, {
                      key: 0,
                      "allowance-amount": _ctx.relationAllowanceAmount,
                      "relations-id": _ctx.$route.params.relationsId,
                      "connector-currency": _ctx.connectorCurrency
                    }, null, 8, ["allowance-amount", "relations-id", "connector-currency"]))
                  : _createCommentVNode("", true),
                (_ctx.fundingType !== 'monthlyAllowance' && _ctx.fundingType !== 'oneTimeAllowance')
                  ? (_openBlock(), _createBlock(_component_NoAllowance, {
                      key: 1,
                      impacts: _ctx.impacts,
                      "one-time-impacts": _ctx.oneTimeImpacts,
                      "funding-type": _ctx.fundingType
                    }, null, 8, ["impacts", "one-time-impacts", "funding-type"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_loading, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}