import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-542cd1e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setting-card-text-wrapper" }
const _hoisted_2 = { class: "setting-card-title-wrapper" }
const _hoisted_3 = { class: "checkmark" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = {
  key: 0,
  class: "counter-text"
}
const _hoisted_6 = { class: "card-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['setting-card', { 'is-active': _ctx.isActive }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_icon, { class: "checkmark-icon" }, {
            default: _withCtx(() => [
              _createTextVNode(" mdi-check ")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("p", _hoisted_4, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]),
        (_ctx.activeAmount)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.tc('active', _ctx.activeAmount)), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", _hoisted_6, [
        _renderSlot(_ctx.$slots, "desc", {}, undefined, true)
      ])
    ]),
    _renderSlot(_ctx.$slots, "button", {}, () => [
      _createVNode(_component_gs_button, {
        type: _ctx.isActive ? 'secondary' : 'primary',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('button-click')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
        ]),
        _: 1
      }, 8, ["type"])
    ], true)
  ], 2))
}