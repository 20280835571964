<template>
  <div class="preview-wrapper">
    <p>{{ t('text') }}</p>

    <div class="preview">
      <div class="email">
        <div class="subject-wrapper">
          <p class="preview-subject">
            {{ resolveVariables(settings.subject) }}
          </p>
          <div class="subject-icon-wrapper">
            <div class="icons-left">
              <v-icon class="email-icon"> mdi-label-variant-outline </v-icon>
            </div>
            <div class="icons-right">
              <v-icon class="email-icon"> mdi-printer </v-icon>
              <v-icon class="email-icon"> mdi-open-in-new </v-icon>
            </div>
          </div>
        </div>
        <p class="preview-pre-header">
          {{ resolveVariables(settings.preheader) }}
        </p>

        <div class="address-wrapper">
          <div class="account-wrapper">
            <div class="account-icon-wrapper">
              <v-icon class="email-icon"> mdi-account </v-icon>
            </div>
            <div class="sender-details">
              <div class="email-alias-wrapper">
                <p class="preview-alias">
                  <!--eslint-disable-next-line vue/no-parsing-error-->
                  {{ resolveVariables(settings.alias) }}
                  {{
                    !emailName || !emailDomain
                      ? `< ${defaultEmailAddress}>`
                      : `<
                      ${emailName}@${emailDomain}>`
                  }}
                </p>
              </div>
              <p class="address-flavor">to me</p>
            </div>
          </div>
          <div class="address-details-wrapper">
            <p class="address-detail">9:15 AM (8 hours ago)</p>
            <div class="address-details-icons">
              <v-icon class="email-icon"> mdi-star-outline </v-icon>
              <v-icon class="email-icon"> mdi-share </v-icon>
              <v-icon class="email-icon"> mdi-dots-vertical </v-icon>
            </div>
          </div>
        </div>
        <div class="email-body">
          <img class="preview-header-image mb-4" :src="content.headerImage" />
          <div
            class="preview-body"
            v-html="formatWhiteSpacesToLineBreak(resolveVariables(content.body))"
          />
          <img
            :src="require('@/assets/backgrounds/email-preview-impacts.png')"
            alt=""
            class="footer-image mb-4"
          />
          <img :src="buttonURL" alt="" class="footer-image" />
          <p v-if="!content.isTotalImpactHidden" class="preview-impact">
            <img
              :src="require('@/assets/backgrounds/email-preview-impacts-2.png')"
              alt=""
              class="footer-image"
            />
          </p>
          <p v-if="!content.isFooterHidden" class="preview-impact">
            <img
              :src="require('@/assets/backgrounds/email-preview-footer-1.png')"
              alt=""
              class="footer-image"
            />
            <img
              :src="require('@/assets/backgrounds/email-preview-footer-5.png')"
              alt=""
              class="footer-image"
            />
            <img
              :src="require('@/assets/backgrounds/email-preview-footer-2.png')"
              alt=""
              class="footer-image"
            />
            <img :src="buttonURL" alt="" class="footer-image" />
          </p>

          <p class="preview-footer">
            <img
              v-if="settings.from.domain === defaultEmailDomain"
              :src="require('@/assets/backgrounds/email-preview-footer-3.png')"
              alt=""
              class="footer-image"
            />
            <img
              v-else
              :src="require('@/assets/backgrounds/email-preview-footer-4.png')"
              alt=""
              class="footer-image"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type {
  EmailTemplateSetting,
  EmailTemplateContent,
  TemplateType,
} from '@/store/customer-engagement'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { Account } from '@/store'
import { GREENSPARK_EMAIL_ADDRESS, GREENSPARK_EMAIL_DOMAIN } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EmailPreview',
  mixins: [Utils],
  data() {
    return {
      emailTemplateVariables: [],
      resolveValuesForEmailTemplateVariables: [
        'order',
        'John',
        'Doe',
        'plant 3 trees, rescue 7 plastic bottles and offset 5 kgs of CO2',
        'planted 3 trees, rescued 7 plastic bottles and offset 5 kgs of CO2',
      ],
    } as {
      emailTemplateVariables: string[]
      resolveValuesForEmailTemplateVariables: string[]
    }
  },
  computed: {
    defaultEmailAddress() {
      return GREENSPARK_EMAIL_ADDRESS
    },
    defaultEmailDomain() {
      return GREENSPARK_EMAIL_DOMAIN
    },
    buttonURL() {
      switch (this.templateType) {
        case 'invitation':
          return require('@/assets/backgrounds/activate-button.png')
        case 'update':
          return require('@/assets/backgrounds/login-button.png')
        case 'nudge':
          return require('@/assets/backgrounds/claim-button.png')
        default:
          return require('@/assets/backgrounds/login-button.png')
      }
    },
    defaultVariables(): Record<string, string> {
      return this.$store.getters['getCustomerEngagementDefaultVariables']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
  created() {
    this.emailTemplateVariables = [...Object.keys(this.defaultVariables), 'sellerName']
    this.resolveValuesForEmailTemplateVariables.push(this.account.companyName)
  },
  methods: {
    t(key: string) {
      return this.$t(`EmailPreview.${key}`)
    },
    resolveVariables(string: string) {
      return this.replaceValuesInTripleCurlyBrackets(
        string,
        this.emailTemplateVariables,
        this.resolveValuesForEmailTemplateVariables,
      )
    },
  },
  props: {
    settings: {
      type: Object as PropType<EmailTemplateSetting>,
    },
    content: {
      type: Object as PropType<EmailTemplateContent>,
    },
    templateType: {
      type: String as PropType<TemplateType>,
    },
    emailName: {
      type: String,
    },
    emailDomain: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.preview {
  background-color: var(--ui-black);
  border-radius: 20px;
  border: 15px solid var(--ui-black);
  position: relative;
}

.preview::before {
  border-top: 22px solid var(--ui-black);
  border-bottom: 22px solid var(--ui-black);
  content: '';
  display: block;
  height: 52px;
  width: 2px;
  left: calc(100% + 15px);
  top: 15px;
  position: absolute;
}

.preview::after {
  content: '';
  display: block;
  background-color: var(--ui-black);
  height: 2px;
  width: 22px;
  bottom: calc(100% + 15px);
  right: 20px;
  position: absolute;
}

.email {
  background: white;
  height: 600px;
  overflow: hidden;
  overflow-y: auto;
  padding: 4px;
}

.preview-header-image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 2;
}

.subject-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 40px;
  margin-bottom: 20px;
}

.preview-subject {
  width: 65%;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  overflow: hidden;
  max-width: 20ch;
  text-overflow: ellipsis;
}

.subject-icon-wrapper {
  display: flex;
  width: 35%;
  justify-content: space-between;
}

.icons-left {
  display: flex;
}

.icons-right {
  display: flex;
}

.preview-pre-header {
  padding-left: 40px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 20px;
  max-width: 46ch;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #00000070;
}

.email-icon {
  color: #00000050;
  font-size: 18px;
}

.account-wrapper {
  display: flex;
  margin-bottom: 10px;
}

.email-alias-wrapper {
  display: flex;
}

.account-icon-wrapper {
  height: 28px;
  width: 28px;
  background-color: #00000015;
  color: #00000030;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.preview-email-address {
  margin-bottom: 0;
  font-size: 12px;
}

.preview-alias {
  margin-bottom: 0;
  margin-right: 4px;
  font-size: 12px;
  max-width: 42ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.address-flavor {
  margin-bottom: 0;
  font-size: 12px;
}

.address-details-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 40px;
  margin-bottom: 15px;
}

.address-detail {
  font-size: 12px;
  margin-bottom: 0;
}

.address-wrapper {
  width: 100%;
}

.email-body {
  padding: 0 20px 0 40px;
  overflow: hidden;
  font-size: 14px;
}

.preview-body {
  margin-bottom: 15px;
  max-width: 35ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer-image {
  width: 100%;
  object-fit: contain;
}
</style>
