<template>
  <div class="carts-wrapper">
    <div class="outer-totals-wrapper">
      <div v-if="showLeftArrow" class="chevron-left">
        <img
          style="
            min-width: 20px;
            filter: invert(90%) sepia(1%) saturate(7437%) hue-rotate(194deg) brightness(116%)
              contrast(97%);
          "
          class="chevron-icon"
          :src="require('@/assets/icons/chevron_left.svg')"
          alt="left"
        />
      </div>
      <div id="totals" class="totals-cart-wrapper">
        <!-- standard -->
        <div v-if="addStandard" class="text-number">
          <div class="number">{{ Math.sign(addStandard) === 1 ? '+' : '' }} {{ addStandard }}</div>
          <div class="text">
            {{ t('standard') }}
          </div>
        </div>

        <div v-if="addStandard" class="divider-wrapper">
          <div class="line" />
        </div>

        <!-- superior -->
        <div v-if="addSuperior" class="text-number">
          <div class="number">{{ Math.sign(addSuperior) === 1 ? '+' : '' }} {{ addSuperior }}</div>
          <div class="text">
            {{ t('superior') }}
          </div>
        </div>

        <div v-if="addSuperior" class="divider-wrapper">
          <div class="line" />
        </div>

        <!-- premium -->
        <div v-if="addPremium" class="text-number">
          <div class="number">{{ Math.sign(addPremium) === 1 ? '+' : '' }} {{ addPremium }}</div>
          <div class="text">
            {{ t('premium') }}
          </div>
        </div>

        <div v-if="addPremium" class="divider-wrapper">
          <div class="line" />
        </div>

        <!-- Tree Planter -->
        <div v-if="addTrees" class="text-number">
          <div class="number">{{ Math.sign(addTrees) === 1 ? '+' : '' }} {{ addTrees }}</div>
          <div class="text">
            {{ t('tree_planter') }}
          </div>
        </div>

        <div v-if="addTrees" class="divider-wrapper">
          <div class="line" />
        </div>

        <!-- Plastic Hero -->
        <div v-if="addPlastic" class="text-number">
          <div class="number">{{ Math.sign(addPlastic) === 1 ? '+' : '' }} {{ addPlastic }}</div>
          <div class="text">
            {{ t('plastic_hero') }}
          </div>
        </div>

        <div v-if="addPlastic" class="divider-wrapper">
          <div class="line" />
        </div>
      </div>
      <div v-if="showRightArrow" class="chevron-cart">
        <img
          style="
            width: 20px;
            filter: invert(90%) sepia(1%) saturate(7437%) hue-rotate(194deg) brightness(116%)
              contrast(97%);
          "
          class="chevron-icon"
          :src="require('@/assets/icons/chevron_right.svg')"
          alt="right"
        />
      </div>
    </div>

    <div class="button-wrapper">
      <v-btn
        class="button"
        color="#3B755F"
        height="54"
        variant="flat"
        :disabled="loading"
        style="width: 100%; height: 100%; border-radius: 0"
        @click="cartClick()"
      >
        <div v-if="!loading" class="button-text">
          <div v-if="account.currency === 'euro'">
            {{ currencyEuro(totalCost ? totalCost : 0) }}
          </div>
          <div v-if="account.currency === 'pound'">
            {{ currencyPound(totalCost ? totalCost : 0) }}
          </div>
          <div v-if="account.currency === 'dollar'">
            {{ currencyDollar(totalCost ? totalCost : 0) }}
          </div>
          <div class="confirm">
            {{ t('confirm') }}
          </div>
        </div>
        <v-progress-circular v-if="loading" indeterminate color="#F9F9F9" />
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import type { Subscription } from '@api/index'
import { getSubscriptions } from '@api/index'
import { Decimal } from 'decimal.js'
import type { OffsetType } from '@/helpers/interfaces'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Cart',
  emits: ['noSubscriptionClick', 'updateSubscriptionClick'],
  data() {
    return {
      standardPrice: 0,
      superiorPrice: 0,
      premiumPrice: 0,
      plasticHeroPrice: 0,
      totalCost: 0,
      showRightArrow: false,
      showLeftArrow: false,
      hasSubscription: false,
    }
  },
  created() {
    this.setUpdatedTotals()
    this.checkScroll()
  },
  async mounted() {
    this.checkScroll()
    const subscriptions = await getSubscriptions()
    this.hasSubscription = this.hasPaymentDetails(subscriptions)
  },
  methods: {
    t(key: string) {
      return this.$t(`Cart.${key}`)
    },
    async cartClick() {
      if (!this.hasSubscription) {
        this.$emit('noSubscriptionClick')
      } else {
        this.$emit('updateSubscriptionClick')
      }
    },
    checkScroll() {
      const div = document.getElementById('totals')
      if (div && div.scrollWidth > div.clientWidth) {
        this.showRightArrow = true

        const maxScrollLeft = div.scrollWidth - div.clientWidth
        div.onscroll = () => {
          this.showRightArrow = div.scrollLeft < maxScrollLeft - 30
          this.showLeftArrow = div.scrollLeft > 0
        }
      } else {
        this.showRightArrow = false
      }
    },
    setUpdatedTotals() {
      this.setPrices()
      this.setTotalCost()
    },
    setPrices() {
      if (this.account?.currency === 'euro') {
        this.standardPrice = 5.5
        this.superiorPrice = 11.0
        this.premiumPrice = 20.0
        this.plasticHeroPrice = 3.5
      } else if (this.account?.currency === 'pound') {
        this.standardPrice = 4.8
        this.superiorPrice = 9.6
        this.premiumPrice = 18.0
        this.plasticHeroPrice = 3.9
      } else {
        this.standardPrice = 6.25
        this.superiorPrice = 12.5
        this.premiumPrice = 24.0
        this.plasticHeroPrice = 3.95
      }
    },
    setTotalCost() {
      this.totalCost = 0
      const standardTotal = new Decimal(this.addStandard || 0).times(this.standardPrice)
      const superiorTotal = new Decimal(this.addSuperior || 0).times(this.superiorPrice)
      const premiumTotal = new Decimal(this.addPremium || 0).times(this.premiumPrice)
      const plasticTotal = new Decimal(this.addPlastic || 0).times(this.plasticHeroPrice)
      const treeTotal = new Decimal(this.addTrees || 0).times(
        this.getDefaultProjectPriceByType('trees'),
      )

      const totalPlanCost = standardTotal.plus(superiorTotal).plus(premiumTotal)
      const totalUpgradeCost = plasticTotal.plus(treeTotal)

      this.totalCost = totalPlanCost.plus(totalUpgradeCost).toNumber()
      if (isNaN(this.totalCost)) {
        this.totalCost = 0
      }
    },
    onWindowWidthChange() {
      this.checkScroll()
    },
    onStandardPlanChange() {
      this.setUpdatedTotals()
      this.checkScroll()
    },
    onSuperiorPlanChange() {
      this.setUpdatedTotals()
      this.checkScroll()
    },
    onPremiumPlanChange() {
      this.setUpdatedTotals()
      this.checkScroll()
    },
    onTreesChange() {
      this.setUpdatedTotals()
      this.checkScroll()
    },
    onPlasticPlanChange() {
      this.setUpdatedTotals()
      this.checkScroll()
    },
  },
  props: {
    addStandard: { default: 0, type: Number },
    addSuperior: { default: 0, type: Number },
    addPremium: { default: 0, type: Number },
    addTrees: { default: 0, type: Number },
    addPlastic: { default: 0, type: Number },
    totalEmployees: { default: 0, type: Number },
    account: {
      type: Object,
    },
    windowWidth: {
      type: Number,
    },
    loading: { default: false, type: Boolean, required: true },
  },
  watch: {
    windowWidth: [
      {
        handler: 'onWindowWidthChange',
      },
    ],
    addStandard: [
      {
        handler: 'onStandardPlanChange',
      },
    ],
    addSuperior: [
      {
        handler: 'onSuperiorPlanChange',
      },
    ],
    addPremium: [
      {
        handler: 'onPremiumPlanChange',
      },
    ],
    addTrees: [
      {
        handler: 'onTreesChange',
      },
    ],
    addPlastic: [
      {
        handler: 'onPlasticPlanChange',
      },
    ],
  },
  computed: {
    hasPaymentDetails(): (subscriptions: Subscription[]) => boolean {
      return this.$store.getters['hasPaymentDetails']
    },
    getDefaultProjectPriceByType(): (type: OffsetType) => number {
      return this.$store.getters['getDefaultProjectPriceByType']
    },
  },
})
</script>

<style lang="scss" scoped>
.carts-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.outer-totals-wrapper {
  overflow: auto;
  display: flex;
  width: 100%;
}

.totals-cart-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.text-number {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // color: #212121;
  color: #f9f9f9;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.number {
  font-size: 20px;
}

.line {
  min-height: 100px;
  background: #f9f9f9;
  min-width: 2px;
  opacity: 0.8;
}

.button-wrapper {
  min-width: 212px;
}

.button-text {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 15px;
}

.confirm {
  margin-top: 4px;
}

.chevron-cart {
  display: flex;
  background: #cfcccc;
  align-items: center;
  -webkit-box-shadow: -2px 0px 10px 1px rgba(0, 0, 0, 0.04);
  box-shadow: -2px 0px 10px 1px rgba(0, 0, 0, 0.04);
}

.chevron-icon {
  filter: invert(39%) sepia(15%) saturate(1175%) hue-rotate(106deg) brightness(96%) contrast(87%);
}

.chevron-left {
  display: flex;
  background: #cfcccc;
  align-items: center;
  -webkit-box-shadow: 2px 0 10px 1px rgba(0, 0, 0, 0.02);
  box-shadow: 2px 0 10px 1px rgba(0, 0, 0, 0.02);
}

@media screen and (max-width: 970px) {
  .totals-cart-wrapper {
    overflow: scroll;
  }

  .button-wrapper {
    min-width: 150px;
  }

  .number {
    font-size: 18px;
  }

  .text-number {
    font-size: 14px;
    padding-left: 6px;
    padding-right: 6px;
    min-width: 100px;
  }
}
</style>
