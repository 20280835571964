import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7638ade4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "maintenance-view" }
const _hoisted_2 = { class: "maintenance-content-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "maintenance-title" }
const _hoisted_5 = { class: "maintenance-text" }
const _hoisted_6 = {
  href: "https://www.getgreenspark.com/",
  class: "homepage-link"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/greenspark-logo.svg'),
        alt: "greenspark logo",
        class: "greenspark-logo"
      }, null, 8, _hoisted_3),
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.t('title')), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('text', { date: new Date(_ctx.$route.query.maintenanceUntil).toLocaleString(_ctx.userLocale) })), 1),
      _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.t('home_link_text')), 1),
      _createElementVNode("button", {
        class: "contact-link animated-link",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openChat()))
      }, _toDisplayString(_ctx.t('contact_link_text')), 1)
    ])
  ]))
}