<template>
  <section>
    <automations-table v-if="hasAnyAutomation" @automationSelected="openEditPopup" />
    <div v-else class="no-automations">
      <div class="text-wrapper">
        <p class="no-automations-text">
          {{ t('no_automations_text') }}
        </p>
      </div>
      <gs-button
        size="large"
        width="200"
        @click="$router.push({ name: 'AddIntegrationsAndAutomations' })"
      >
        {{ t('add_automation') }}
      </gs-button>
    </div>
    <full-width-popup ref="popup" :popup-title="t('edit_popup_title')">
      <div class="card">
        <p class="edit-automation-description">
          {{ t('edit_automation_description') }}
        </p>
        <edit-automation :selected-automation-id="selectedAutomationId" />
      </div>
    </full-width-popup>
  </section>
</template>

<script lang="ts">
import AutomationsTable from '@/components/integration/AutomationsTable.vue'
import FullWidthPopup from '@/components/common/FullWidthPopup.vue'
import EditAutomation from '@/components/integration/EditAutomation.vue'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'AutomationsView',
  components: {
    EditAutomation,
    FullWidthPopup,
    AutomationsTable,
  },
  data() {
    return {
      selectedAutomationId: '',
    }
  },
  async created() {
    await this.setCustomIntegrationList()
  },
  methods: {
    t(key: string): TranslateResult {
      return this.$t(`AutomationsView.${key}`)
    },
    openEditPopup(automationId: string): void {
      const popup = this.$refs.popup as HTMLElement & { isOpen: boolean }
      this.selectedAutomationId = automationId
      this.$nextTick(() => {
        popup.isOpen = true
      })
    },
    setCustomIntegrationList(): Promise<void> {
      return this.$store.dispatch('setCustomIntegrationList')
    },
  },
  computed: {
    hasAnyAutomation(): boolean {
      return this.$store.getters['hasAnyAutomation']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.card {
  padding: 15px 5px;
  background: var(--ui-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  max-width: 1200px;
  margin: 0 auto 20px;
}

.no-automations {
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.text-wrapper {
  align-items: center;
  display: flex;
  max-width: 36ch;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.text-wrapper::before,
.text-wrapper::after {
  content: '';
  display: block;
  flex-shrink: 0;
  background: url('../../assets/reath-left.svg') no-repeat center/cover;
  height: 75px;
  width: 75px;
}

.text-wrapper::after {
  background-image: url('../../assets/reath-right.svg');
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .card {
    padding: 30px;
    margin-bottom: 30px;
  }

  .card.card--with-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .edit-automation-description {
    font-size: 18px;
    line-height: 24px;
    max-width: 40ch;
    margin-bottom: 20px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
