import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc585bf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "copy-apy-key" }
const _hoisted_2 = { class: "section" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "help-text" }
const _hoisted_5 = { class: "input-wrapper" }
const _hoisted_6 = { class: "alert-text" }
const _hoisted_7 = { class: "section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t(`copy_api_key_label_${_ctx.customIntegrationType}`)), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t(`copy_api_key_help_${_ctx.customIntegrationType}`)), 1),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_v_text_field, {
          class: "apy-key-input",
          readonly: "",
          disabled: "",
          "hide-details": "",
          flat: "",
          color: "green",
          "model-value": _ctx.apiKey,
          variant: "outlined"
        }, null, 8, ["model-value"]),
        _createElementVNode("button", {
          class: "apy-key-button",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)), ["prevent"]))
        }, [
          _createVNode(_component_v_icon, { class: "copy-icon" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isCopied ? 'mdi-check' : 'mdi-content-copy'), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t(`alert_${_ctx.customIntegrationType}`)), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_gs_button, {
        "full-width": "",
        size: "large",
        loading: _ctx.loading,
        disabled: _ctx.loading || !_ctx.wasCopiedAtLeastOnce,
        onClick: _ctx.done
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('done')), 1)
        ]),
        _: 1
      }, 8, ["loading", "disabled", "onClick"])
    ])
  ]))
}