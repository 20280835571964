import type { CustomIntegrationResponse } from '@api/index'

export const INTEGRATOR_TYPES = ['zapier', 'integrately'] as const
export type IntegratorType = (typeof INTEGRATOR_TYPES)[number]

export interface Integrator extends CustomIntegrationResponse {
  type: IntegratorType
}

export type AvailableIntegrator = Pick<Integrator, 'source' | 'imageUrl' | 'type'> & {
  id?: string
  active: boolean
}

export const AVAILABLE_INTEGRATORS: AvailableIntegrator[] = [
  {
    id: '',
    source: 'Zapier',
    imageUrl: require('@/assets/icons/zapier.svg'),
    type: 'zapier',
    active: false,
  },
  {
    id: '',
    source: 'Integrately',
    imageUrl: require('@/assets/icons/integrately_simplified.svg'),
    type: 'integrately',
    active: false,
  },
]
