import type { TranslateResult } from 'vue-i18n'
import type { AccountImpact, Rutter, TransferredImpact } from '@/store'
export const STORE_TYPES = [
  'shopify',
  'woocommerce',
  'magento',
  'squarespace',
  'prestashop',
  'amazon',
  'square',
  'ebay',
  'etsy',
] as const
export type StoreType = (typeof STORE_TYPES)[number]
export const STORE_INTEGRATION_TRIGGERS = [
  'offsetPerOrder',
  'offsetPerProduct',
  'offsetBySpend',
  'offsetByStoreRevenue',
  'offsetByPercentageOrder',
] as const
export const BASIC_STORE_INTEGRATION_TRIGGERS = ['offsetPerOrder'] as const
export type StoreIntegrationTrigger = (typeof STORE_INTEGRATION_TRIGGERS)[number]
export const EMAIL_TRIGGER_TYPES = ['perSubscriber', 'perSmsSubscriber'] as const
export type EmailTriggerType = (typeof EMAIL_TRIGGER_TYPES)[number]
export const FEEDBACK_TRIGGER_TYPES = ['perProductReview', 'perCompanyReview'] as const
export type FeedbackTriggerType = (typeof FEEDBACK_TRIGGER_TYPES)[number]
export const FORM_TRIGGER_TYPES = ['perCompletedForm', 'perSelectedAnswer'] as const
export type FormTriggerType = (typeof FORM_TRIGGER_TYPES)[number]
export const BILLING_TRIGGER_TYPES = [
  'perPaidInvoice',
  'byPercentageOfPaidInvoice',
  'bySpendOfPaidInvoice',
  'byTieredSpendOfPaidInvoice',
] as const
export type BillingTriggerType = (typeof BILLING_TRIGGER_TYPES)[number]
export const INTEGRATION_TRIGGER_TYPES = [
  ...EMAIL_TRIGGER_TYPES,
  ...FEEDBACK_TRIGGER_TYPES,
  ...FORM_TRIGGER_TYPES,
  ...BILLING_TRIGGER_TYPES,
] as const
export type IntegrationTriggerType = (typeof INTEGRATION_TRIGGER_TYPES)[number]
export const BASIC_TRIGGER_TYPES = [
  ...BASIC_STORE_INTEGRATION_TRIGGERS,
  ...INTEGRATION_TRIGGER_TYPES,
] as const
export type BasicTriggerType = (typeof BASIC_TRIGGER_TYPES)[number]
export const EMAIL_INTEGRATION_SOURCES = [
  'mailchimp',
  'activecampaign',
  'klaviyo',
  'mailshake',
  'sendinblue',
  'mailgun',
  'campaign-monitor',
  'omnisend',
] as const
export type EmailIntegrationSource = (typeof EMAIL_INTEGRATION_SOURCES)[number]
export const FEEDBACK_INTEGRATION_SOURCES = ['reviewsio', 'judgeme', 'yotpo', 'stamped'] as const
export type FeedbackIntegrationSource = (typeof FEEDBACK_INTEGRATION_SOURCES)[number]
export const BILLING_INTEGRATION_SOURCES = ['stripe'] as const
export const INTEGRATION_SOURCES = [...EMAIL_INTEGRATION_SOURCES, ...FEEDBACK_INTEGRATION_SOURCES]
export type IntegrationSource = EmailIntegrationSource | FeedbackIntegrationSource
export const CUSTOM_SOURCES = ['custom'] as const
export type CustomSource = (typeof CUSTOM_SOURCES)[number]
export const INTEGRATOR_SOURCES = ['zapier', 'integrately'] as const
export type IntegratorSource = (typeof INTEGRATOR_SOURCES)[number]
export const SHOPIFY_ORDER_ORIGINS = ['all', 'web', 'pos'] as const
export type ShopifyOrderOrigin = (typeof SHOPIFY_ORDER_ORIGINS)[number]

export type BrandColor = 'beige' | 'green' | 'blue' | 'black' | 'white'
export type Currency = 'dollar' | 'euro' | 'pound'
export type CurrencyCode = 'USD' | 'EUR' | 'GBP' | 'HKD' | 'CAD' | 'AUD'
export const BRAND_COLORS: BrandColor[] = ['beige', 'green', 'blue', 'black', 'white']
export const ICON_STYLES = ['simple', 'flat', 'isometric'] as const
export type IconStyleType = (typeof ICON_STYLES)[number]
export const OFFSET_TYPES = ['trees', 'carbon', 'plastic', 'kelp'] as const
export const SUBSCRIPTION_OFFSET_TYPES = ['carbon', 'plastic', 'trees'] as const
export type OffsetType = (typeof OFFSET_TYPES)[number]

export type ComparisonValue = { value: number; suffix?: string; link: string }
const CARBON_COMPARISON_UNITS = ['flights', 'distance', 'plantBased'] as const
export type CarbonComparisonUnit = (typeof CARBON_COMPARISON_UNITS)[number]
const PLASTIC_COMPARISON_UNITS = ['straws', 'coffeeCups', 'bathtubs'] as const
export type PlasticComparisonUnit = (typeof PLASTIC_COMPARISON_UNITS)[number]
const TREE_COMPARISON_UNITS = ['areaPlanted', 'carbonSequestered', 'footballPitches'] as const
export type TreeComparisonUnit = (typeof TREE_COMPARISON_UNITS)[number]
const KELP_COMPARISON_UNITS = ['iceHockeyRinks', 'carbonSequestered', 'babyWhales'] as const
export type KelpComparisonUnit = (typeof KELP_COMPARISON_UNITS)[number]
export type ComparisonType =
  | CarbonComparisonUnit
  | PlasticComparisonUnit
  | TreeComparisonUnit
  | KelpComparisonUnit

export type CarbonComparison = Record<CarbonComparisonUnit, ComparisonValue>
export type PlasticComparison = Record<PlasticComparisonUnit, ComparisonValue>
export type KelpComparison = Record<KelpComparisonUnit, ComparisonValue>
export type TreeComparison = Record<TreeComparisonUnit, ComparisonValue>
export type Comparison = TreeComparison | CarbonComparison | PlasticComparison | KelpComparison

export const TopStatDataKeys = [...OFFSET_TYPES, 'monthsEarthPositive'] as const
export type TopStatDataKey = (typeof TopStatDataKeys)[number]
export const GREENSPARK_EMAIL_DOMAIN = 'get-greenspark.com'
export const GREENSPARK_EMAIL_ADDRESS = `hello@${GREENSPARK_EMAIL_DOMAIN}`

export interface Impact {
  type: Emptyable<OffsetType>
  amount: number
}

export type Emptyable<Type> = Type | ''
export type Clearable<Type> = Type | null
export type ImpactActionType =
  | 'ORDER_CREATED'
  | 'PER_COMPANY_REVIEW'
  | 'PER_PRODUCT_REVIEW'
  | 'PER_SUBSCRIBER'
  | 'ONE_OFF'
  | 'SUBSCRIPTION'
  | 'REFERRAL'
  | 'INVOICE'
  | 'GIFT'
export type LineItem = { impacts: Impact[]; image: string; title: string }
export type Action = { impacts: Impact[]; createdAt: string; lineItems?: LineItem[] }
export type ImpactAction = {
  _id: string
  type: ImpactActionType
  action: Action
  imageURL: string
  sellerUserId: string
  sellerUsername: string
}
export type ImpactByBrand = {
  companyName: string
  imageURL: string
  impacts: Impact[]
  userId: string
  profileDescription: string
  url: string
}
export type PlanName = 'E-Commerce' | 'Plastic Hero' | 'Earth Hero' | 'Personal'

export interface Customer {
  orderId: string
  email: string
  firstName: string
  lastName: string
  impacts: Impact[]
  sellerCompanyName: string
  impactActionType: ImpactActionType | ''
}

export type TotalImpact = { type: OffsetType; amount: number }
export type AccountType = 'ecommerce' | 'business' | 'personal' | 'family' | 'admin'
export type SustainabilityGoal = { targetId?: string; title: string; description: string }

export interface ResponseTarget extends SustainabilityGoal {
  _id: string
  _v: number
  createdAt: Date
  updatedAt: Date
  userId: string
}

export type FeedItemType =
  | 'CAMPAIGN'
  | 'COMPANY_UPDATE'
  | 'IMPACT_FACT'
  | 'IMPACT_TARGET'
  | 'ORDER_CREATED'
  | 'PROJECT_DESCRIPTION'
  | 'PER_COMPANY_REVIEW'
  | 'PER_PRODUCT_REVIEW'
  | 'PER_SUBSCRIBER'
  | 'WHY_WE_JOINED'
  | 'ONE_OFF'
  | 'SUBSCRIPTION'
  | 'REFERRAL'
  | 'INVOICE'

export interface FeedItemsResponse {
  data: FeedItem[]
  hasMore: boolean
}

export interface FeedItem {
  _id: string
  createdAt: string
  description: string
  image: string
  videoURL?: string
  link: { label: string; url: string }
  title: string
  type: FeedItemType
  byImpactType?: OffsetType | 'all'
}

export interface MenuItem {
  active: boolean
  value: string
  label: string | TranslateResult
}

export interface SelectOption {
  value: string | number
  text: string | TranslateResult
}

export type DashboardType = 'general' | 'automations' | 'subscriptionAndTailoredImpact' | 'livePage'

export interface RutterStore extends Rutter {
  accessToken: string
}

export interface Heap {
  track: (event: string, properties?: Record<string, string>) => void
  identify: (identity: string) => void
  resetIdentity: () => void
  addUserProperties: (
    properties: Record<string, number | null | string | AccountImpact[] | TransferredImpact[]>,
  ) => void
  addEventProperties: (properties: Record<string, string>) => void
  removeEventProperty: (property: string) => void
  clearEventProperties: () => void
  appid: string
  userId: string
  identity: string | null
}

export interface LivePageData {
  companyName: string
  website: string
  name: string
  signupDate: Date
  impacts: AccountImpact[]
  temporaryUpgrades: TotalImpact[]
  transferredImpact: TransferredImpact[]
  relationImpact: TotalImpact[]
  impactActionImpacts: TotalImpact[]
  profileDescription: string
  imageURL: string
  accountType: AccountType
}

export interface CookieConfig {
  name: string
  value: string | undefined
  days: number
  domain?: string
  httpOnly?: boolean
  secure?: boolean
}
