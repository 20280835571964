import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc7067ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "automations-table" }
const _hoisted_2 = { class: "icon-wrapper" }
const _hoisted_3 = { class: "mb-0 tooltip-text" }
const _hoisted_4 = {
  key: 0,
  class: "impact-pill-wrapper"
}
const _hoisted_5 = {
  key: 1,
  class: "mb-0 perproduct-pill"
}
const _hoisted_6 = {
  key: 2,
  class: "impact-pill-wrapper"
}
const _hoisted_7 = { class: "mb-0" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "drawer" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "table-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_impact_pill = _resolveComponent("impact-pill")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_data_table, {
      items: _ctx.getAutomationsDataForTableWithAccumulatedOffsets,
      headers: _ctx.headers,
      page: _ctx.page,
      "onUpdate:page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
      "hide-default-footer": "",
      "row-props": { class: _ctx.rowClass },
      "items-per-page": _ctx.itemsPerPage,
      height: "423"
    }, {
      [`item.image`]: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_icon, { class: "trigger-icon" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getAutomationIcon(item.trigger)), 1)
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      [`item.schedule`]: _withCtx(({ item }) => [
        _createVNode(_component_v_tooltip, {
          "open-on-click": _ctx.isTouch,
          color: "black",
          location: "top"
        }, {
          activator: _withCtx(({ props }) => [
            _createElementVNode("p", _mergeProps({ class: "mb-0" }, props), [
              _createTextVNode(_toDisplayString(item.scheduleLabel) + " ", 1),
              (item.status === 'active' && !item.schedule.endDate)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    class: "ongoing-icon"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-all-inclusive ")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], 16)
          ]),
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_3, [
              _createTextVNode(_toDisplayString(item.scheduleLabelUTC) + " ", 1),
              (item.status === 'active' && !item.schedule.endDate)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    class: "ongoing-icon tooltip"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-all-inclusive ")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 2
        }, 1032, ["open-on-click"])
      ]),
      [`item.offsets`]: _withCtx(({ item }) => [
        (item.offsets.length && item.trigger !== 'offsetByPercentageOrder')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.offsets, (offset, index) => {
                return (_openBlock(), _createBlock(_component_impact_pill, {
                  key: index,
                  impact: { type: offset.type, amount: offset.amount },
                  theme: "filled"
                }, null, 8, ["impact"]))
              }), 128))
            ]))
          : (
            item.platform === 'custom' ||
            item.platform === 'zapier' ||
            item.platform === 'integrately'
          )
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t('custom')), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_impact_pill, {
                  impact: { type: item.offsets[0].type, amount: 0 },
                  theme: "filled"
                }, null, 8, ["impact"])
              ]))
      ]),
      [`item.platform`]: _withCtx(({ item }) => [
        _createVNode(_component_v_tooltip, {
          "open-on-click": _ctx.isTouch,
          color: "black",
          location: "top"
        }, {
          activator: _withCtx(({ props }) => [
            _createElementVNode("p", _mergeProps({ class: "mb-0 platform-pill" }, props), _toDisplayString(_ctx.getIntegrationPlatformName(item.platform)), 17)
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(item.integrationName), 1)
          ]),
          _: 2
        }, 1032, ["open-on-click"])
      ]),
      [`item.trigger`]: _withCtx(({ item }) => [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t(`IntegrationMixin.${_ctx.snakeCase(item.trigger)}`)), 1)
      ]),
      [`item.id`]: _withCtx(({ item }) => [
        _createElementVNode("span", {
          onClick: _withModifiers(($event: any) => (_ctx.copyTextToClipboard(item.id)), ["prevent"])
        }, [
          _createTextVNode(_toDisplayString(_ctx.shortenText(item.id)) + " ", 1),
          _createVNode(_component_v_icon, {
            size: 18,
            class: "copy ml-1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isCopied ? 'mdi-check-circle' : 'mdi-content-copy'), 1)
            ]),
            _: 1
          })
        ], 8, _hoisted_8)
      ]),
      [`item.statusLabel`]: _withCtx(({ item }) => [
        _createElementVNode("span", {
          class: _normalizeClass(['status-pill', item.status])
        }, _toDisplayString(item.statusLabel), 3)
      ]),
      [`item.context`]: _withCtx(({ item }) => [
        _createVNode(_component_v_menu, null, {
          activator: _withCtx(({ props }) => [
            _createElementVNode("button", _mergeProps({ class: "context-menu-button" }, props), [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-dots-horizontal")
                ]),
                _: 1
              })
            ], 16)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                class: "drawer-button",
                onClick: ($event: any) => (_ctx.openEditAutomationDialog(item))
              }, [
                _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-pencil-outline ")
                  ]),
                  _: 1
                }),
                _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.edit')), 1)
              ], 8, _hoisted_10),
              (_ctx.showUpdate(item))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "drawer-button",
                    onClick: ($event: any) => (
                _ctx.updateAutomation({
                  automationId: item.id,
                  createAutomationRequestBody: { enabled: item.status !== 'active' },
                })
              )
                  }, [
                    _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.status === 'active' ? 'mdi-stop-circle-outline' : 'mdi-play-outline'), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createTextVNode(_toDisplayString(item.status === 'active' ? 'Deactivate' : 'Activate'), 1)
                  ], 8, _hoisted_11))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "drawer-button",
                onClick: ($event: any) => (_ctx.openDeleteAutomationDialog(item))
              }, [
                _createVNode(_component_v_icon, { class: "drawer-icon" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-delete-outline ")
                  ]),
                  _: 1
                }),
                _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.delete')), 1)
              ], 8, _hoisted_12)
            ])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, 1032, ["items", "headers", "page", "row-props", "items-per-page"]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_v_pagination, {
        "total-visible": 3,
        modelValue: _ctx.page,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event)),
        length: _ctx.pageCount
      }, null, 8, ["modelValue", "length"])
    ])
  ]))
}