<template>
  <div v-if="loaded" class="dialog-wrapper">
    <div>
      <div class="close-wrapper">
        <div class="dialog-title">
          {{ t('history') }}
        </div>

        <img
          style="width: 25px"
          class="close-icon"
          :src="require('@/assets/icons/close_icon.svg')"
          alt="close"
          @click="$emit('dialogClick')"
        />
      </div>

      <MainTable
        v-if="rowItems.length > 0"
        :menu-items="menuItems"
        :rows="rowItems"
        :column-width="columnWidth"
        :footer-items="footerItems"
        :hide-partner-image="true"
        :hide-search="true"
        :table-loading="tableLoading"
        :active-page="activePage"
        @emitSort="sort"
        @footerClick="footerClick"
        @arrowClick="arrowClick"
      />

      <ReferralEmpty v-if="rowItems.length < 1" :text="t('empty')" :text1="t('empty1')" />

      <LoadingOpaque v-if="!loaded" />
    </div>
  </div>
</template>

<script lang="ts">
import MainTable from '@/components/tools/Table/MainTable.vue'
import LoadingOpaque from '@/components/tools/LoadingOpaque.vue'
import ReferralEmpty from '@/components/referrals/ReferralEmpty.vue'
import { listPayments } from '@api/index'
import type { Account } from '@/store'
import type { TranslateResult } from 'vue-i18n'
import { defineComponent } from 'vue'

interface RowItemLabel {
  text?: string
  type: 'text' | 'impact'
  customWidth?: number
  labels?: { text: string; type: string }[]
}
interface RowItem {
  name: string
  labels: RowItemLabel[]
  status: { text: string; customWidth: number | string }
  quickMenuItems: unknown[]
}

export default defineComponent({
  name: 'History',
  emits: ['dialogClick'],
  components: {
    MainTable,
    LoadingOpaque,
    ReferralEmpty,
  },
  data() {
    const footerItems: { label: number | string; value: number; active: boolean }[] = []
    const rowItems: RowItem[] = []
    const menuItems: {
      label: TranslateResult
      value: string
      sortDirection: 'desc' | 'asc'
      sortedBy: boolean
      sortable: boolean
      active?: boolean
      customWidth?: number
    }[] = []

    return {
      loaded: false,
      menuItems,
      rowItems,
      footerItems,
      columnWidth: 0,
      payments: false,
      currencySymbol: '',
      sortDirection: 'desc',
      sortBy: 'added',
      skip: 0,
      tableLoading: false,
      itemCount: 0,
      activePage: 0,
    }
  },
  async created() {
    this.setCurrencySymbol()
    this.setMenuItems()
    await this.setRowItems()
    this.setFooterItems()
    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`History.${key}`)
    },
    setMenuItems() {
      this.menuItems = []
      this.menuItems.push(
        {
          label: this.t('partner_name'),
          value: 'name',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('paid'),
          value: 'added',
          sortDirection: 'desc',
          sortedBy: true,
          sortable: true,
          customWidth: 85,
        },
        {
          label: this.t('funding'),
          value: 'funding',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: false,
          customWidth: 143,
        },
        {
          label: this.t('funding_amount'),
          value: 'fundingAmount',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: false,
        },
        {
          label: this.t('status'),
          value: 'status',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
          customWidth: 60,
        },
      )
    },
    resetMenuItems() {
      this.menuItems = []
      this.menuItems.push(
        {
          label: this.t('partner_name'),
          value: 'name',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('paid'),
          value: 'added',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
          customWidth: 85,
        },
        {
          label: this.t('funding'),
          value: 'funding',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: false,
          customWidth: 143,
        },
        {
          label: this.t('funding_amount'),
          value: 'fundingAmount',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: false,
        },
        {
          label: this.t('status'),
          value: 'status',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
          customWidth: 60,
        },
      )
    },
    async setRowItems() {
      const {
        data: { data: payments, count },
      } = await listPayments(this.relationsId ?? '', this.sortBy, this.sortDirection, this.skip, 5)
      this.itemCount = count

      this.rowItems = payments.map((payment) => {
        const payload: RowItem = {
          name: '',
          labels: [], // object with either type: text, impact
          status: { text: '', customWidth: '60' },
          quickMenuItems: [],
        }

        // set the name
        payload.name =
          payment.userId === this.account.accountId
            ? this.account.companyName
            : this.partnerName ?? ''

        // set labels
        // paid date
        if (payment.paidAt !== '' && payment.paidAt !== null) {
          const date = new Date(payment.paidAt)
          const day = date.getDate()
          const month = date.getMonth() + 1
          const year = date.getFullYear()
          const paidDate = `${day}.${month}.${year}`
          payload.labels.push({ text: paidDate, type: 'text', customWidth: 85 })
        } else {
          payload.labels.push({ text: '', type: 'text', customWidth: 85 })
        }

        // set type & amount by source
        const labelObject: RowItemLabel = {
          type: 'impact',
          labels: [],
        }
        switch (payment.source) {
          case 'oneTimeImpact':
            payload.labels.push({
              type: 'impact',
              labels: [{ text: 'One-time impact', type: 'plan' }],
              customWidth: 143,
            })

            for (let i = 0; i < payment.impacts.length; i++) {
              labelObject.labels?.push({
                text: `${
                  payment.impacts[i].type === 'carbon'
                    ? payment.impacts[i].amount + 'kg'
                    : payment.impacts[i].amount
                } ${payment.impacts[i].type === 'carbon' ? 'CO2' : payment.impacts[i].type}`,
                type: payment.impacts[i].type,
              })
            }
            payload.labels.push(labelObject)
            break
          case 'monthlyAllowance':
            payload.labels.push({
              type: 'impact',
              labels: [{ text: 'Monthly allowance', type: 'plan' }],
              customWidth: 143,
            })

            for (let i = 0; i < payment.allowances.length; i++) {
              labelObject.labels?.push({
                text: `${this.currencySymbol}${payment.allowances[i].amount}`,
                type: 'allowance',
              })
            }
            payload.labels.push(labelObject)
            break
          case 'oneTimeAllowance':
            payload.labels.push({
              type: 'impact',
              labels: [{ text: 'One-time allowance', type: 'plan' }],
              customWidth: 143,
            })

            for (let i = 0; i < payment.allowances.length; i++) {
              labelObject.labels?.push({
                text: `${this.currencySymbol}${payment.allowances[i].amount}`,
                type: 'allowance',
              })
            }
            payload.labels.push(labelObject)
            break
          case 'gift':
            payload.labels.push({
              type: 'impact',
              labels: [{ text: 'Reward', type: 'plan' }],
              customWidth: 143,
            })

            for (let i = 0; i < payment.impacts.length; i++) {
              labelObject.labels?.push({
                text: `${
                  payment.impacts[i].type === 'carbon'
                    ? payment.impacts[i].amount + 'kg'
                    : payment.impacts[i].amount
                } ${payment.impacts[i].type === 'carbon' ? 'CO2' : payment.impacts[i].type}`,
                type: payment.impacts[i].type,
              })
            }
            payload.labels.push(labelObject)
            break
          case 'subscription':
            payload.labels.push({
              type: 'impact',
              labels: [{ text: 'Subscription', type: 'plan' }],
              customWidth: 143,
            })

            for (let i = 0; i < payment.subscriptions.length; i++) {
              let subscriptionName = ''
              switch (payment.subscriptions[i].type) {
                case 'ECOMMERCE':
                case 'ecommerce':
                  subscriptionName = 'Ecommerce'
                  break
                case 'starterBusiness':
                  subscriptionName = 'Starter'
                  break
                case 'growthBusiness':
                  subscriptionName = 'Growth'
                  break
                case 'premiumBusiness':
                  subscriptionName = 'Premium'
                  break
              }
              labelObject.labels?.push({
                text: `${payment.subscriptions[i].amount} ${subscriptionName}`,
                type: 'subscription',
              })
            }
            payload.labels.push(labelObject)
            break
          case 'monthlyImpact':
            payload.labels.push({
              type: 'impact',
              labels: [{ text: 'Monthly impact', type: 'plan' }],
              customWidth: 143,
            })

            for (let i = 0; i < payment.impacts.length; i++) {
              labelObject.labels?.push({
                text: `${
                  payment.impacts[i].type === 'carbon'
                    ? payment.impacts[i].amount + 'kg'
                    : payment.impacts[i].amount
                } ${payment.impacts[i].type === 'carbon' ? 'CO2' : payment.impacts[i].type}`,
                type: payment.impacts[i].type,
              })
            }
            payload.labels.push(labelObject)
            break
        }

        // set status
        if (payment.paidAt !== '' && payment.paidAt !== null) {
          payload.status.text = 'paid'
        } else {
          payload.status.text = 'pending'
        }
        return payload
      })
    },
    setFooterItems() {
      const totalPages = this.itemCount / 5
      this.footerItems = []

      if (totalPages > 1) {
        for (let i = 0; i < totalPages; i++) {
          if (i === this.activePage) {
            this.footerItems.push({ label: `${i + 1}`, value: i + 1, active: true })
          } else {
            this.footerItems.push({ label: `${i + 1}`, value: i + 1, active: false })
          }
        }
      } else {
        this.footerItems.push({ label: 1, value: 1, active: true })
      }
    },
    setCurrencySymbol() {
      switch (this.account.currency) {
        case 'euro':
          this.currencySymbol = '€'
          break
        case 'pound':
          this.currencySymbol = '£'
          break
        case 'dollar':
          this.currencySymbol = '$'
          break
      }
    },
    async sort(value, sortDirection, index) {
      this.tableLoading = true
      this.resetMenuItems()
      this.menuItems[index].active = !sortDirection
      this.menuItems[index].sortedBy = true
      if (sortDirection === 'asc') {
        this.menuItems[index].sortDirection = 'desc'
        this.sortDirection = 'desc'
      } else {
        this.menuItems[index].sortDirection = 'asc'
        this.sortDirection = 'asc'
      }
      this.sortBy = value
      if (this.activePage > 0) {
        this.activePage = 0
        this.skip = 0
        this.setFooterItems()
      }
      await this.setRowItems()
      this.tableLoading = false
    },
    async footerClick(value) {
      this.tableLoading = true
      this.activePage = value
      this.skip = this.activePage * 5
      this.setFooterItems()
      await this.setRowItems()
      this.tableLoading = false
    },
    async arrowClick(value) {
      const maxPages = this.itemCount / 5
      if (value === 'next' && this.activePage + 1 < maxPages) {
        this.tableLoading = true
        this.activePage++
        this.skip = this.activePage * 5
        this.setFooterItems()
        await this.setRowItems()
        this.tableLoading = false
      }

      if (value === 'back' && this.activePage > 0) {
        this.tableLoading = true
        this.activePage--
        this.skip = this.activePage * 5
        this.setFooterItems()
        await this.setRowItems()
        this.tableLoading = false
      }
    },
  },
  props: {
    relationsId: {
      type: String,
    },
    partnerName: {
      type: String,
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
})
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  background: #f9f9f9;
  padding: 25px;
  padding-bottom: 34px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.close-wrapper {
  margin-top: -10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.close-icon {
  cursor: pointer;
}

.dialog-title {
  color: #212121;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  width: 100%;
}
</style>
