import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a2904be"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "highlight-text"
}
const _hoisted_2 = { class: "plan-name" }
const _hoisted_3 = {
  key: 1,
  class: "plan-price"
}
const _hoisted_4 = { class: "currency-symbol" }
const _hoisted_5 = { class: "currency-value" }
const _hoisted_6 = { class: "currency-code" }
const _hoisted_7 = {
  key: 2,
  class: "plan-price unpriced"
}
const _hoisted_8 = {
  key: 3,
  class: "plan-billing"
}
const _hoisted_9 = { class: "plan-checks" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "button-wrapper" }
const _hoisted_12 = {
  key: 2,
  class: "trial-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(['card-wrapper', { isHighlighted: _ctx.isHighlighted }])
  }, [
    (_ctx.isHighlighted)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.t('highlight')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t(_ctx.snakeCase(_ctx.planType))), 1),
    (_ctx.theme === 'priced')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.userCurrencySymbol), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatedPriceAmount), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getUserCurrencyCode), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.theme === 'unpriced')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.t('contact_us')), 1))
      : _createCommentVNode("", true),
    (_ctx.theme === 'priced')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.planBilling), 1))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planCheckItems, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(['plan-check-item', { 'hide-checkmark': _ctx.hideCheckmark }]),
          innerHTML: item
        }, null, 10, _hoisted_10))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_11, [
      (_ctx.planType !== 'enterpriseBusiness')
        ? (_openBlock(), _createBlock(_component_gs_button, {
            key: 0,
            "full-width": "",
            size: "large",
            disabled: _ctx.buttonLoading || _ctx.isButtonDisabled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectPlan()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true),
      (_ctx.planType === 'enterpriseBusiness')
        ? (_openBlock(), _createBlock(_component_gs_button, {
            key: 1,
            type: "link",
            href: "https://www.getgreenspark.com/book-a-demo",
            "full-width": "",
            size: "large"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.showTrial)
        ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.planType !== 'enterpriseBusiness' ? _ctx.t('trial') : null), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}