<template>
  <v-dialog v-model="dialog" width="480" :fullscreen="isMobile" scrollable>
    <template v-slot:activator="{ props }">
      <v-btn class="button" color="#3B755F" height="auto" variant="text" v-bind="props">
        {{ useButtonText }}
      </v-btn>
    </template>

    <div class="pop-up">
      <div class="close-wrapper" @click="dialog = false">
        <img
          style="width: 25px"
          class="close-icon"
          :src="require('@/assets/icons/close_icon.svg')"
          alt="close"
        />
      </div>

      <div class="pop-up-content-wrapper">
        <slot />

        <template v-if="shopifySteps">
          <div class="overlay-text">
            {{ overlayText }}
          </div>

          <div class="how-it-works">
            <div class="hiw-title">
              {{ t('how_it_works') }}
            </div>
            <div class="number-explanation-wrapper">
              <div class="number">1</div>
              <div class="explanation">
                {{ t('tutorial_step_1') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">2</div>
              <div class="explanation">
                {{ t('tutorial_step_2') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">3</div>
              <div class="explanation">
                {{ t('tutorial_step_3') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">4</div>
              <div class="explanation">
                {{ t('tutorial_step_4') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">5</div>
              <div class="explanation">
                {{ t('tutorial_step_5') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">6</div>
              <div class="explanation">
                {{ t('tutorial_step_6') }}
              </div>
            </div>
          </div>

          <v-select
            v-model="shopify"
            :items="displayUrls"
            label="Shop Name"
            variant="outlined"
            color="#3B755F"
            item-color="#3B755F"
            class="dropdown dropdown-block"
            hide-details
          />

          <div class="support">
            <span>{{ t('questions') }}</span>
            <i18n-t tag="span" keypath="Badge.help">
              <a
                href="https://help.shopify.com/en/manual/online-store/themes/theme-structure/extend/apps#app-blocks"
                target="_blank"
                class="green-text"
                >{{ t('help_read') }}</a
              >
              <button class="green-text" @click="openChat()">
                {{ t('help_chat') }}
              </button>
            </i18n-t>
          </div>

          <div class="button-wrapper">
            <gs-button
              size="large"
              width="230"
              target="_self"
              :disabled="!buttonActive"
              :href="shopifyUrl"
            >
              <div class="button-text">
                {{ t('proceed_to_shopify') }}
              </div>
            </gs-button>
          </div>
        </template>
        <template v-if="!shopifySteps">
          <div class="overlay-text">
            {{ overlayText }}
          </div>

          <div class="how-it-works">
            <div class="hiw-title">
              {{ t('how_it_works') }}
            </div>
            <div class="number-explanation-wrapper">
              <div class="number">1</div>
              <div class="explanation">
                <i18n-t tag="span" keypath="Badge.api_step_1">
                  <router-link to="/account?tab=apiKey">
                    {{ t('account_link') }}
                  </router-link>
                </i18n-t>
              </div>
            </div>
            <div class="number-explanation-wrapper">
              <div class="number">2</div>
              <div class="explanation">
                <i18n-t tag="span" keypath="Badge.api_step_2">
                  <a :href="API_DOCUMENTATION_URL" target="_blank">
                    {{ t('documentation_link') }}
                  </a>
                </i18n-t>
              </div>
            </div>
            <div class="number-explanation-wrapper">
              <div class="number">3</div>
              <div class="explanation">
                {{ t('api_step_3') }}
              </div>
            </div>
          </div>
          <div class="support">
            <span>{{ t('help_more') }}</span>
            <button class="green-text" @click="openChat()">
              {{ t('help_chat') }}
            </button>
          </div>
        </template>
        <div
          v-if="isShopifyIntegrated"
          class="green-text bottom"
          @click="shopifySteps = !shopifySteps"
        >
          {{ switchButtonText }}
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script lang="ts">
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { API_DOCUMENTATION_URL, isDemoEnvironment } from '@/helpers/constants'
import type { Integration, IntegrationPlatform } from '@/store/integrations'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

export type DialogMode = 'banner' | 'product' | 'widget'

export default defineComponent({
  name: 'WidgetDialog',
  mixins: [LoadCrispMixin, Utils],
  data() {
    return {
      dialog: false,
      loaded: false,
      shopify: '',
      buttonActive: false,
      windowWidth: 0,
      shopifySteps: false,
      API_DOCUMENTATION_URL: '',
    }
  },
  computed: {
    useButtonText(): TranslateResult {
      return this.mode === 'banner' ? this.t('use_banner') : this.t('use')
    },
    overlayText(): TranslateResult {
      if (this.mode === 'banner') {
        return this.shopifySteps ? this.t('overlay_shopify_banner') : this.t('overlay_api_banner')
      } else if (this.mode === 'product') {
        return this.shopifySteps ? this.t('overlay_shopify_product') : this.t('overlay_api')
      } else {
        return this.shopifySteps ? this.t('overlay_shopify_block') : this.t('overlay_api')
      }
    },
    switchButtonText(): TranslateResult {
      return this.shopifySteps ? this.t('api_switch') : this.t('shopify_switch')
    },
    isShopifyIntegrated(): boolean {
      return isDemoEnvironment
        ? true
        : !!this.getIntegrationsByPlatform('shopify').length || this.account.userFromMarketplace
    },
    shopifyUrls(): string[] {
      return isDemoEnvironment
        ? [this.integrationList[0]?.name]
        : this.getIntegrationsByPlatform('shopify').map(({ name }) => name)
    },
    shopifyUrl(): string {
      return `https://${this.shopify}/admin/themes/current/editor`
    },
    displayUrls(): { value: string; title: string }[] {
      return this.shopifyUrls.map((item, index) => {
        return { title: this.shopifyUrls[index], value: this.shopifyUrls[index] }
      })
    },
    integrationList(): Integration[] {
      return this.$store.getters['getIntegrationList']
    },
    getIntegrationsByPlatform(): (platform: IntegrationPlatform) => Integration[] {
      return this.$store.getters['getIntegrationsByPlatform']
    },
  },
  async created() {
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
    this.API_DOCUMENTATION_URL = API_DOCUMENTATION_URL
    this.shopifySteps = this.isShopifyIntegrated
    this.loaded = true
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string) {
      return this.$t(`Badge.${key}`)
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    onShopifyChange() {
      this.buttonActive = this.shopify !== ''
    },
  },
  props: {
    mode: { type: String as () => DialogMode, default: 'widget' },
  },
  watch: {
    shopify: [
      {
        handler: 'onShopifyChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.menu-wrapper {
  align-items: flex-start;
  display: flex;
  padding: 20px 0;
  width: 100%;
}

.badge-wrapper {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 260px;
  background-color: #f9f9f9 !important;
}

.widget-image-wrapper {
  width: 100%;
  height: 100px;
  background-size: contain;
  background-position: center;
}

.widget-small {
  width: 100%;
  height: auto;
}

.button {
  margin-top: 18px;
  border-radius: 0;
  padding: 0 !important;
}

.button-text {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 12px;
}

.button-text-send {
  color: #3b755f;
  font-weight: 600;
  font-size: 12px;
}

.pop-up {
  background: #f9f9f9;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  min-height: 100%;
}

.close-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  cursor: pointer;
}

.widget-overlay {
  width: 70%;
}

.pop-up-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay-text {
  text-align: center;
  color: #212121;
  font-size: 15px;
  margin-top: 12px;
}

.code {
  resize: none;
  width: 95%;
  padding: 10px;
  background-color: rgb(235, 235, 235);
  border-color: rgb(140, 149, 158);
  color: #212121;
  font-size: 14px;
  margin-top: 15px;
  word-break: break-all;
}

.button-wrapper {
  display: flex;
  margin-top: 12px;
}

.how-it-works {
  margin-top: 14px;
  margin-bottom: 14px;
  width: 100%;
}

.hiw-title {
  font-weight: bold;
  margin-bottom: 14px;
}

.number-explanation-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.number {
  border: 2px solid #3b755f;
  border-radius: 50%;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3b755f;
  font-weight: 600;
  margin-right: 8px;
}

.explanation {
  font-size: 14px;
}

.dropdown {
  width: 100%;
}

.dropdown-block {
  margin-bottom: 16px;
}

.copy-item-wrapper {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold;
  // margin-right: 10px;
  margin-bottom: -7px;
  // width: 100%;
}

.copy-inner {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.item-to-copy {
  padding: 5px 20px;
  background: #f2ebdb;
  height: 54px;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  max-width: 100%;
  white-space: nowrap;
  width: 355px;
}

.switch {
  cursor: pointer;
  color: #3b755f;
  font-size: 12px;
  margin-top: 12px;
}

.switch:hover {
  opacity: 80;
}

.support {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.green-text {
  padding-left: 2px;
  color: #3b755f;
  text-decoration: underline;
  cursor: pointer;
}

.green-text:hover {
  opacity: 80;
}

.green-text.bottom {
  margin-top: 20px;
}

@media screen and (max-width: 420px) {
  .widget-image-wrapper {
    width: 100%;
  }
}
</style>
