<template>
  <div :class="['public-ledger', { live: livePageData }]">
    <div class="header">
      <h2 :class="['header-title', { centered: isHeaderCentered }]">
        {{ t('header') }}
      </h2>
      <div :class="['header-description-wrapper', { centered: isHeaderCentered }]">
        <p :class="['header-description', { centered: isHeaderCentered }]">
          {{
            livePageData ? t('text', { name: livePageData.companyName }) : t('public_ledger_text')
          }}
        </p>
      </div>
    </div>

    <div class="menu-bar">
      <button
        v-for="type in tabs"
        :key="type"
        :class="['menu-item', { 'is-active': activeTab === type }]"
        @click="setActiveTab(type)"
      >
        <div class="menu-item-label">
          {{ t(type) }}
        </div>
      </button>
    </div>

    <div class="accordion-wrapper">
      <v-expansion-panels :model-value="accordion" variant="accordion" flat>
        <v-expansion-panel v-for="(value, key) in receiptsByType(activeTab)" :key="key">
          <v-expansion-panel-title>{{ value.date }}</v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="accordion-content">
              <ProjectReceipt
                v-for="(receipt, b) in value.receipts"
                :key="b"
                :name="receipt.receiptName"
                :image-url="receipt.imageUrl"
              />
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <TransferredImpact v-if="isLifePageDataShown" :live-page-data="livePageData" :live="true" />
  </div>
</template>

<script lang="ts">
import ProjectReceipt from '@/components/ledger/ProjectReceipt.vue'
import TransferredImpact from '@/components/ledger/TransferredImpact.vue'
import type { Receipt } from '@api/index'
import { getAllReceipts } from '@api/index'
import type { LivePageData, OffsetType } from '@/helpers/interfaces'
import { OFFSET_TYPES } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PublicLedger',
  components: {
    ProjectReceipt,
    TransferredImpact,
  },
  data() {
    return {
      activeTab: OFFSET_TYPES[0],
      allReceipts: {},
      accordion: undefined,
    } as {
      activeTab: OffsetType
      allReceipts: { [k in OffsetType]: Receipt[] }
      accordion: string[] | undefined
    }
  },
  computed: {
    tabs(): OffsetType[] {
      return OFFSET_TYPES.filter((type) => this.allReceipts[type]?.length > 0)
    },
    isLifePageDataShown(): boolean {
      return this.livePageData
        ? this.livePageData?.transferredImpact?.some((i) => i.urls.length)
        : false
    },
    isHeaderCentered(): boolean {
      return !this?.$route?.meta?.requiresAuth
    },
  },
  async created() {
    await this.getReceipts()
  },
  methods: {
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`LivePublicLedger.${key}`, params ?? {})
    },
    async getReceipts() {
      // group receipts by OffsetType as key on an object
      const receipts = await getAllReceipts()
      this.allReceipts = receipts.reduce((acc, receipt) => {
        if (!acc[receipt.type]) {
          acc[receipt.type] = []
        }
        acc[receipt.type].push(receipt)
        return acc
      }, {} as { [k in OffsetType]: Receipt[] })
    },
    receiptsByType(type: OffsetType): Receipt[] {
      return this.allReceipts[type]
    },
    setActiveTab(type: OffsetType) {
      this.activeTab = type
    },
  },
  props: {
    livePageData: {
      type: Object as PropType<LivePageData>,
    },
  },
  watch: {
    activeTab() {
      this.accordion = []
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.public-ledger.live .header {
  text-align: center;
  align-items: center;
}

.public-ledger .header {
  margin: 35px 0;
  display: flex;
  flex-direction: column;
}

.public-ledger .header-title {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
}

.public-ledger .header-title.centered {
  text-align: center;
}

.public-ledger .header-description-wrapper {
  display: flex;
}

.public-ledger .header-description-wrapper.centered {
  justify-content: center;
}

.public-ledger .header-description {
  margin-top: 16px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  max-width: 45ch;
}

.public-ledger .header-description.centered {
  text-align: center;
}

.accordion-content {
  padding-bottom: 10px;
}

.accordion-wrapper {
  padding: 70px 80px;
  background-image: url('../../assets/gifs/comparisons/public_ledger_background.svg');
  border-radius: 8px;
  margin-top: -30px;
  background-size: cover;
}

.menu-bar {
  padding: 8px 30px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item {
  display: flex;
  cursor: pointer;
  margin: 0 10px;
  background-color: var(--ui-green-light);
  padding: 12px 24px;
  border-radius: 99px;
}

.menu-item.is-active {
  background-color: var(--ui-green);
}

.menu-item:hover {
  background-color: var(--ui-green);
}

.menu-item-label {
  font-size: 18px;
  font-weight: 700;
  color: var(--ui-beige);
}

.v-expansion-panel {
  margin: 4px 0;
  background: var(--ui-white);
  border-radius: 4px;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .menu-item,
  .menu-item-active {
    padding: 10px 14px;
  }

  .menu-item-label {
    font-size: 14px;
  }

  .accordion-wrapper {
    padding: 40px 30px 20px 30px;
  }

  .description-header {
    font-size: 20px;
  }

  .description-text {
    margin-top: 8px;
    font-size: 14px;
  }
}
</style>
