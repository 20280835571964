<template>
  <div class="fullwidth-banner-wrapper">
    <section class="fullwidth-banner">
      <ul class="option-list">
        <li v-for="(value, key, index) in totalImpacts" :key="index" class="option-list-item">
          <svg>
            <use :href="'#' + key + '-icon'" />
          </svg>
          <h3 class="option-amount">
            {{ value.toLocaleString() }}
          </h3>
          <p class="option-label">
            {{ t(key) }}
          </p>
        </li>
      </ul>
      <span class="greenspark-logo-banner public-profile-link">
        <img :src="require('@/assets/greenspark-logo-beige.svg')" alt="greenspark-logo" />
      </span>
    </section>
    <WidgetDialog mode="banner" />
  </div>
</template>

<script lang="ts">
import WidgetDialog from '@/components/shareables/WidgetDialog.vue'
import type { TotalImpacts } from './BadgesMain.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FullWidthBanner',
  components: {
    WidgetDialog,
  },
  methods: {
    t(key: string) {
      return this.$t(`FullWidthBanner.${key}`)
    },
  },
  props: {
    totalImpacts: { type: Object as () => TotalImpacts },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.fullwidth-banner-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 42px 0;
  width: 100%;
}

.fullwidth-banner {
  background: url('../../assets/backgrounds/full-width-banner-background.svg') #f2ebdb no-repeat
    bottom center/100% auto;
  color: #3b755f;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 160px;
  position: relative;
  width: 100%;
}

.greenspark-logo-banner {
  color: #f2ebdb;
}

.option-list {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.option-list-item {
  color: inherit;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  max-width: 230px;
  text-align: center;
  margin-bottom: 50px;
}

.option-list-item svg {
  height: 48px;
  width: 48px;
}

.option-amount {
  color: inherit;
  font-size: 32px;
  font-weight: 600;
  margin: 10px 0 0;
  padding: 0;
}

.option-label {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  padding: 0;
}

.greenspark-logo-banner {
  bottom: 5px;
  line-height: 0;
  position: absolute;
  right: 5px;
}

.greenspark-logo-banner img {
  height: 25px;
  width: 100px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .fullwidth-banner {
    padding: 20px 20px 200px;
  }

  .greenspark-logo-banner {
    bottom: 20px;
    right: 20px;
  }

  .greenspark-logo-banner img {
    height: 25px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .fullwidth-banner {
    padding: 80px 40px 0;
    height: 650px;
  }

  .option-list {
    align-items: flex-start;
    flex-direction: row;
    gap: 20px;
  }

  .option-list-item {
    margin-bottom: 0;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .fullwidth-banner {
    padding: 80px 40px 40px;
    height: 530px;
  }
}
</style>
