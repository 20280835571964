<template>
  <v-panel class="news-feed">
    <template v-slot:title>
      {{ t('updates') }}
    </template>
    <ul id="news-feed-list" class="news-feed-list">
      <news-feed-item v-for="(feedItem, index) in feedItems" :key="index" :feed-item="feedItem" />
    </ul>
    <div
      v-if="feedItems.length && isScrollingOn"
      v-observe-visibility="{ callback: handleScrollBottom, throttle: 300 }"
    />
    <LoaderBlock v-if="loading" background-color="transparent" />
  </v-panel>
</template>

<script lang="ts">
import NewsFeedItem from './NewsFeedItem.vue'
import LoaderBlock from '@/components/tools/LoaderBlock.vue'
import { getFeedItems } from '@api/index'
import type { FeedItem } from '@/helpers/interfaces'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NewsFeed',
  components: {
    NewsFeedItem,
    LoaderBlock,
  },
  data() {
    const feedItems: FeedItem[] = []

    return {
      feedItems,
      loading: false,
      isScrollingOn: true,
    }
  },
  async mounted() {
    await this.setFeedItems()
  },
  methods: {
    async setFeedItems() {
      this.loading = true

      const skip = this.feedItems.length
      const {
        data: { data, hasMore },
      } = await getFeedItems(
        skip,
        this.feedItems.map((feedItem) => feedItem._id),
      )
      this.isScrollingOn = hasMore
      if (data.length) this.feedItems.push(...data)

      this.loading = false
    },
    handleScrollBottom(isVisible) {
      if (isVisible) {
        this.setFeedItems()
      }
    },
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`NewsFeed.${key}`, params ?? {})
    },
  },
})
</script>

<style lang="scss" scoped>
.news-feed {
  margin-bottom: 20px;
}

.news-feed-list {
  list-style-type: none;
  padding: 0;
}
</style>
